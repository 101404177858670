import React from 'react';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import transparentize from 'polished/lib/color/transparentize';
import FocusTrap from 'focus-trap-react';

import { colors, typography } from '@style-guide/config';
import SearchIcon from '@style-guide/mixins/SearchIcon';

import ScreenReaderText from '@components/ScreenReaderText';

const SearchButton = styled.button`
  ${SearchIcon({
    color: colors.white,
    size: '10px',
  })}

  color: ${colors.white};
  font-weight: 700;
  height: 100%;
  padding: 0 1rem;
  text-align: center;
  width: 100%;

  &::after,
  &::before {
    .open & {
      border-color: ${colors.darkRed};
    }
  }
`;

SearchButton.displayName = 'SearchButton';

const SearchContainer = styled.li`
  align-items: center;
  display: flex;
  height: 40px;
  margin: 0 auto;
  flex-grow: 0;
  width: 60px;
  font-size: ${rem('18px', typography.baseFontSize)};

  > div {
    height: 100%;
    width: 100%;
  }

  &.open {
    background-color: ${colors.white};
    box-shadow: inset 0 -1px ${colors.gray};
  }
`;

SearchContainer.displayName = 'SearchContainer';

const SearchFlyout = styled.div`
  background-color: ${transparentize(0.1, colors.white)};
  display: flex;
  justify-content: space-around;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 3;
`;

SearchFlyout.displayName = 'SearchFlyout';

const SearchInput = styled.input`
  flex-grow: 2;
  font-weight: 200;
  letter-spacing: 0.03em;
  padding: 1.2rem;

  &::placeholder {
    color: ${colors.darkGray};
  }
`;

SearchInput.displayName = 'SearchInput';

const SearchSubmit = styled.button`
  color: ${colors.red};
  padding: 0 25px;
  text-transform: uppercase;
`;

SearchSubmit.displayName = 'SearchSubmit';

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      keyword: '',
    };

    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      document.addEventListener('keydown', this.handleEscape, true);
    }
  }

  handleClickOutside() {
    this.setState({
      isOpen: false,
    });
  }

  toggleOpenState() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleEscape({ keyCode, key }) {
    if (keyCode === 27 || key === 'Escape') {
      this.setState({
        isOpen: false,
      });
    }
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.search();
    }
  }

  handleChange(e) {
    this.setState({
      keyword: e.target.value,
    });
  }

  search() {
    const { keyword } = this.state;

    window.location.href = `/search?search={"keyword": "${keyword}", "section": []}`;
  }

  render() {
    const searchClassNames = classNames({
      open: this.state.isOpen,
    });

    return (
      <SearchContainer
        className={searchClassNames}
      >
        <FocusTrap
          active={this.state.isOpen}
        >
          <SearchButton
            className={searchClassNames}
            onClick={this.toggleOpenState}
            aria-expanded={this.state.isOpen}
            aria-haspopup="true"
            aria-label="Search"
            id="desktopHeaderSearchToggle"
          >
            <ScreenReaderText>
              Search
            </ScreenReaderText>
          </SearchButton>
          {this.state.isOpen && (
            <SearchFlyout
              id="desktopHeaderSearchForm"
              className={searchClassNames}
            >
              <SearchInput
                placeholder="Search"
                type="text"
                id="desktopHeaderSearchInput"
                onKeyUp={this.handleKeyUp}
                onChange={this.handleChange}
                value={this.state.keyword}
              />
              <SearchSubmit
                onClick={this.search}
                id="desktopHeaderSearchButton"
              >
                SEARCH
              </SearchSubmit>
            </SearchFlyout>
          )}
        </FocusTrap>
      </SearchContainer>
    );
  }
}

Search.displayName = 'Search';

export default onClickOutside(Search);
