import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RemoveButton from '@components/RemoveButton';
import { removeFromCart } from '@redux/modules/cart';

import CartItemContainer from './components/CartItemContainer';
import CartSummaryItemContainer from './components/CartSummaryItemContainer';
import ItemName from './components/ItemName';
import ItemPrice from './components/ItemPrice';

const CartItem = ({
  dispatch,
  variant,
  displayName,
  price,
  contentGuid,
  itemIndex,
  applyAutomatically,
  soldOut,
}) => {
  const removeItem = () => {
    dispatch(removeFromCart(contentGuid));
  };

  if (variant === 'summary-item') {
    return (
      <CartSummaryItemContainer>
        <ItemName>{displayName}</ItemName>
        <ItemPrice>{price}</ItemPrice>
      </CartSummaryItemContainer>
    );
  }

  return (
    <CartItemContainer
      id={`cartItem${itemIndex}`}
    >
      <ItemName>{displayName}
        {soldOut && (<p Style="color:Red">Sold Out</p>)}
      </ItemName>
      <ItemPrice>
        {price}
        {!applyAutomatically && (
        <RemoveButton
          screenReaderLabel="Remove Item"
          action={removeItem}
          onClick={removeItem}
          className="cartItemRemoveButton"
          id={`cartItemRemove${itemIndex}`}
        />
        )}
      </ItemPrice>
    </CartItemContainer>
  );
};

CartItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  variant: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  contentGuid: PropTypes.string,
  itemIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  applyAutomatically: PropTypes.bool,
  soldOut: PropTypes.bool,
};

CartItem.defaultProps = {
  variant: 'default',
  contentGuid: '',
  applyAutomatically: false,
  soldOut: false,
};

CartItem.displayName = 'CartItem';

export default connect()(CartItem);
