import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import { colors } from '@style-guide/config';

import ListItem from './ListItem';
import ListWrapper from './ListWrapper';
import ListColumn from './ListColumn';

const List = ({
  items,
  searchPageUrl,
  filterField,
}) => {
  const groupSize = Math.ceil(items.length / 3);
  const column1 = items.slice(0, groupSize);
  const column2 = items.slice(groupSize, groupSize * 2);
  const column3 = items.slice(groupSize * 2);

  const renderListItem = (item) => {
    let queryParams = '';

    if (filterField === 'practiceAreas') {
      queryParams = `{"dateRange":{"end":null,"start":null},"keyword":"","practiceAreas":[{"filterName":"${item.filterName}","filterId":"${item.filterId}"}],"otherResources":[],"publications":[],"resourceTypes":[],"type":"practiceAreas"}`;
    } else if (filterField === 'otherResources') {
      queryParams = `{"dateRange":{"end":null,"start":null},"keyword":"","practiceAreas":[],"otherResources":[{"filterName":"${item.filterName}","filterId":"${item.filterId}"}],"publications":[],"resourceTypes":[],"type":"otherResources"}`;
    } else if (filterField === 'publications') {
      queryParams = `{"dateRange":{"end":null,"start":null},"keyword":"","practiceAreas":[],"otherResources":[],"publications":[{"filterName":"${item.filterName}","filterId":"${item.filterId}"}],"resourceTypes":[],"type":"all"}`;
    }

    return (
      <ListItem>
        <a
          href={`${searchPageUrl}?sort=recentlyPublished&search=${queryParams}`}
        >
          <span>{item.filterName}</span>
          <FontAwesomeIcon
            icon={faCaretRight}
            style={{
              color: colors.red,
            }}
          />
        </a>
      </ListItem>
    );
  };

  return (
    <ListWrapper>
      {column1.length > 0 && (
        <ListColumn>
          {column1.map(item => renderListItem(item))}
        </ListColumn>
      )}
      {column2.length > 0 && (
        <ListColumn>
          {column2.map(item => renderListItem(item))}
        </ListColumn>
      )}
      {column3.length > 0 && (
        <ListColumn>
          {column3.map(item => renderListItem(item))}
        </ListColumn>
      )}
    </ListWrapper>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchPageUrl: PropTypes.string.isRequired,
  filterField: PropTypes.string.isRequired,
};

List.displayName = 'List';

export default List;
