import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  > div {
    max-width: ${props => (props.wide ? '600px' : '300px')};
    width: 100%;
  }

  > div:not(:first-child) {
    margin: 30px 0 0 0;
  }

  @media (min-width: ${breakpoints.s}) {
    flex-direction: row;

    > div:not(:first-child) {
      margin: 0 0 0 10px;
    }
  }
`;

FormFieldWrapper.displayName = 'FormFieldWrapper';

export default FormFieldWrapper;
