import React from 'react';
import PropTypes from 'prop-types';
import { injectGlobal } from 'styled-components';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import without from 'lodash/without';

import { colors } from '@style-guide/config';

import RightRailContainer from '@components/RightRailContainer';
import { SpeakersList } from '@structures/Speakers';
import SimilarCourses from '@components/SimilarCourses';
import { courseFormat as courseFormatPropTypes } from '@services/PropTypes';
import SubMenu from '@components/ShoppingCartPopUp/SubMenu';
import ShoppingCartItems from '@components/ShoppingCartPopUp/ShoppingCartItems/ShoppingCartItems';

import { Provider } from 'react-redux';
import Store from '@redux';

import AdditionalInstances from './components/AdditionalInstances';
import AddOns from './components/AddOns';
import CLEDetailBodyContainer from './components/CLEDetailBodyContainer';
import CLEDetailPageContainer from './components/CLEDetailPageContainer';
import Description from './components/Description';
import Details from './components/Details';
import Header from './components/Header';
import MainColumnContainer from './components/MainColumnContainer';

class CLEDetail extends React.Component {
  constructor() {
    super();

    this.state = {
      addOnsActive: [],
      windowWidth: window.innerWidth,
      showCartPopUp: false,
    };

    this.updateAddOnsActiveState = this.updateAddOnsActiveState.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.updateShowCart = this.updateShowCart.bind(this);
    this.onClickCloseCancel = this.onClickCloseCancel.bind(this);

    this.store = Store.get();
  }

  componentDidMount() {
    injectGlobal`
      body {
        background: ${colors.lightGray};
      }
    `;

    window.addEventListener('resize', debounce(this.updateWindowWidth, 100));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.updateWindowWidth, 100));
  }

  onClickCloseCancel() {
    this.setState({
      showCartPopUp: false,
    });
  }

  updateShowCart(flag) {
    this.setState({
      showCartPopUp: flag,
    });
  }

  updateWindowWidth() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  updateAddOnsActiveState(id) {
    const currentAddOns = this.state.addOnsActive;
    const addOnAlreadyActive = includes(currentAddOns, id);

    if (addOnAlreadyActive) {
      this.setState({
        addOnsActive: without(currentAddOns, id),
      });
    } else {
      this.setState({
        addOnsActive: [
          ...currentAddOns,
          id,
        ],
      });
    }
  }

  render() {
    const {
      courseFormat,
      description,
      richCourseDescription,
      location,
      displayName,
      displayPicture,
      speakers,
      brochureLink,
      contentGuid,
      zoomMeetingUrl,
      date,
      startTime,
      endTime,
      credits,
      courseID,
      status,
      practiceAreas,
      prices,
      additionalLocations,
      additionalInstances,
      similarCourses,
      attendanceTypeOptions,
      addOns,
      addOnsBundle,
      specializationCredits,
      hideInstance,
      checkoutLink,
      shoppingCartLink,
      cLEStoreLink,
      isAuthenticated,
      myDashboardLink,
      isMeetingEnded,
    } = this.props;
    const { windowWidth } = this.state;

    const content = (
      <ShoppingCartItems
        checkoutLink={checkoutLink}
        shoppingCartLink={shoppingCartLink}
        continueShoppingLink={cLEStoreLink}
        myDashboardLink={myDashboardLink}
      />
    );

    return (
      <React.Fragment>
        {this.state.showCartPopUp && isAuthenticated && (
          <Provider store={this.store}>
            <SubMenu
              heading="Shopping Cart"
              content={content}
              id="desktopHeaderCartContent"
              closeAction={this.onClickCloseCancel}
            />
          </Provider>
        )}
        <CLEDetailPageContainer>
          <Header
            displayName={displayName}
          />
          <CLEDetailBodyContainer>
            <MainColumnContainer>
              <Description
                courseFormat={courseFormat}
                date={date}
                displayPicture={displayPicture}
                description={description}
                richDescription={richCourseDescription}
              />
              {(!!addOns.length || !!addOnsBundle.length) && (
                <AddOns
                  addOns={addOns}
                  addOnsBundle={addOnsBundle}
                  addOnsActive={this.state.addOnsActive}
                  updateAddOnsActiveState={this.updateAddOnsActiveState}
                />
              )}
              {windowWidth < 900 && (
                <Details
                  brochureLink={brochureLink}
                  contentGuid={contentGuid}
                  zoomMeetingUrl={zoomMeetingUrl}
                  date={date}
                  endTime={endTime}
                  startTime={startTime}
                  courseFormat={courseFormat}
                  credits={credits}
                  courseID={courseID}
                  status={status}
                  prices={prices}
                  location={location}
                  additionalLocations={additionalLocations}
                  practiceAreas={practiceAreas}
                  attendanceTypeOptions={attendanceTypeOptions}
                  addOnsActive={this.state.addOnsActive}
                  specializationCredits={specializationCredits}
                  disableAddToCart={hideInstance}
                  updateShowCart={this.updateShowCart}
                  isMeetingEnded={isMeetingEnded}
                />
              )}
              {!!speakers.length && (
              <SpeakersList
                speakers={speakers}
              />
              )}
              {!!additionalInstances.length && (
                <AdditionalInstances
                  additionalInstances={additionalInstances}
                />
              )}
              {!!similarCourses.length && (
                <SimilarCourses
                  similarCourses={similarCourses}
                />
              )}
            </MainColumnContainer>
            {windowWidth >= 900 && (
              <RightRailContainer>
                <Details
                  brochureLink={brochureLink}
                  contentGuid={contentGuid}
                  zoomMeetingUrl={zoomMeetingUrl}
                  date={date}
                  endTime={endTime}
                  startTime={startTime}
                  courseFormat={courseFormat}
                  credits={credits}
                  courseID={courseID}
                  status={status}
                  prices={prices}
                  location={location}
                  additionalLocations={additionalLocations}
                  practiceAreas={practiceAreas}
                  attendanceTypeOptions={attendanceTypeOptions}
                  addOnsActive={this.state.addOnsActive}
                  specializationCredits={specializationCredits}
                  disableAddToCart={hideInstance}
                  updateShowCart={this.updateShowCart}
                  isMeetingEnded={isMeetingEnded}
                />
              </RightRailContainer>
            )}
          </CLEDetailBodyContainer>
        </CLEDetailPageContainer>
      </React.Fragment>
    );
  }
}

CLEDetail.displayName = 'CLEDetail';

CLEDetail.propTypes = {
  courseFormat: courseFormatPropTypes,
  description: PropTypes.string,
  richCourseDescription: PropTypes.string,
  location: PropTypes.shape({
    displayName: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    line4: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  displayName: PropTypes.string,
  displayPicture: PropTypes.string,
  speakers: PropTypes.arrayOf(Object),
  brochureLink: PropTypes.string,
  contentGuid: PropTypes.string,
  zoomMeetingUrl: PropTypes.string,
  date: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  credits: PropTypes.arrayOf(Object),
  courseID: PropTypes.string,
  status: PropTypes.string,
  practiceAreas: PropTypes.arrayOf(Object),
  prices: PropTypes.arrayOf(Object),
  additionalLocations: PropTypes.arrayOf(Object),
  additionalInstances: PropTypes.arrayOf(Object),
  similarCourses: PropTypes.arrayOf(Object),
  // eslint-disable-next-line
  attendanceTypeOptions: PropTypes.object,
  addOns: PropTypes.arrayOf(Object),
  addOnsBundle: PropTypes.arrayOf(Object),
  specializationCredits: PropTypes.arrayOf(Object),
  hideInstance: PropTypes.bool,
  checkoutLink: PropTypes.string,
  shoppingCartLink: PropTypes.string,
  cLEStoreLink: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  myDashboardLink: PropTypes.string,
  isMeetingEnded: PropTypes.bool.isRequired,
};

CLEDetail.defaultProps = {
  courseFormat: {
    courseFormatType: '',
    name: '',
    sourceId: '',
    type: 1,
  },
  richCourseDescription: '',
  description: '',
  location: {
    displayName: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    city: '',
    state: '',
    postalCode: '',
  },
  displayName: '',
  displayPicture: '',
  speakers: [],
  brochureLink: '',
  contentGuid: '',
  zoomMeetingUrl: '',
  date: '',
  startTime: '',
  endTime: '',
  credits: [],
  courseID: '',
  status: '',
  practiceAreas: [],
  prices: [],
  additionalLocations: [],
  additionalInstances: [],
  similarCourses: [],
  attendanceTypeOptions: {},
  addOns: [],
  addOnsBundle: [],
  specializationCredits: [],
  hideInstance: false,
  checkoutLink: '',
  shoppingCartLink: '',
  cLEStoreLink: '',
  isAuthenticated: false,
  myDashboardLink: '',
};

export default CLEDetail;
