import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
} from 'redux-form';
import { colors } from '@style-guide/config';
import Angle from '@style-guide/mixins/Angle';
import Button from '@style-guide/components/Button';
import TextArea from '@style-guide/components/TextArea';

import ReduxFormCheckbox from '@components/ReduxFormCheckbox';
import FormFieldSet from '@components/FormFieldSet';
import ReduxFormRadioButton from '@components/ReduxFormRadioButton';
import ReduxFormCheckboxGroup from '@components/ReduxFormCheckboxGroup';
import FormFieldWrapper from './components/FormFieldWrapper';
import CheckBoxGroupWrapper from './components/CheckBoxGroupWrapper';
import RadioButtonGroup from './components/RadioButtonGroup';
import FormFieldSection from './components/FormFieldSection';
import PracticeAreaCourtDiv from './components/PracticeAreaCourtDiv';
import { areaOfLawSelectOption, courtSelectOption, eNewsLetterFrequencyOptions } from '@config';

const SaveContainer = styled.div`
  button {
    margin: 0;
  }
`;

SaveContainer.displayName = 'SaveContainer';

const PaddedPara = styled.p`
  padding-left: 35px;
`;

PaddedPara.displayName = 'PaddedPara';

const PracticeAreaDiv = styled.div`
  padding-left: 35px;
`;

PracticeAreaDiv.displayName = 'PracticeAreaDiv';

const FreqPromDiv = styled.div`
  padding-left: 40px;
`;

FreqPromDiv.displayName = 'FreqPromDiv';

const CheckBoxDiv = styled.div`
  label {
    font-weight: 700;
    letter-spacing: 0;
  }
`;

CheckBoxDiv.displayName = 'CheckBoxDiv';

const InLineBlockDiv = styled.div`
  display: flex;
`;

InLineBlockDiv.displayName = 'InLineBlockDiv';

const angleOptionsRight = {
  angle: 'right',
};

const angleOptionsDown = {
  angle: 'down',
};

const OptionsToggle = styled.button`
  ${props => Angle(props.angleOption)}

  color: ${props => (props.disabled ? colors.darkGray : 'rgb(195, 0, 47)')};
  margin: 25px 0 0 0 !important;
  padding: 0;
`;

OptionsToggle.displayName = 'OptionsToggle';

const ErrorSpan = styled.span`
  color: red;
  font-size: 14px;
`;

ErrorSpan.displayName = 'ErrorSpan';

const HeadingDiv = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

HeadingDiv.displayName = 'HeadingDiv';

class ENewsLettersInfoForm extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;

    this.valueChange = bindActionCreators(change, dispatch);
  }

  componentDidUpdate(prevProps) {
    if (this.props.AllPracticeAreasSelectedValue !== prevProps.AllPracticeAreasSelectedValue) {
      if (this.props.AllPracticeAreasSelectedValue === '1' &&
      (this.props.PracticeAreasSelectedValue.length !== this.props.eBarAreasOfLawList.length)) {
        const practiceAreaSelected = this.props.eBarAreasOfLawList.map(item => item.name);
        this.valueChange('eNewsLettersInfoForm', 'PracticeAreasSelected', practiceAreaSelected);
      } else if (this.props.AllPracticeAreasSelectedValue === '2'
      && (this.props.PracticeAreasSelectedValue.length === this.props.eBarAreasOfLawList.length)) {
        const practiceAreaSelectedZero = [];
        this.valueChange('eNewsLettersInfoForm', 'PracticeAreasSelected', practiceAreaSelectedZero);
        this.props.handlePracticeAreas(true);
      }
    } else if (this.props.PracticeAreasSelectedValue.length
      !== prevProps.PracticeAreasSelectedValue.length) {
      if (this.props.AllPracticeAreasSelectedValue === '1' &&
        (this.props.PracticeAreasSelectedValue.length !== this.props.eBarAreasOfLawList.length)) {
        this.valueChange('eNewsLettersInfoForm', 'allPracticeAreasSelected', '2');
      } else if (this.props.AllPracticeAreasSelectedValue === '2'
      && (this.props.PracticeAreasSelectedValue.length === this.props.eBarAreasOfLawList.length)) {
        this.valueChange('eNewsLettersInfoForm', 'allPracticeAreasSelected', '1');
      }
    } else if (this.props.AllCourtsSelectedValue !== prevProps.AllCourtsSelectedValue) {
      if (this.props.AllCourtsSelectedValue === '1' &&
      (this.props.CourtsSelectedValue.length !== this.props.eBarCourtsList.length)) {
        const courtsSelected = this.props.eBarCourtsList.map(item => item.name);
        this.valueChange('eNewsLettersInfoForm', 'CourtsSelected', courtsSelected);
      } else if (this.props.AllCourtsSelectedValue === '2'
      && (this.props.CourtsSelectedValue.length === this.props.eBarCourtsList.length)) {
        const courtsSelectedZero = [];
        this.valueChange('eNewsLettersInfoForm', 'CourtsSelected', courtsSelectedZero);
        this.props.handleCourts(true);
      }
    } else if (this.props.CourtsSelectedValue.length
      !== prevProps.CourtsSelectedValue.length) {
      if (this.props.AllCourtsSelectedValue === '1' &&
        (this.props.CourtsSelectedValue.length !== this.props.eBarCourtsList.length)) {
        this.valueChange('eNewsLettersInfoForm', 'allCourtsSelected', '2');
      } else if (this.props.AllCourtsSelectedValue === '2'
      && (this.props.CourtsSelectedValue.length === this.props.eBarCourtsList.length)) {
        this.valueChange('eNewsLettersInfoForm', 'allCourtsSelected', '1');
      }
    }
  }

  render() {
    const {
      handleSubmit,
      loading,
      pristine,
      hasSaveError,
      isMember,
      ENewsLettersGreenBookValue,
      PracticeAreasSelectedValue,
      CourtsSelectedValue,
      AllPracticeAreasSelectedValue,
      AllCourtsSelectedValue,
      eBarAreasOfLawList,
      eBarCourtsList,
      labelContent,
      handlePracticeAreas,
      handleCourts,
      handleEnewsLettersSubscription,
      showPracticeAreas,
      showCourts,
    } = this.props;

    const eNewsLetterFrequencyRadioButtons = eNewsLetterFrequencyOptions.map(eNewsFreq => (
      <li
        key={eNewsFreq.value}
      >
        <Field
          id={`eNewsLetterFrequencyRadioButton${eNewsFreq.value}`}
          label={eNewsFreq.label}
          name="frequencyGreenBook"
          props={{
            value: eNewsFreq.value,
          }}
          type="radiobutton"
          component={ReduxFormRadioButton}
          disabled={!isMember || !ENewsLettersGreenBookValue}
        />
      </li>
    ));
    const areaOfLawsSelectRadioButtons = areaOfLawSelectOption.map(areaOfLawOption => (
      <li
        key={areaOfLawOption.value}
      >
        <Field
          id={`areaOfLawsSelectRadioButton${areaOfLawOption.value}`}
          label={areaOfLawOption.label}
          name="allPracticeAreasSelected"
          props={{
            value: areaOfLawOption.value,
          }}
          type="radiobutton"
          component={ReduxFormRadioButton}
          disabled={!isMember || !ENewsLettersGreenBookValue}
        />
      </li>
    ));
    const courtsSelectRadioButtons = courtSelectOption.map(courtOption => (
      <li
        key={courtOption.value}
      >
        <Field
          id={`courtsSelectRadioButton${courtOption.value}`}
          label={courtOption.label}
          name="allCourtsSelected"
          props={{
            value: courtOption.value,
          }}
          type="radiobutton"
          component={ReduxFormRadioButton}
          disabled={!isMember || !ENewsLettersGreenBookValue}
        />
      </li>
    ));

    // the ReduxFormCheckboxGroup just requires a array of strings to make checkboxes from
    // we receive an array of objects with ID so we need to flatten it to just an array of names
    const flattenArray = inputArray => (inputArray.map(item => item.name));

    return (
      <form
        id="eNewsLettersInfoForm"
        onSubmit={handleSubmit}
      >
        <FormFieldSection>
          <FormFieldWrapper wide>
            <CheckBoxDiv>
              <Field
                id="eNewsLettersGreenBook"
                label="OSBA Report “Greenbook”"
                name="eNewsLettersGreenBook"
                props={{
                  value: true,
                }}
                type="checkbox"
                component={ReduxFormCheckbox}
                disabled={!isMember}
                onChange={() => handleEnewsLettersSubscription()}
              />
            </CheckBoxDiv>
          </FormFieldWrapper>
          <PaddedPara>
            This exclusive member benefit includes timely caselaw and statutory updates,
            legal and legislative news and career opportunities. Be sure to update your
            preferences for frequency and content.
          </PaddedPara>
          <PracticeAreaDiv>
            <FreqPromDiv>
              <HeadingDiv>Frequency</HeadingDiv>
              <RadioButtonGroup>
                {eNewsLetterFrequencyRadioButtons}
              </RadioButtonGroup>
              <HeadingDiv>Content</HeadingDiv>
              <TextArea
                htmlString={labelContent}
              />
            </FreqPromDiv>
            <FreqPromDiv>
              <HeadingDiv>Areas of Law</HeadingDiv>
              <InLineBlockDiv>
                <RadioButtonGroup>
                  {areaOfLawsSelectRadioButtons}
                </RadioButtonGroup>
                <OptionsToggle
                  type="button"
                  disabled={!isMember || !ENewsLettersGreenBookValue}
                  onClick={() => handlePracticeAreas()}
                  angleOption={showPracticeAreas ? angleOptionsDown : angleOptionsRight}
                />
              </InLineBlockDiv>
              {isMember && ENewsLettersGreenBookValue && PracticeAreasSelectedValue !== null
              && PracticeAreasSelectedValue !== undefined && PracticeAreasSelectedValue.length <= 0
              && AllPracticeAreasSelectedValue === '2' && (
                <ErrorSpan>
                  At least one area of law must be selected.
                </ErrorSpan>
              )}
              <PracticeAreaCourtDiv
                show={showPracticeAreas}
              >
                <CheckBoxGroupWrapper>
                  <FormFieldSet>
                    <Field
                      name="PracticeAreasSelected"
                      props={{
                        options:
                          flattenArray(eBarAreasOfLawList),
                        columns: 3,
                      }}
                      component={ReduxFormCheckboxGroup}
                      disabled={!isMember || !ENewsLettersGreenBookValue}
                    />
                  </FormFieldSet>
                </CheckBoxGroupWrapper>
              </PracticeAreaCourtDiv>
            </FreqPromDiv>
            <FreqPromDiv>
              <HeadingDiv>Courts</HeadingDiv>
              <InLineBlockDiv>
                <RadioButtonGroup>
                  {courtsSelectRadioButtons}
                </RadioButtonGroup>
                <OptionsToggle
                  type="button"
                  disabled={!isMember || !ENewsLettersGreenBookValue}
                  onClick={() => handleCourts()}
                  angleOption={showCourts ? angleOptionsDown : angleOptionsRight}
                />
              </InLineBlockDiv>
              {isMember && ENewsLettersGreenBookValue &&
              CourtsSelectedValue !== null && CourtsSelectedValue !== undefined
              && CourtsSelectedValue.length <= 0 && AllCourtsSelectedValue === '2' && (
                <ErrorSpan>
                  At least one court must be selected.
                </ErrorSpan>
              )}
              <PracticeAreaCourtDiv
                show={showCourts}
              >
                <CheckBoxGroupWrapper>
                  <FormFieldSet>
                    <Field
                      name="CourtsSelected"
                      props={{
                        options:
                          flattenArray(eBarCourtsList),
                        columns: 3,
                      }}
                      component={ReduxFormCheckboxGroup}
                      disabled={!isMember || !ENewsLettersGreenBookValue}
                    />
                  </FormFieldSet>
                </CheckBoxGroupWrapper>
              </PracticeAreaCourtDiv>
            </FreqPromDiv>
          </PracticeAreaDiv>
          <FormFieldWrapper
            wide
          >
            <CheckBoxDiv>
              <Field
                id="eNewsLettersBriefs"
                label="The Brief"
                name="eNewsLettersBriefs"
                type="checkbox"
                props={{
                  value: true,
                }}
                component={ReduxFormCheckbox}
              />
            </CheckBoxDiv>
          </FormFieldWrapper>
          <PaddedPara>
            This monthly publication provides useful business and personal development
            resources as well as practice management content to help you find balance
            and become a successful lawyer.
          </PaddedPara>
          <SaveContainer>
            <Button
              type="submit"
              disabled={(pristine || loading ||
                (isMember && ENewsLettersGreenBookValue && PracticeAreasSelectedValue !== null
                  && PracticeAreasSelectedValue !== undefined
                  && PracticeAreasSelectedValue.length <= 0) ||
                (isMember && ENewsLettersGreenBookValue &&
                  CourtsSelectedValue !== null && CourtsSelectedValue !== undefined
                  && CourtsSelectedValue.length <= 0)) && !hasSaveError}
            >
              Save
            </Button>
          </SaveContainer>
        </FormFieldSection>
      </form>
    );
  }
}

ENewsLettersInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
  isMember: PropTypes.bool.isRequired,
  eBarAreasOfLawList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  eBarCourtsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  handlePracticeAreas: PropTypes.func.isRequired,
  handleCourts: PropTypes.func.isRequired,
  handleEnewsLettersSubscription: PropTypes.func.isRequired,
  showPracticeAreas: PropTypes.bool.isRequired,
  showCourts: PropTypes.bool.isRequired,
  labelContent: PropTypes.string,
  ENewsLettersGreenBookValue: PropTypes.bool.isRequired,
  PracticeAreasSelectedValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  CourtsSelectedValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  AllPracticeAreasSelectedValue: PropTypes.string.isRequired,
  AllCourtsSelectedValue: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ENewsLettersInfoForm.defaultProps = {
  hasSaveError: false,
  labelContent: '',
};

ENewsLettersInfoForm.displayName = 'ENewsLettersInfoForm';

const ENewsLettersInfoReduxForm = reduxForm({
  form: 'eNewsLettersInfoForm',
  enableReinitialize: true,
})(ENewsLettersInfoForm);

/* following example at https://redux-form.com/7.4.2/examples/selectingformvalues/ */
const selector = formValueSelector('eNewsLettersInfoForm');

const ENewsLettersInfoFormConnected = connect((state) => {
  const ENewsLettersGreenBookValue = selector(state, 'eNewsLettersGreenBook');
  const PracticeAreasSelectedValue = selector(state, 'PracticeAreasSelected');
  const CourtsSelectedValue = selector(state, 'CourtsSelected');
  const AllPracticeAreasSelectedValue = selector(state, 'allPracticeAreasSelected');
  const AllCourtsSelectedValue = selector(state, 'allCourtsSelected');

  return {
    ENewsLettersGreenBookValue,
    PracticeAreasSelectedValue,
    CourtsSelectedValue,
    AllPracticeAreasSelectedValue,
    AllCourtsSelectedValue,
  };
})(ENewsLettersInfoReduxForm);

export default ENewsLettersInfoFormConnected;
