import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';

import Button from '@style-guide/components/Button';

import { phoneFormatter } from '@services/FormFormatters';
import { phoneParser } from '@services/FormParsers';
import {
  email,
  phoneNumber,
} from '@services/FormValidators';

import ErrorMessage from '@components/ErrorMessage';
import FormFieldSubGroup from '@components/FormFieldSubGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormSelect from '@components/ReduxFormSelect';

import validate from './services/validate';
import warn from './services/warn';

import CheckBoxWrapper from './components/CheckBoxWrapper';
import FlexWrapper from './components/FlexWrapper';
import PreferredEmailStateContainer from './components/PreferredEmailStateContainer';

const ContactInfoForm = ({
  handleSubmit,
  hasSaveError,
  invalid,
  loading,
  pristine,
  states,
  warning,
}) => (
  <form
    id="contactInfoForm"
    onSubmit={handleSubmit}
  >
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        format={phoneFormatter}
        id="homePhoneNumber"
        label="Home Phone Number"
        name="HomePhoneNumber"
        parse={phoneParser}
        type="tel"
        validate={phoneNumber}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        format={phoneFormatter}
        id="cellPhoneNumber"
        label="Cell Phone Number"
        name="CellPhoneNumber"
        parse={phoneParser}
        type="tel"
        validate={phoneNumber}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id="workPhoneNumber"
        format={phoneFormatter}
        label="Work Phone Number"
        name="WorkPhoneNumber"
        parse={phoneParser}
        type="tel"
        validate={phoneNumber}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        format={phoneFormatter}
        id="faxNumber"
        label="Fax Phone Number"
        name="FaxNumber"
        type="tel"
        parse={phoneParser}
        validate={phoneNumber}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <FlexWrapper>
        <Field
          component={ReduxFormInput}
          id="personalEmailAddress"
          label="Personal Email Address"
          name="PersonalEmailAddress"
          type="email"
          validate={email}
        />
        <CheckBoxWrapper>
          <Field
            component={PreferredEmailStateContainer}
            id="isPersonalEmailPreferred"
            label="Preferred Email"
            name="PreferredEmail"
            props={{
              value: 'personal',
              emailField: 'PersonalEmailAddress',
            }}
            type="checkbox"
          />
        </CheckBoxWrapper>
      </FlexWrapper>
    </FormFieldWrapper>
    <FormFieldWrapper>
      <FlexWrapper>
        <Field
          component={ReduxFormInput}
          id="workOrSchoolEmailAddress"
          label="Work/School Email Address"
          name="WorkOrSchoolEmailAddress"
          type="email"
          validate={email}
        />
        <CheckBoxWrapper>
          <Field
            component={PreferredEmailStateContainer}
            id="isWorkOrSchoolEmailPreferred"
            label="Preferred Email"
            name="PreferredEmail"
            props={{
              value: 'workOrSchool',
              emailField: 'WorkOrSchoolEmailAddress',
            }}
            type="checkbox"
          />
        </CheckBoxWrapper>
      </FlexWrapper>
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id="addressLine1"
        label="Home Street Address"
        name="AddressLine1"
      />
      <Field
        component={ReduxFormInput}
        id="addressLine2"
        label="PO Box / Suite"
        name="AddressLine2"
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id="city"
        label="City"
        name="City"
      />
      <FormFieldSubGroup>
        <Field
          component={ReduxFormSelect}
          id="state"
          label="State"
          name="State"
          options={states.map(state => ({
            label: state.value,
            value: state.value,
          }))}
        />
        <Field
          component={ReduxFormInput}
          id="zipCode"
          label="Zip Code"
          name="ZipCode"
          type="tel"
        />
      </FormFieldSubGroup>
    </FormFieldWrapper>
    {warning && (
      <FormFieldWrapper>
        <div><ErrorMessage warn>{warning}</ErrorMessage></div>
      </FormFieldWrapper>
    )}
    <Button
      disabled={(pristine || loading || invalid) && !hasSaveError}
      type="submit"
    >
      Save
    </Button>
  </form>
);

ContactInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hasSaveError: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  warning: PropTypes.string,
};

ContactInfoForm.defaultProps = {
  hasSaveError: false,
  warning: '',
};

ContactInfoForm.displayName = 'ContactInfoForm';

export default reduxForm({
  enableReinitialize: true,
  form: 'contactInfoForm',
  validate,
  warn,
})(ContactInfoForm);
