import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';
import camelcaseKeys from 'camelcase-keys';

import { courseFormat as courseFormatPropTypes } from '@services/PropTypes';
import { colors } from '@style-guide/config';

import LiveSVG from '@components/LiveSVG';
import SelfStudySVG from '@components/SelfStudySVG';

const height = 40;
const arrowSize = height / 2;
const borderThickness = 3;

const Label = styled.span`
  letter-spacing: 1px;
`;

Label.displayName = 'Label';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${props => (props.courseFormat.type === 0 ? colors.blue : colors.darkRed)};
  box-shadow: 0 0 0 3px ${colors.white};
  color: ${colors.white};
  display: flex;
  flex-direction: row;
  height: ${height}px;
  justify-content: center;
  left: -10px;
  padding: 5px 10px 5px 0;
  position: absolute;
  top: -${arrowSize}px;
  z-index: 1;

  svg {
    height: 100%;
    border-right: ${transparentize(0.35, colors.white)} 1px solid;
    margin-right: 10px;
  }

  &::before {
    border-bottom: ${arrowSize + borderThickness}px solid transparent;
    border-top: ${arrowSize + borderThickness}px solid transparent;
    border-left: ${15 + borderThickness}px solid ${colors.white};
    content: '';
    position: absolute;
    right: -${15 + borderThickness + borderThickness}px;
    top: -${borderThickness}px;
  }

  &::after {
    border-bottom: ${arrowSize}px solid transparent;
    border-top: ${arrowSize}px solid transparent;
    border-left: 15px solid ${props => (props.courseFormat.type === 0 ? colors.blue : colors.darkRed)};
    content: '';
    position: absolute;
    right: -15px;
    top: 0;
  }
`;

Wrapper.displayName = 'Wrapper';

const CLEBadge = ({ courseFormat, featured }) => {
  const camelKeys = camelcaseKeys(courseFormat);
  const Icon = camelKeys.type === 0 ? LiveSVG : SelfStudySVG;

  return featured ? (
    <Wrapper
      style={{ left: '2px' }}
      courseFormat={camelKeys}
    >
      <Icon />
      <Label>{camelKeys.courseFormatType}</Label>
    </Wrapper>
  )
    :
    (
      <Wrapper
        courseFormat={camelKeys}
      >
        <Icon />
        <Label>{camelKeys.courseFormatType}</Label>
      </Wrapper>
    );
};

CLEBadge.propTypes = {
  courseFormat: courseFormatPropTypes.isRequired,
  featured: PropTypes.bool,
};

CLEBadge.defaultProps = {
  featured: false,
};

CLEBadge.displayName = 'CLEBadge';

export default CLEBadge;
