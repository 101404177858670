import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';

import Button from '@style-guide/components/Button';

import FormFieldSection from '@components/FormFieldSection';

import AwardsList from './components/AwardsList';

const HonorsInfoForm = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  hasSaveError,
}) => (
  <form
    id="honorsInfoForm"
    onSubmit={handleSubmit}
  >
    <FormFieldSection
      heading="Awards"
    >
      <FieldArray
        name="Awards"
        component={AwardsList}
        rerenderOnEveryChange
      />
    </FormFieldSection>
    <Button
      type="submit"
      disabled={((pristine || loading) && !hasSaveError) || invalid}
    >
      Save
    </Button>
  </form>
);

HonorsInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
};

HonorsInfoForm.defaultProps = {
  hasSaveError: false,
};

HonorsInfoForm.displayName = 'HonorsInfoForm';

export default reduxForm({
  form: 'honorsInfoForm',
  enableReinitialize: true,
})(HonorsInfoForm);
