import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextArea from '@style-guide/components/TextArea';
import YellowLine from './components/YellowLine';

import Wrapper from './components/Wrapper';

const HeadingSpan = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

HeadingSpan.displayName = 'HeadingSpan';

const EBarContentResourcesListBlock = ({
  hasBottomMargin,
  hasTopMargin,
  headline,
  multipleResourcesListContent,
}) => (
  <Wrapper
    hasTopMargin={hasTopMargin}
    hasBottomMargin={hasBottomMargin}
  >
    <YellowLine />
    <HeadingSpan>{headline}</HeadingSpan>
    <br />
    <TextArea
      htmlString={multipleResourcesListContent}
    />
  </Wrapper>
);

EBarContentResourcesListBlock.propTypes = {
  /** Whether or not a 30px bottom margin is applied. */
  hasBottomMargin: PropTypes.bool,
  /** Whether or not a 30px top margin is applied. */
  hasTopMargin: PropTypes.bool,
  headline: PropTypes.string,
  multipleResourcesListContent: PropTypes.string,
};

EBarContentResourcesListBlock.defaultProps = {
  hasBottomMargin: true,
  hasTopMargin: true,
  headline: 'ContentResourcesList Block',
  multipleResourcesListContent: '',
};

EBarContentResourcesListBlock.displayName = 'EBarContentResourcesListBlock';

export default EBarContentResourcesListBlock;
