import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';

const SuccessMessage = styled.p`
  align-items: center;
  color: ${colors.green};
  display: flex;
  font-size: ${rem('16px', typography.baseFontSize)};
  font-weight: 500;
  justify-content: center;
  margin: 15px 0;
  max-width: ${breakpoints.s};
  padding: 0 35px;
  position: relative;
  text-align: center;

  &::after {
    color: ${colors.green};
    content: '\f00c';
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    position: absolute;
    right: 0;
  }
`;

SuccessMessage.displayName = 'SuccessMessage';

export default SuccessMessage;
