import moment from 'moment';

const cardOptions = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Visa',
    value: '3',
  },
  {
    label: 'Mastercard',
    value: '7',
  },
  {
    label: 'American Express',
    value: '8',
  },
  {
    label: 'Discover',
    value: '9',
  },
];

const monthOptions = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Jan',
    value: '01',
  },
  {
    label: 'Feb',
    value: '02',
  },
  {
    label: 'Mar',
    value: '03',
  },
  {
    label: 'Apr',
    value: '04',
  },
  {
    label: 'May',
    value: '05',
  },
  {
    label: 'Jun',
    value: '06',
  },
  {
    label: 'Jul',
    value: '07',
  },
  {
    label: 'Aug',
    value: '08',
  },
  {
    label: 'Sep',
    value: '09',
  },
  {
    label: 'Oct',
    value: '10',
  },
  {
    label: 'Nov',
    value: '11',
  },
  {
    label: 'Dec',
    value: '12',
  },
];

const expirationYearOptions = [
  {
    label: '',
    value: '',
  },
];

const currentYear = moment().year();

for (let i = 0; i < currentYear + 10; i += 1) {
  expirationYearOptions.push({
    label: currentYear + i,
    value: (currentYear + i).toString().slice(-2),
  });
}

const stateOptions = [
  {
    label: '',
    value: '',
  },
  {
    label: 'AL',
    value: 'AL',
  },
  {
    label: 'AK',
    value: 'AK',
  },
  {
    label: 'AZ',
    value: 'AZ',
  },
  {
    label: 'AR',
    value: 'AR',
  },
  {
    label: 'CA',
    value: 'CA',
  },
  {
    label: 'CO',
    value: 'CO',
  },
  {
    label: 'CT',
    value: 'CT',
  },
  {
    label: 'DC',
    value: 'DC',
  },
  {
    label: 'DE',
    value: 'DE',
  },
  {
    label: 'FL',
    value: 'FL',
  },
  {
    label: 'GA',
    value: 'GA',
  },
  {
    label: 'HI',
    value: 'HI',
  },
  {
    label: 'ID',
    value: 'ID',
  },
  {
    label: 'IL',
    value: 'IL',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: 'IA',
    value: 'IA',
  },
  {
    label: 'KS',
    value: 'KS',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'LA',
    value: 'LA',
  },
  {
    label: 'ME',
    value: 'ME',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MI',
    value: 'MI',
  },
  {
    label: 'MN',
    value: 'MN',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MO',
    value: 'MO',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'NE',
    value: 'NE',
  },
  {
    label: 'NV',
    value: 'NV',
  },
  {
    label: 'NH',
    value: 'NH',
  },
  {
    label: 'NJ',
    value: 'NJ',
  },
  {
    label: 'NM',
    value: 'NM',
  },
  {
    label: 'NY',
    value: 'NY',
  },
  {
    label: 'NC',
    value: 'NC',
  },
  {
    label: 'ND',
    value: 'ND',
  },
  {
    label: 'OH',
    value: 'OH',
  },
  {
    label: 'OK',
    value: 'OK',
  },
  {
    label: 'OR',
    value: 'OR',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'RI',
    value: 'RI',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SD',
    value: 'SD',
  },
  {
    label: 'TN',
    value: 'TN',
  },
  {
    label: 'TX',
    value: 'TX',
  },
  {
    label: 'UT',
    value: 'UT',
  },
  {
    label: 'VT',
    value: 'VT',
  },
  {
    label: 'VA',
    value: 'VA',
  },
  {
    label: 'WA',
    value: 'WA',
  },
  {
    label: 'WV',
    value: 'WV',
  },
  {
    label: 'WI',
    value: 'WI',
  },
  {
    label: 'WY',
    value: 'WY',
  },
  {
    label: 'GU',
    value: 'GU',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'VI',
    value: 'VI',
  },
];

const exportOptions = [
  {
    label: '--Select--',
    value: '',
  },
  {
    label: 'Excel(.xls)',
    value: '1',
  },
  {
    label: 'Text(.txt)',
    value: '2',
  },
  {
    label: 'Html(.html)',
    value: '3',
  },
];

const sCOAnnouncementsSubHeaderOptions = [
  {
    label: '--Select--',
    value: '',
  },
  {
    label: 'Case Announcements',
    value: '1',
  },
  {
    label: 'Administrative Actions',
    value: '2',
  },
  {
    label: 'Ohio Rules of Court',
    value: '3',
  },
];

const sCOAnnouncementsDateTypeOptions = [
  {
    label: '--Select--',
    value: '',
  },
  {
    label: 'Posted',
    value: '1',
  },
  {
    label: 'Deadline',
    value: '3',
  },
  {
    label: 'Effective',
    value: '2',
  },
];

const cleFrequencyOptions = [
  {
    label: 'As announced, as well as a weekly summary of all programs',
    value: 1,
  },
  {
    label: 'Weekly Summary only',
    value: 0,
  },
];

const ebarContentTypesValues = {
  eBarCase: 1,
  eBarLegislativeEnactment: 2,
  eBarSCOAnnouncement: 3,
  eBarBoardOfProfessionalsConduct: 4,
  eBarAttorneyGeneralOpinion: 5,
  eBarEthicsCommissionAdvisoryOpinion: 6,
  eBarOtherAnnouncement: 7,
};

const areaOfLawSelectOption = [
  {
    label: 'All areas of law',
    value: 1,
  },
  {
    label: 'Customize my list',
    value: 2,
  },
];

const courtSelectOption = [
  {
    label: 'All courts',
    value: 1,
  },
  {
    label: 'Customize my courts',
    value: 2,
  },
];

const eNewsLetterFrequencyOptions = [
  {
    label: 'Daily',
    value: 1,
  },
  {
    label: 'Weekly',
    value: 2,
  },
];

export {
  cardOptions,
  monthOptions,
  expirationYearOptions,
  stateOptions,
  exportOptions,
  sCOAnnouncementsSubHeaderOptions,
  sCOAnnouncementsDateTypeOptions,
  cleFrequencyOptions,
  ebarContentTypesValues,
  areaOfLawSelectOption,
  courtSelectOption,
  eNewsLetterFrequencyOptions,
};
