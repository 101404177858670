import React from 'react';
import PropTypes from 'prop-types';

import CLEContentCard from '@components/CLEContentCard';

import DateStamp from './components/DateStamp';
import DescriptionContainer from './components/DescriptionContainer';
import DescriptionHeading from './components/DescriptionHeading';
import DescriptionPicture from './components/DescriptionPicture';

const Description = ({
  date,
  description,
  richDescription,
  displayPicture,
  richInstructions,
}) => (
  <CLEContentCard>
    <DateStamp>
      <span>Date:&nbsp;</span>
      <span>{date}</span>
    </DateStamp>
    <DescriptionHeading>Event Description</DescriptionHeading>
    <DescriptionContainer>
      {displayPicture && (
        <div>
          <DescriptionPicture
            src={displayPicture}
            id="cleDetailCoursePicture"
          />
        </div>
      )}
      <p>
        {(richDescription.length) /* eslint-disable-next-line react/no-danger */
          ? <span dangerouslySetInnerHTML={{ __html: richDescription }} />
          : <p>{description}</p>}
        {(richInstructions.length) /* eslint-disable-next-line react/no-danger */
          ? <span dangerouslySetInnerHTML={{ __html: richInstructions }} />
          : ''}
      </p>
    </DescriptionContainer>
  </CLEContentCard>
);

Description.displayName = 'Description';

Description.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  richDescription: PropTypes.string,
  displayPicture: PropTypes.string,
  richInstructions: PropTypes.string,
};

Description.defaultProps = {
  date: '',
  description: '',
  richDescription: '',
  displayPicture: '',
  richInstructions: '',
};

export default Description;
