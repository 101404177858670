import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import moment from 'moment';

import Button from '@style-guide/components/Button';

import { required } from '@services/FormValidators';
import ErrorMessage from '@components/ErrorMessage';
import FormFieldSet from '@components/FormFieldSet';
import FormFieldSubGroup from '@components/FormFieldSubGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormCheckbox from '@components/ReduxFormCheckbox';
import ReduxFormDatepicker from '@components/ReduxFormDatePicker';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormSelect from '@components/ReduxFormSelect';

import warn from './services/warn';
import validate from './services/validate';
import FlexWrapper from './components/FlexWrapper';
import SupremeCourtNumberField from './components/SupremeCourtNumberField';

const PersonalInfoForm = ({
  handleSubmit,
  hasSaveError,
  invalid,
  loading,
  pristine,
  salutations,
  suffixes,
  supremeCourtNumber,
  warning,
  isMember,
}) => (
  <form
    id="personalInfoForm"
    onSubmit={handleSubmit}
  >
    <FormFieldWrapper>
      <FormFieldSubGroup>
        <Field
          id="salutation"
          label="Salutation"
          name="Salutation"
          options={salutations.map(salutation => ({
            label: salutation,
            value: salutation,
          }))}
          component={ReduxFormSelect}
        />
        <Field
          id="suffix"
          label="Suffix"
          name="Suffix"
          options={suffixes.map(suffix => ({
            label: suffix,
            value: suffix,
          }))}
          component={ReduxFormSelect}
        />
      </FormFieldSubGroup>
    </FormFieldWrapper>
    <FormFieldWrapper>
      <FormFieldSet
        label="Birth Date"
        maxWidth="400px"
      >
        <FormFieldSubGroup hasMobileColumns>
          <Field
            component={ReduxFormDatepicker}
            id="birthDate"
            name="BirthDate"
            props={{
              scrollableYearDropdown: true,
              showYearDropdown: true,
              maxDate: moment(),
              yearDropdownItemNumber: 75,
            }}
          />
        </FormFieldSubGroup>
      </FormFieldSet>
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id="firstName"
        label="First Name*"
        name="FirstName"
        validate={required}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id="middleName"
        label="Middle Name"
        name="MiddleName"
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id="lastName"
        label="Last Name*"
        name="LastName"
        validate={required}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id="priorLastName"
        label="Prior Name"
        name="PriorLastName"
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id="preferredName"
        label="Preferred Name"
        name="PreferredName"
      />
    </FormFieldWrapper>
    {(!isMember || (isMember && supremeCourtNumber)) && (
    <FormFieldWrapper>
      <FlexWrapper>
        <p>Supreme Court Number*</p>
        {!supremeCourtNumber && <span>You must supply a supreme court number or opt out</span>}
        <Field
          component={SupremeCourtNumberField}
          id="ohioSupremeCourtNumber"
          maxLength="7"
          name="OhioSupremeCourtNumber"
          type="tel"
        />
      </FlexWrapper>
    </FormFieldWrapper>
    )}
    {(!isMember && !supremeCourtNumber) && (
      <FormFieldWrapper>
        <Field
          component={ReduxFormCheckbox}
          id="ohioSupremeCourtNumberOptOut"
          label="Opt Out"
          name="OhioSupremeCourtNumberOptOut"
          props={{
            value: true,
          }}
          style={{
            justifyContent: 'flex-start',
          }}
        />
      </FormFieldWrapper>
    )}
    {(!isMember || (isMember && supremeCourtNumber)) && (
    <FormFieldWrapper>
      <p>This number is needed for Attorney Identification and CLE credit</p>
    </FormFieldWrapper>
    )}
    {warning && (
      <FormFieldWrapper>
        <div>
          <ErrorMessage warn>{warning}</ErrorMessage>
        </div>
      </FormFieldWrapper>
    )}
    <Button
      disabled={(pristine || loading || invalid) && !hasSaveError}
      type="submit"
    >
      Save
    </Button>
  </form>
);

PersonalInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  salutations: PropTypes.arrayOf(PropTypes.string),
  suffixes: PropTypes.arrayOf(PropTypes.string),
  pristine: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
  warning: PropTypes.string,
  supremeCourtNumber: PropTypes.string,
  isMember: PropTypes.bool,
};

PersonalInfoForm.defaultProps = {
  salutations: [],
  suffixes: [],
  hasSaveError: false,
  warning: '',
  supremeCourtNumber: '',
  isMember: false,
};

PersonalInfoForm.displayName = 'PersonalInfoForm';

const Form = reduxForm({
  form: 'personalInfoForm',
  enableReinitialize: true,
  warn,
  validate,
})(PersonalInfoForm);

export default Form;
