import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { breakpoints } from '@style-guide/config';

import { required } from '@services/FormValidators';

import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormSelect from '@components/ReduxFormSelect';

const InvolvementContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.s}) {
    flex-direction: row;

    > div:not(:last-of-type) {
      margin-right: 15px;
    }
  }

  @media(min-width: ${breakpoints.l}) {
    flex-wrap: nowrap;
  }
`;

InvolvementContainer.displayName = 'InvolvementContainer';

const Involvement = ({
  involvement,
  involvementTypes,
  index,
}) => {
  const transformedInvolvementTypes = is.not.empty(involvementTypes)
    ?
    involvementTypes.map(involvementType => ({
      label: involvementType,
      value: involvementType,
      selected: false,
      disabled: false,
    }))
    :
    involvementTypes;

  // we need to add a default blank option
  transformedInvolvementTypes.unshift({
    label: '',
    value: '',
    selected: true,
    disabled: false,
  });

  return (
    <InvolvementContainer>
      <FormFieldWrapper>
        <Field
          id={`involvementType${index}`}
          label="Involvement Type*"
          name={`${involvement}.InvolvementType`}
          options={transformedInvolvementTypes}
          component={ReduxFormSelect}
          validate={required}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          id={`involvementName${index}`}
          label="Name*"
          name={`${involvement}.Name`}
          component={ReduxFormInput}
          validate={required}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          id={`involvementLeadershipPost${index}`}
          label="Leadership Post"
          name={`${involvement}.Position`}
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
    </InvolvementContainer>
  );
};

Involvement.propTypes = {
  involvement: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  involvementTypes: PropTypes.arrayOf(PropTypes.string),
};

Involvement.defaultProps = {
  involvementTypes: [],
};

Involvement.displayName = 'Involvement';

export default Involvement;
