import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import FormFieldGroup from '@components/FormFieldGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import FormFieldSubGroup from '@components/FormFieldSubGroup';
import FormHeader from '@components/FormHeader';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormTextAreaInput from '@components/ReduxFormTextAreaInput';
import ReduxFormDatePicker from '@components/ReduxFormDatePicker';
import ReduxFormSelect from '@components/ReduxFormSelect';
import Button from '@style-guide/components/Button';
import moment from 'moment';
import { sCOAnnouncementsSubHeaderOptions, sCOAnnouncementsDateTypeOptions } from '@config';
import FormButtonWrapper from './components/FormButtonWrapper';

const AnnForm = styled.form`
  width: 70%;
`;

AnnForm.displayName = 'AnnForm';

const SCOAnnouncementsForm = ({
  handleSubmit,
  pristine,
  onClickCloseCancel,
  edit,
  onSubmit,
  invalid,
}) => (
  <AnnForm
    id="sCOAnnouncementsForm"
    onSubmit={handleSubmit(onSubmit)}
  >
    <FormHeader>
      {edit ? 'Update Supreme Court of Ohio Announcement' : 'Create Supreme Court of Ohio Announcement'}
    </FormHeader>
    <FormFieldGroup>
      <FormFieldSubGroup>
        <FormFieldWrapper wide>
          <Field
            id="subHeaderString"
            label="Sub Header"
            options={sCOAnnouncementsSubHeaderOptions}
            name="subHeaderString"
            component={ReduxFormSelect}
            autoFocus
          />
        </FormFieldWrapper>
        <FormFieldWrapper wide>
          <Field
            name="title"
            id="title"
            type="text"
            label="Title"
            component={ReduxFormInput}
          />
        </FormFieldWrapper>
      </FormFieldSubGroup>
      <FormFieldWrapper wide>
        <Field
          name="summary"
          id="summary"
          label="Summary"
          props={{
            rows: '5',
            maxLength: '2000',
          }}
          component={ReduxFormTextAreaInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <Field
          name="cite"
          id="cite"
          type="text"
          label="Cite"
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
      <FormFieldSubGroup>
        <FormFieldWrapper wide>
          <Field
            name="cTALabel"
            id="cTALabel"
            type="text"
            label="CTA Label"
            component={ReduxFormInput}
          />
        </FormFieldWrapper>
        <FormFieldWrapper wide>
          <Field
            name="cTAUrl"
            id="cTAUrl"
            type="text"
            label="CTA URL"
            component={ReduxFormInput}
          />
        </FormFieldWrapper>
      </FormFieldSubGroup>
      <FormFieldSubGroup>
        <FormFieldWrapper wide>
          <Field
            id="dateTypeString"
            label="Date Type"
            options={sCOAnnouncementsDateTypeOptions}
            name="dateTypeString"
            component={ReduxFormSelect}
          />
        </FormFieldWrapper>
        <FormFieldWrapper wide>
          <Field
            component={ReduxFormDatePicker}
            id="effectivePostedDateString"
            label="Date"
            name="effectivePostedDateString"
            scrollableYearDropdown
            showYearDropdown
            yearDropdownItemNumber={50}
          />
        </FormFieldWrapper>
        <FormFieldWrapper wide>
          <Field
            component={ReduxFormDatePicker}
            id="activeDateString"
            label="Active Date"
            maxDate={moment().add(31, 'days')}
            name="activeDateString"
            scrollableYearDropdown
            showYearDropdown
            yearDropdownItemNumber={50}
          />
        </FormFieldWrapper>
      </FormFieldSubGroup>
    </FormFieldGroup>
    <FormButtonWrapper>
      <Button
        type="submit"
        disabled={pristine || invalid}
      >
        {edit ? 'Update' : 'Create'}
      </Button>
      <Button
        onClick={() => onClickCloseCancel()}
      >
        Cancel
      </Button>
    </FormButtonWrapper>
  </AnnForm>
);

SCOAnnouncementsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeaderString: PropTypes.string,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateTypeString: PropTypes.string,
    summary: PropTypes.string,
    activeDateString: PropTypes.string,
  }),
  onClickCloseCancel: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

SCOAnnouncementsForm.defaultProps = {
  initialValues: {
    subHeaderString: '',
    title: '',
    cite: '',
    cTALabel: '',
    cTAUrl: '',
    effectivePostedDateString: '',
    dateTypeString: '',
    dateType: 0,
    summary: '',
    activeDateString: '',
  },
};

export default reduxForm({
  form: 'sCOAnnouncementsForm',
  enableReinitialize: true,
})(SCOAnnouncementsForm);
