import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { breakpoints, colors, typography } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import ErrorMessage from '@components/ErrorMessage';
import FormFieldSection from '@components/FormFieldSection';
import Modal from '@components/Modal';
import SuccessMessage from '@components/SuccessMessage';

const PaymentDetailsSection = styled.div`
  display: block;
  flex-direction: column;

  > div:first-child {
    border-bottom: 1px solid ${colors.lightGray};
    margin-bottom: 30px;
  }

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;

    > div {
      width: 100%;

      &:first-child {
        border-bottom: none;
        margin-right: 30px;
        padding-right: 30px;
      }
    }
  }
`;

PaymentDetailsSection.displayName = 'PaymentDetailsSection';

const PaymentTypeContainer = styled.div`
  align-items: center;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0;
  max-width: ${breakpoints.l};

  button {
    margin-left: 10px;
  }

  @media (min-width: ${breakpoints.xs}) {
    flex-direction: row;
  }
`;

PaymentTypeContainer.displayName = 'PaymentTypeContainer';

const PaymentTypeBadge = styled.div`
  align-items: center;
  display: flex;
  margin: 10px 0;

  img {
    margin-right: 10px;
    width: 40px;
  }
`;

PaymentTypeBadge.displayName = 'PaymentTypeBadge';

const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 45vh;
  justify-content: center;

  span {
    font-size: ${rem('20px', typography.baseFontSize)};
    margin-bottom: 30px;
  }
`;

ModalContent.displayName = 'ModalContent';

class PaymentDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  render() {
    const {
      introHeading,
      introCopy,
      paymentType,
      creditCardPartial,
      creditCardExpirationDate,
      creditCardHasExpired,
      creditCardHasExpiredCopy,
      standingOrderDoesNotExistCopy,
      standingOrderId,
      removeSavedPayment,
      errorMessage,
      successMessage,
      loading,
    } = this.props;

    return (
      <React.Fragment>
        <FormFieldSection
          heading={introHeading}
          margin="30px 15px"
        >
          <PaymentDetailsSection>
            <div>{introCopy}</div>
            {!!standingOrderId && (
              <div>
                {errorMessage && (
                  <ErrorMessage>
                    <span>{errorMessage}</span>
                  </ErrorMessage>
                )}
                {successMessage && (
                  <SuccessMessage>
                    <span>{successMessage}</span>
                  </SuccessMessage>
                )}
                {!!paymentType && !successMessage && (
                  <React.Fragment>
                    <PaymentTypeContainer>
                      <div>
                        {paymentType === 'Paypal' && (
                          <PaymentTypeBadge>
                            <img
                              src="Static/img/paypal.png"
                              alt="PayPal Logo"
                            />
                            <span>PayPal</span>
                          </PaymentTypeBadge>
                        )}
                        {paymentType !== 'Paypal' && (
                          <React.Fragment>
                            <PaymentTypeBadge>
                              <img
                                src={`/Static/img/${paymentType}.png`}
                                alt="Credit Card"
                              />
                              {creditCardPartial && (
                                <span>************{creditCardPartial.slice(-4)}</span>
                              )}
                            </PaymentTypeBadge>
                            <p>Expires - {creditCardExpirationDate}</p>
                          </React.Fragment>
                        )}
                      </div>
                      {
                        // TODO: put this back in once aptify issue is resolved
                        // remove comments and remove curly braces surrounding div
                        //
                        // <div>
                        //   <Button
                        //     disabled={loading}
                        //     onClick={this.toggleModal}
                        //   >
                        //     Remove
                        //   </Button>
                        // </div>
                      }
                    </PaymentTypeContainer>
                    {creditCardHasExpired && paymentType !== 'Paypal' && (
                      <ErrorMessage warn>
                        <span>{creditCardHasExpiredCopy}</span>
                      </ErrorMessage>
                    )}
                  </React.Fragment>
                )}
                {!paymentType && !successMessage && (
                  <PaymentTypeContainer>
                    <div>No saved payment method.</div>
                  </PaymentTypeContainer>
                )}
              </div>
            )}
            {!standingOrderId && (
              <div>
                <PaymentTypeContainer>
                  {standingOrderDoesNotExistCopy}
                </PaymentTypeContainer>
              </div>
            )}
          </PaymentDetailsSection>
        </FormFieldSection>
        {this.state.isModalOpen && (
          <Modal
            closeAction={this.toggleModal}
            body={(
              <ModalContent>
                <span>Are you sure?</span>
                <Button
                  disabled={loading}
                  onClick={() => {
                    this.toggleModal();
                    removeSavedPayment();
                  }}
                >
                  Remove
                </Button>
              </ModalContent>
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

PaymentDetails.propTypes = {
  introHeading: PropTypes.string,
  introCopy: PropTypes.string,
  paymentType: PropTypes.string,
  creditCardPartial: PropTypes.string,
  creditCardExpirationDate: PropTypes.string,
  creditCardHasExpired: PropTypes.bool,
  creditCardHasExpiredCopy: PropTypes.string,
  standingOrderDoesNotExistCopy: PropTypes.string,
  standingOrderId: PropTypes.number.isRequired,
  removeSavedPayment: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  loading: PropTypes.bool,
};

PaymentDetails.defaultProps = {
  introHeading: '',
  introCopy: '',
  paymentType: '',
  creditCardPartial: '',
  creditCardExpirationDate: '',
  creditCardHasExpired: false,
  creditCardHasExpiredCopy: '',
  standingOrderDoesNotExistCopy: '',
  errorMessage: '',
  successMessage: '',
  loading: false,
};

PaymentDetails.displayName = 'PaymentDetails';

export default PaymentDetails;
