import React from 'react';
import PropTypes from 'prop-types';
import { injectGlobal } from 'styled-components';
import { colors } from '@style-guide/config';
import DashboardContainer from './components/DashboardContainer';
import DashboardHeroImage from './components/DashboardHeroImage';
import DashboardTools from './components/DashboardTools';

const DashboardHero = ({
  imgSrc,
  title,
  subtitle,
  buttonLabel,
  buttonLink,
  isMember,
  isMonthlyMember,
  memberType,
  membershipExpiration,
  alertMessage,
  quickLinks,
  myAccountOverviewLink,
  becomeAMemberLink,
  upgradeLink,
  autoRenew,
}) => {
  injectGlobal`
    body {
      background: linear-gradient(#747e82, ${colors.lightGray} 350px, ${colors.lightGray} 100%) no-repeat;
    }
  `;

  return (
    <DashboardContainer>
      <DashboardHeroImage
        imgSrc={imgSrc}
        title={title}
        subtitle={subtitle}
        buttonLabel={buttonLabel}
        buttonLink={buttonLink}
      />
      <DashboardTools
        isMember={isMember}
        alertMessage={alertMessage}
        memberType={memberType}
        isMonthlyMember={isMonthlyMember}
        membershipExpiration={membershipExpiration}
        quickLinks={quickLinks}
        myAccountOverviewLink={myAccountOverviewLink}
        becomeAMemberLink={becomeAMemberLink}
        upgradeLink={upgradeLink}
        autoRenew={autoRenew}
      />
    </DashboardContainer>
  );
};

DashboardHero.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  isMember: PropTypes.bool,
  isMonthlyMember: PropTypes.bool,
  memberType: PropTypes.string,
  membershipExpiration: PropTypes.string,
  alertMessage: PropTypes.string,
  quickLinks: PropTypes.arrayOf(Object),
  myAccountOverviewLink: PropTypes.string.isRequired,
  becomeAMemberLink: PropTypes.string.isRequired,
  upgradeLink: PropTypes.string.isRequired,
  autoRenew: PropTypes.bool.isRequired,
};

DashboardHero.defaultProps = {
  title: '',
  subtitle: '',
  buttonLabel: '',
  buttonLink: '',
  isMember: false,
  isMonthlyMember: false,
  memberType: '',
  membershipExpiration: '',
  alertMessage: '',
  quickLinks: [],
};

DashboardHero.displayName = 'DashboardHero';

export default DashboardHero;
