import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';

import { colors } from '@style-guide/config';

const IconContainer = styled.span`
  color: ${props => (props.isBookmarked ? colors.red : colors.black)};
  margin-left: 10px;
`;

IconContainer.displayName = 'IconContainer';

const ResourceBookmarkContainer = styled.button`
  font-size: 1rem;
  font-weight: bold;
  justify-content: center;
  display: inherit;
  align-items: flex-start;
  position: absolute;
  right: 0px;

  &:hover {
    color: ${colors.red};
    cursor: pointer;

    ${IconContainer} {
      color: ${colors.red};
    }
  }
`;

ResourceBookmarkContainer.displayName = 'ResourceBookmarkContainer';

const ResourceBookmarkUI = ({
  isBookmarked,
  saveBookmarkHandler,
}) => {
  const iconToRender = <FontAwesomeIcon icon={isBookmarked ? faBookmark : farBookmark} />;

  return (
    <React.Fragment>
      <ResourceBookmarkContainer onClick={saveBookmarkHandler}>
        {isBookmarked ? 'Saved to My Bookmarks' : 'Save to My Bookmarks'}
        <IconContainer isBookmarked={isBookmarked}> {iconToRender}</IconContainer>
      </ResourceBookmarkContainer>
    </React.Fragment>
  );
};


ResourceBookmarkUI.propTypes = {
  isBookmarked: PropTypes.bool.isRequired,
  saveBookmarkHandler: PropTypes.func.isRequired,
};

ResourceBookmarkUI.displayName = 'ResourceBookmarkUI';

export default ResourceBookmarkUI;
