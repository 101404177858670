import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import FocusTrap from 'focus-trap-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import ShoppingCartItems from '@views/Header/components/ShoppingCartItems';
import SubMenu from '../SubMenu';

import ShoppingCartNavItem from './components/ShoppingCartNavItem';
import ShoppingCartToggle from './components/ShoppingCartToggle';

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      document.addEventListener('keydown', this.handleEscape, true);
    }
  }

  handleClickOutside() {
    this.setState({
      isOpen: false,
    });
  }

  toggleOpenState() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleKeyPress({ charCode }) {
    if (charCode === 13) {
      this.toggleOpenState();
    }
  }

  handleEscape({ keyCode, key }) {
    if (keyCode === 27 || key === 'Escape') {
      this.setState({
        isOpen: false,
      });
    }
  }

  render() {
    const {
      checkoutLink,
      shoppingCartLink,
    } = this.props;

    const content = (
      <ShoppingCartItems
        checkoutLink={checkoutLink}
        shoppingCartLink={shoppingCartLink}
      />
    );

    return (
      <ShoppingCartNavItem
        isOpen={this.state.isOpen}
      >
        <FocusTrap
          active={this.state.isOpen}
        >
          <ShoppingCartToggle
            onClick={this.toggleOpenState}
            isOpen={this.state.isOpen}
            aria-expanded={this.state.isOpen}
            aria-haspopup="true"
            aria-label="Shopping Cart"
            id="desktopHeaderCartToggle"
          >
            <FontAwesomeIcon icon={faShoppingCart} aria-hidden="true" />
            <span>{this.props.count}</span>
          </ShoppingCartToggle>
          {this.state.isOpen && (
            <SubMenu
              heading="Shopping Cart"
              content={content}
              id="desktopHeaderCartContent"
            />
          )}
        </FocusTrap>
      </ShoppingCartNavItem>
    );
  }
}

ShoppingCart.propTypes = {
  checkoutLink: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  shoppingCartLink: PropTypes.string.isRequired,
};

const mapStateToProps = ({ cart }) => ({
  count: cart.lineItems.length,
});

ShoppingCart.displayName = 'ShoppingCart';

export default connect(mapStateToProps)(onClickOutside(ShoppingCart));
