import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

import Divider from '@components/Divider';

const FormFieldSectionContainer = styled.div`
  background-color: ${colors.white};
  padding: 15px;
  margin: ${props => (props.margin ? props.margin : '30px 0')};
`;

FormFieldSectionContainer.displayName = 'FormFieldSectionContainer';

const FormFieldSectionContent = styled.div`
  padding: 15px 0 0;

  @media (min-width: ${breakpoints.s}) {
    padding: 15px;
  }
`;

FormFieldSectionContent.displayName = 'FormFieldSectionContent';

const FormFieldSection = ({
  heading,
  children,
  margin,
}) => (
  <FormFieldSectionContainer margin={margin}>
    {heading && (
      <React.Fragment>
        <h3>{heading}</h3>
        <Divider />
      </React.Fragment>
    )}
    <FormFieldSectionContent>
      {children}
    </FormFieldSectionContent>
  </FormFieldSectionContainer>
);

FormFieldSection.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  margin: PropTypes.string,
};

FormFieldSection.defaultProps = {
  heading: '',
  children: undefined,
  margin: '',
};

export default FormFieldSection;
