import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import SectionList from './components/SectionList';

const SectionsContent = ({
  loading,
  sections,
  title,
  checkoutLink,
  error,
  isSectionAccordionOpen,
}) => {
  const errorMessage = typeof error === 'object' ?
    'An unknown error has occurred. Please try again later.'
    :
    error;

  return (
    <FormAccordionContainer>
      <FormAccordion
        isAccordionOpen={isSectionAccordionOpen}
        title={title}
        content={(
          <FormContainer>
            {loading && <LoadingOverlay />}
            {sections.Sections.length === 0 && (
              <h3>No sections found</h3>
            )}
            {sections.Sections.length > 0 && (
              <SectionList
                sections={sections.Sections}
                checkoutLink={checkoutLink}
              />
            )}
            {errorMessage && (
              <ErrorMessage>
                <span>{errorMessage}</span>
              </ErrorMessage>
            )}
          </FormContainer>
        )}
      />
    </FormAccordionContainer>
  );
};

SectionsContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  sections: PropTypes.shape({
    Sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      contentGuid: PropTypes.string,
      price: PropTypes.string,
      subscriptionType: PropTypes.string,
      selected: PropTypes.bool,
    })),
  }),
  title: PropTypes.string,
  checkoutLink: PropTypes.string,
  isSectionAccordionOpen: PropTypes.bool.isRequired,
};

SectionsContent.defaultProps = {
  error: '',
  loading: false,
  title: 'Sections',
  sections: {
    Sections: [],
  },
  checkoutLink: '',
};

const mapStateToProps = ({ sections }) => ({
  error: sections.error,
  loading: sections.loading,
  sections,
});

SectionsContent.displayName = 'SectionsContent';

export default connect(mapStateToProps)(SectionsContent);
