import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';

import { colors } from '@style-guide/config';

const Header = styled.button`
  align-items: center;
  border-bottom: ${props => ((props.isOpened || props.isStatic) ? `solid 2px ${transparentize(0.85, colors.white)}` : '0px')};
  cursor: ${props => (props.isStatic ? 'auto' : 'pointer')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 30px;
  width: 100%;
`;

Header.displayName = 'Header';

export default Header;
