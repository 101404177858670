import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import transparentize from 'polished/lib/color/transparentize';
import { breakpoints, typography, colors } from '@style-guide/config';

import TransparentOverlay from '@style-guide/mixins/TransparentOverlay';

import LinkButton from '@style-guide/components/LinkButton';

import OhioSVG from '@style-guide/svg/OhioSVG';

const HubCTAContainer = styled.div`
  background-color: ${colors.lightGray};
  margin-bottom: 20px;
  width: 100%;
  z-index: 1;

  @media (min-width: ${breakpoints.m}) {
    margin-left: 10px;
    margin-right: 10px;
    max-width: 500px;
    padding: 15px;
  }
`;

HubCTAContainer.displayName = 'HubCTAContainer';

const HubCTAHero = styled.div`
  ${TransparentOverlay()};

  align-items: center;
  background-image: url(${props => (props.heroImage)});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 240px;
  position: relative;

  svg {
    fill: ${transparentize(0.5, colors.white)};
    position: absolute;
    width: 115px;
    z-index: 1;
  }
`;

HubCTAHero.displayName = 'HubCTAHero';

const HubCTATitle = styled.h3`
  color: ${colors.white};
  font-size: ${rem('28px', typography.baseFontSize)};
  padding: 0 20px;
  text-align: center;
  z-index: 1;
`;

HubCTATitle.displayName = 'HubCTATitle';

const HubCTADescription = styled.div`
  background-color: ${colors.lightGray};
  padding: 15px;

  @media (min-width: ${breakpoints.m}) {
    background-color: transparent;
    padding: 15px 0 0;
  }
`;

HubCTADescription.displayName = 'HubCTADescription';

const HubCTA = ({
  image,
  title,
  subTitle,
  buttonType,
  buttonLink,
  buttonLabel,
}) => (
  <HubCTAContainer>
    <HubCTAHero
      heroImage={image}
    >
      <OhioSVG />
      <HubCTATitle>{title}</HubCTATitle>
    </HubCTAHero>
    <HubCTADescription>
      <p>{subTitle}</p>
      <LinkButton
        variant={buttonType}
        href={buttonLink}
      >
        {buttonLabel}
      </LinkButton>
    </HubCTADescription>
  </HubCTAContainer>
);

HubCTA.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  buttonType: PropTypes.string,
  buttonLink: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

HubCTA.defaultProps = {
  image: '',
  subTitle: '',
  buttonType: 'primary',
};

export default HubCTA;
