/* eslint-disable react/no-array-index-key */
// the keys in the array here the items are static and will not be resorted or regenerated
// so safe to use index as keys

import React from 'react';
import PropTypes from 'prop-types';

import MobileButton from './components/MobileButton';
import MobileButtons from './components/MobileButtons';
import DesktopButtons from './components/DesktopButtons';
import Wrapper from './components/Wrapper';
import TabButton from './components/TabButton';

const TabMenu = ({
  tabLabels,
  activeTab,
  updateTabState,
}) => (
  <Wrapper>
    <MobileButtons>
      {tabLabels.map((label, index) => (
        <MobileButton
          key={index}
          onClick={() => updateTabState(label)}
          variant={activeTab === label ? 'primary' : 'ghost'}
        >
          {label}
        </MobileButton>
      ))}
    </MobileButtons>
    <DesktopButtons>
      {tabLabels.map((label, index) => (
        <TabButton
          key={index}
          active={activeTab === label}
          onClick={() => updateTabState(label)}
          variant={activeTab === label ? 'primary' : 'ghost'}
        >
          {label}
        </TabButton>
      ))}
    </DesktopButtons>
  </Wrapper>
);

TabMenu.propTypes = {
  updateTabState: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  tabLabels: PropTypes.arrayOf(PropTypes.string),
};

TabMenu.defaultProps = {
  tabLabels: [],
};

TabMenu.displayName = 'TabMenu';

export default TabMenu;
