import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import map from 'lodash/map';

import Button from '@style-guide/components/Button';

import FormFieldSet from '@components/FormFieldSet';
import FormFieldWrapper from '@components/FormFieldWrapper';
import RadioButtonGroup from '@components/RadioButtonGroup';
import ReduxFormCheckbox from '@components/ReduxFormCheckbox';
import ReduxFormCheckboxGroup from '@components/ReduxFormCheckboxGroup';
import ReduxFormRadioButton from '@components/ReduxFormRadioButton';

const DemographicInfoForm = ({
  genders,
  handleSubmit,
  hasSaveError,
  languages,
  loading,
  pristine,
  races,
  sexualOrientations,
}) => {
  // shift 'Other' to end of array
  races.push(races.splice(races.findIndex(race => race.value === 'Other'), 1)[0]);

  const genderRadioButtons = genders.map(({ gender, id }) => (
    <li
      key={gender}
    >
      <Field
        id={`genderRadioButton${gender}`}
        label={gender}
        props={{
          value: id,
        }}
        name="Gender"
        type="radiobutton"
        component={ReduxFormRadioButton}
      />
    </li>
  ));

  const raceRadioButtons = races.map((race, index) => (
    <li
      key={race.key}
    >
      <Field
        id={`raceRadioButton${index}`}
        label={race.value}
        props={{
          value: race.value,
        }}
        name="RaceValue"
        type="radiobutton"
        component={ReduxFormRadioButton}
      />
    </li>
  ));

  const sexualOrientationRadioButtons = map(
    sexualOrientations,
    (orientation, index) => (
      <li
        key={index}
      >
        <Field
          id={`sexualOrientationRadioButton${index}`}
          label={orientation}
          props={{
            value: index,
          }}
          name="SexualOrientationId"
          type="radiobutton"
          component={ReduxFormRadioButton}
        />
      </li>
    ),
  );

  return (
    <form
      id="demographicInfoForm"
      onSubmit={handleSubmit}
    >
      <FormFieldWrapper>
        <FormFieldSet
          label="Gender"
        >
          <RadioButtonGroup>
            {genderRadioButtons}
          </RadioButtonGroup>
        </FormFieldSet>
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormFieldSet
          label="Race"
        >
          <RadioButtonGroup>
            {raceRadioButtons}
          </RadioButtonGroup>
        </FormFieldSet>
      </FormFieldWrapper>
      <FormFieldWrapper>
        <div>
          <Field
            id="isHispanic"
            label="Are you Hispanic/Latino?"
            name="IsHispanic"
            props={{
              value: true,
            }}
            type="checkbox"
            component={ReduxFormCheckbox}
          />
        </div>
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormFieldSet
          label="Sexual Orientation"
        >
          <RadioButtonGroup>
            {sexualOrientationRadioButtons}
          </RadioButtonGroup>
        </FormFieldSet>
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormFieldSet
          label="Languages"
        >
          <Field
            name="Languages"
            props={{
              options: languages,
              columns: 2,
            }}
            component={ReduxFormCheckboxGroup}
          />
        </FormFieldSet>
      </FormFieldWrapper>
      <Button
        type="submit"
        disabled={(pristine || loading) && !hasSaveError}
      >
        Save
      </Button>
    </form>
  );
};

DemographicInfoForm.propTypes = {
  genders: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  hasSaveError: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  races: PropTypes.arrayOf(PropTypes.object),
  /*
    The CMS is handing us an object back. This isn't ideal, but we were having MAJOR
    problems with Episerver trying to change the type of this property
  */
  // eslint-disable-next-line react/forbid-prop-types
  sexualOrientations: PropTypes.object,
};

DemographicInfoForm.defaultProps = {
  genders: [],
  hasSaveError: false,
  languages: [],
  races: [],
  sexualOrientations: {},
};

DemographicInfoForm.displayName = 'DemographicInfoForm';

export default reduxForm({
  form: 'demographicInfoForm',
  enableReinitialize: true,
})(DemographicInfoForm);
