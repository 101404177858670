import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';

import Button from '@style-guide/components/Button';

import ExperienceList from './components/ExperienceList';

const ExperienceInfoForm = ({
  handleSubmit,
  loading,
  pristine,
  hasSaveError,
}) => (
  <form
    id="experienceInfoForm"
    onSubmit={handleSubmit}
  >
    <FieldArray
      name="EmploymentHistory"
      component={ExperienceList}
      rerenderOnEveryChange
    />
    <Button
      type="submit"
      disabled={(pristine || loading) && !hasSaveError}
    >
        Save
    </Button>
  </form>
);

ExperienceInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
};

ExperienceInfoForm.defaultProps = {
  hasSaveError: false,
};

ExperienceInfoForm.displayName = 'ExperienceInfoForm';

export default reduxForm({
  form: 'experienceInfoForm',
  enableReinitialize: true,
})(ExperienceInfoForm);
