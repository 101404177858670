import React from 'react';
import PropTypes from 'prop-types';
import { UnmountClosed } from 'react-collapse';
import { presets } from 'react-motion';

import Arrow from './shared/Arrow';
import Body from './shared/Body';
import Header from './shared/Header';
import Title from './shared/Title';
import Wrapper from './shared/Wrapper';

import TextInput from './shared/TextInput';

class KeywordFilter extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpened: true,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  toggleCollapse() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened,
    }));
  }

  handleTextChange(e) {
    this.props.keywordUpdate(e.target.value);
  }

  render() {
    const {
      filterUpdate,
      filterValue,
      headerText,
      placeholder,
      collapsible,
      ...restProps
    } = this.props;
    const { isOpened } = this.state;

    return (
      <Wrapper
        {...restProps}
      >
        <Header
          isStatic={collapsible}
          onClick={collapsible ? this.toggleCollapse : undefined}
          isOpened={collapsible ? isOpened : undefined}
          id={collapsible ? 'filterKeywordCollapseToggle' : undefined}
        >
          <Title>{headerText}</Title>
          {collapsible ? <Arrow isOpened={isOpened} /> : undefined}
        </Header>
        <UnmountClosed
          presets={presets.gentle}
          isOpened={isOpened}
        >
          <Body
            id="filterKeywordWrapper"
          >
            <TextInput
              filterValue={filterValue}
              filterUpdate={filterUpdate}
              onChange={e => this.props.keywordUpdate(e.target.value)}
              placeholder={placeholder}
              filterField="keyword"
              inputId="filterKeywordInput"
              submitId="filterKeywordSubmit"
            />
          </Body>
        </UnmountClosed>
      </Wrapper>
    );
  }
}

KeywordFilter.propTypes = {
  collapsible: PropTypes.bool,
  filterUpdate: PropTypes.func.isRequired,
  keywordUpdate: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  filterValue: PropTypes.string,
  placeholder: PropTypes.string,
};

KeywordFilter.defaultProps = {
  collapsible: false,
  filterValue: '',
  placeholder: 'Keyword',
};

KeywordFilter.displayName = 'KeywordFilter';

export default KeywordFilter;
