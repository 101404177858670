import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';

import FormFieldGroup from '@components/FormFieldGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import FormFieldSubGroup from '@components/FormFieldSubGroup';
import FormHeader from '@components/FormHeader';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormTextAreaInput from '@components/ReduxFormTextAreaInput';
import ReduxFormDatePicker from '@components/ReduxFormDatePicker';
import Button from '@style-guide/components/Button';
import moment from 'moment';
import FormButtonWrapper from './components/FormButtonWrapper';

const AnnForm = styled.form`
  width: 70%;
`;

AnnForm.displayName = 'AnnForm';

const OtherAnnouncementsForm = ({
  handleSubmit,
  pristine,
  onClickCloseCancel,
  edit,
  onSubmit,
  invalid,
}) => (
  <AnnForm
    id="OtherAnnouncementsForm"
    onSubmit={handleSubmit(onSubmit)}
  >
    <FormHeader>
      {edit ? 'Update Other Announcement' : 'Create Other Announcement'}
    </FormHeader>
    <FormFieldGroup>
      <FormFieldSubGroup>
        <FormFieldWrapper wide>
          <Field
            name="title"
            id="title"
            type="text"
            label="Title"
            component={ReduxFormInput}
          />
        </FormFieldWrapper>
        <FormFieldWrapper wide>
          <Field
            name="partnerEntity"
            id="partnerEntity"
            type="text"
            label="Partner Entity"
            component={ReduxFormInput}
          />
        </FormFieldWrapper>
      </FormFieldSubGroup>
      <FormFieldWrapper wide>
        <Field
          name="summary"
          id="summary"
          label="Summary"
          props={{
            rows: '5',
            maxLength: '2000',
          }}
          component={ReduxFormTextAreaInput}
        />
      </FormFieldWrapper>
      <FormFieldSubGroup>
        <FormFieldWrapper wide>
          <Field
            name="cTALabel"
            id="cTALabel"
            type="text"
            label="CTA Label"
            component={ReduxFormInput}
          />
        </FormFieldWrapper>
        <FormFieldWrapper wide>
          <Field
            name="cTAUrl"
            id="cTAUrl"
            type="text"
            label="CTA URL"
            component={ReduxFormInput}
          />
        </FormFieldWrapper>
      </FormFieldSubGroup>
      <FormFieldSubGroup>
        <FormFieldWrapper wide>
          <Field
            component={ReduxFormDatePicker}
            id="postedDateString"
            label="Posted Date"
            maxDate={moment().add(31, 'days')}
            name="postedDateString"
            scrollableYearDropdown
            showYearDropdown
            yearDropdownItemNumber={50}
          />
        </FormFieldWrapper>
        <FormFieldWrapper wide>
          <Field
            component={ReduxFormDatePicker}
            id="activeDateString"
            label="Active Date"
            maxDate={moment().add(31, 'days')}
            name="activeDateString"
            scrollableYearDropdown
            showYearDropdown
            yearDropdownItemNumber={50}
          />
        </FormFieldWrapper>
      </FormFieldSubGroup>
    </FormFieldGroup>
    <FormButtonWrapper>
      <Button
        type="submit"
        disabled={pristine || invalid}
      >
        {edit ? 'Update' : 'Create'}
      </Button>
      <Button
        onClick={() => onClickCloseCancel()}
      >
        Cancel
      </Button>
    </FormButtonWrapper>
  </AnnForm>
);

OtherAnnouncementsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    partnerEntity: PropTypes.string,
    postedDateString: PropTypes.string,
    activeDateString: PropTypes.string,
    summary: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
  }),
  onClickCloseCancel: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

OtherAnnouncementsForm.defaultProps = {
  initialValues: {
    title: '',
    partnerEntity: '',
    postedDateString: '',
    activeDateString: '',
    summary: '',
    cTALabel: '',
    cTAUrl: '',
  },
};

export default reduxForm({
  form: 'OtherAnnouncementsForm',
  enableReinitialize: true,
})(OtherAnnouncementsForm);
