import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import is from 'is_js';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

import ResourcePreviewList from '@components/ResourcePreviewList';

import Divider from '@components/Divider';
import Subtitle from './Subtitle';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-bottom: 0px;
  width: 100%;
`;

Wrapper.displayName = 'Wrapper';


/**
 * These max-widths are 95% of the breakpoint width- this is a convention I saw in Bootstrap.
 * We need to talk about creating a standard page "container",
 * but this is just what I've been using the mean time.
 */
const WrapperInner = styled.div`
  padding-bottom: 60px;
  width: 100%;

  @media(min-width: ${breakpoints.s}) {
    width: 456px;
  }

  @media(min-width: ${breakpoints.m}) {
    width: 684px;
  }

  @media(min-width: ${breakpoints.l}) {
    width: 855px;
  }
`;

WrapperInner.displayName = 'WrapperInner';

const RecommendedArticlesSection = ({
  recommendedArticles,
  recommendedArticlesHeading,
}) => {
  if (is.not.empty(recommendedArticles)) {
    return (
      <Wrapper>
        <WrapperInner>
          {recommendedArticlesHeading && (
            <React.Fragment>
              <Subtitle>{recommendedArticlesHeading}</Subtitle>
              <Divider
                width="100%"
              />
            </React.Fragment>
          )}
          <ResourcePreviewList
            resources={recommendedArticles}
          />
        </WrapperInner>
      </Wrapper>
    );
  }

  return undefined;
};

RecommendedArticlesSection.propTypes = {
  recommendedArticles: PropTypes.arrayOf(PropTypes.object),
  recommendedArticlesHeading: PropTypes.string,
};

RecommendedArticlesSection.defaultProps = {
  recommendedArticles: [],
  recommendedArticlesHeading: '',
};

RecommendedArticlesSection.displayName = 'RecommendedArticlesSection';

export default RecommendedArticlesSection;
