import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media(min-width: ${breakpoints.m}) {
    padding: 65px 100px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
