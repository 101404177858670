import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from '@style-guide/components/LinkButton';

import CartActionsContainer from './components/CartActionsContainer';

const CartActions = ({
  checkoutDisabled,
  checkoutLink,
}) => (
  <CartActionsContainer>
    {!checkoutDisabled && (
    <LinkButton
      disabled={checkoutDisabled}
      id="cartPageCheckoutButton"
      href={checkoutLink}
    >
     Proceed to Checkout
    </LinkButton>
    )}
    {checkoutDisabled && (
    <LinkButton
      disabled={checkoutDisabled}
      id="cartPageCheckoutButton"
    >
     Proceed to Checkout
    </LinkButton>
    )}
  </CartActionsContainer>
);

CartActions.propTypes = {
  checkoutDisabled: PropTypes.bool,
  checkoutLink: PropTypes.string.isRequired,
};

CartActions.defaultProps = {
  checkoutDisabled: false,
};

CartActions.displayName = 'CartActions';

export default CartActions;
