import React from 'react';
import PropTypes from 'prop-types';
import { SpeakersGrid } from '@structures/Speakers';
import AddOnList from './components/AddOnList';
import CourseDetails from './components/CourseDetails';
import StyledContentArea from './components/StyledContentArea';

const CourseDetailsTab = ({
  instructors,
  inxpoClassroomLink,
  eventInstance,
  credits,
  parentMeeting,
  specializationCredits,
  podcastUrl,
  richCourseDescription,
  addOns,
}) => (
  <StyledContentArea>
    <CourseDetails
      eventInstance={eventInstance}
      credits={credits}
      parentMeeting={parentMeeting}
      specializationCredits={specializationCredits}
      inxpoClassroomLink={inxpoClassroomLink}
      podcastUrl={podcastUrl}
      richCourseDescription={richCourseDescription}
    />
    {!!instructors.length && (
      <SpeakersGrid
        speakers={instructors}
      />
    )}
    {!!addOns.length && (
      <AddOnList
        addOns={addOns}
      />
    )}
  </StyledContentArea>
);

CourseDetailsTab.propTypes = {
  instructors: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line
  eventInstance: PropTypes.object.isRequired,
  // eslint-disable-next-line
  parentMeeting: PropTypes.object.isRequired,
  credits: PropTypes.arrayOf(PropTypes.object).isRequired,
  specializationCredits: PropTypes.arrayOf(PropTypes.object),
  inxpoClassroomLink: PropTypes.string,
  podcastUrl: PropTypes.string,
  richCourseDescription: PropTypes.string,
  addOns: PropTypes.arrayOf(PropTypes.object),
};

CourseDetailsTab.defaultProps = {
  instructors: [],
  specializationCredits: [],
  inxpoClassroomLink: '',
  podcastUrl: '',
  richCourseDescription: '',
  addOns: [],
};

CourseDetailsTab.displayName = 'CourseDetailsTab';

export default CourseDetailsTab;
