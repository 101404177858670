import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors, typography } from '@style-guide/config';
import BriefcaseSVG from '@style-guide/svg/BriefcaseSVG';
import ScalesSVG from '@style-guide/svg/ScalesSVG';
import GradCapSVG from '@style-guide/svg/GradCapSVG';

import CookieUtil from '@services/Cookie';

const FlyoutClosedContentContainer = styled.div`
  cursor: pointer;
  line-height: 20px;
  padding: 20px;
  width: ${props => (props.wide ? '120px' : '100px')};

  > svg {
    font-size: ${rem('18px', typography.baseFontSize)};
    margin-top: .25em;
    display: block;
  }

  span svg {
    fill: ${colors.white};
    width: 30px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    span svg {
      height: 30px;
    }
  }
`;

FlyoutClosedContentContainer.displayName = 'FlyoutClosedContentContainer';

class FlyoutClosedContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      specialization: CookieUtil.get('cookie.visitor') || '',
    };
  }

  renderContent() {
    const LINK_ICONS = {
      help: <BriefcaseSVG />,
      professional: <ScalesSVG />,
      student: <GradCapSVG />,
    };
    const INDEX_MAP = {
      help: 0,
      professional: 1,
      student: 2,
    };
    const selectedSpecialization = this.props.actions[INDEX_MAP[this.state.specialization]];

    if (selectedSpecialization) {
      return (
        <span>
          {LINK_ICONS[this.state.specialization]}
          <br />
          {selectedSpecialization.text}
        </span>
      );
    }

    return (
      <span>{this.props.heading}</span>
    );
  }

  render() {
    return (
      <FlyoutClosedContentContainer
        wide={!!this.state.specialization}
        tabIndex="0"
        role="button"
        onClick={this.props.toggleOpenState}
        onKeyPress={this.props.onKeyPress}
        id="flyoutContainerClosed"
      >
        {this.renderContent()}
        <FontAwesomeIcon aria-hidden="true" icon={faAngleLeft} />
      </FlyoutClosedContentContainer>
    );
  }
}

FlyoutClosedContent.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  heading: PropTypes.string.isRequired,
  toggleOpenState: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
};

FlyoutClosedContent.defaultProps = {
  actions: [],
};

FlyoutClosedContent.displayName = 'FlyoutClosedContent';

export default FlyoutClosedContent;
