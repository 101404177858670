import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import ReactPlayer from 'react-player';
import FormFieldGroup from '@components/FormFieldGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import FormHeader from '@components/FormHeader';
import ReduxFormInput from '@components/ReduxFormInput';
import Button from '@style-guide/components/Button';
import { getMeetingName, checkVideoId } from '@services/CLEVideos';
import FormButtonWrapper from './components/FormButtonWrapper';

// const chunkSize = 1048576 * 3; // its 3MB, increase the number measure in mb

const VideoForm = ({
  handleSubmit,
  initialValues,
  pristine,
  onClickCloseCancel,
  edit,
  updateMeetingDetails,
  // updateFileName,
  setLoading,
  // showMessage,
  onSubmit,
  invalid,
}) => {
  // const [showProgress, setShowProgress] = useState(false);
  // const [counter, setCounter] = useState(1);
  // const [fileToBeUpload, setFileToBeUpload] = useState({});
  // const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  // const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  // const [progress, setProgress] = useState(0);
  // const [fileGuid, setFileGuid] = useState('');
  // const [fileName, setFileName] = useState('');
  // const [fileSize, setFileSize] = useState(0);
  // const [chunkCount, setChunkCount] = useState(0);
  const [meetingId, setMeetingId] = useState(0);
  const [meetingName, setMeetingName] = useState(edit ? initialValues.meetingTitle : '');
  const [descriptionId, setDescriptionId] = useState(edit ? initialValues.descriptionId : '');
  const [startDate, setStartDate] = useState(edit ? initialValues.startDate : '');
  const [endDate, setEndDate] = useState(edit ? initialValues.endDate : '');
  const [meetingIdError, setMeetingIdError] = useState('');
  const [formatCheck, setFormatCheck] = useState({
    meetingTitle: edit,
    checkpoint1: true,
    checkpoint2: true,
    checkpoint3: true,
    checkpoint4: true,
    minTime: true,
    videoIdCheck: true,
  });
  const [videoId, setVideoId] = useState(initialValues.videoLink);
  const [videoDuration, setVideoDuration] = useState('00:00:00');

  const player = React.createRef();

  const secondsToHms = (d) => {
    const duration = Number(d);
    const h = Math.floor(duration / 3600);
    const m = Math.floor((duration % 3600) / 60);
    const s = Math.floor((duration % 3600) % 60);

    const hour = h > 9 ? h.toString() : `0${h}`;
    const minute = m > 9 ? m.toString() : `0${m}`;
    const second = s > 9 ? s.toString() : `0${s}`;

    return `${hour}:${minute}:${second}`;
  };

  useEffect(() => {
    setTimeout(() => {
      if (player.current && /^\d+$/.test(videoId)) {
        console.log(player.current.getDuration());
        setVideoDuration(secondsToHms(player.current.getDuration()));
      } else {
        setVideoDuration('00:00:00');
      }
    }, 2000);
  }, [player]);

  // const fileInputRef = React.createRef();

  /* const resetChunkProperties = () => {
    // setShowProgress(true);
    setProgress(0);
    setCounter(1);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
  }; */

  /* const handleChange = (e) => {
    resetChunkProperties();
    const file = e.target.files[0];
    setFileName(file.name);
    setFileSize(file.size);
    updateFileName(file.name);

    const totalCount = file.size % chunkSize === 0
      ? file.size / chunkSize : Math.floor(file.size / chunkSize) + 1;

    setChunkCount(totalCount);
    setFileToBeUpload(file);
  }; */

  /* const uploadChunk = async (chunk) => {
    try {
      let data;
      await fetch(`/cleManageVideosCallToActionBlock/UploadChunks
        ?id=${counter}&name=${fileGuid}&meetingId=${meetingId}&totalChunks=${chunkCount}`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/octet-stream;charset=UTF-8',
        },
        body: chunk,
      })
        .then(res => res.json())
        .then((dt) => {
          data = dt;
        });
      if (data.IsSuccessStatusCode) {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        if (counter !== chunkCount) {
          const percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        } else {
          setProgress(100);
        }
      } else {
        showMessage(`An error has occured while uploading video! ${data.ReasonPhrase}`);
      }
    } catch (error) {
      showMessage('An error has occured while uploading video! Please try again.');
    }
  }; */

  /* const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      const chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk);
    }
  }; */

  const onChange = (e) => {
    const re = new RegExp('^(?:[0-9][0-9]):(?:[012345][0-9]):(?:[012345][0-9])$');
    if (!re.test(e.target.value) && e.target.value !== '') {
      setFormatCheck({
        ...formatCheck,
        [e.target.getAttribute('name')]: false,
      });
    } else {
      setFormatCheck({
        ...formatCheck,
        [e.target.getAttribute('name')]: true,
      });
    }
  };

  const getMeeting = (e) => {
    if (e.target.value !== '') {
      setLoading(true);
      setMeetingId(e.target.value);
      setMeetingName('');
      setDescriptionId('');
      setStartDate('');
      setEndDate('');
      getMeetingName(e.target.value)
        .then((dt) => {
          if (dt !== '') {
            if (dt === 'Already Exists') {
              setMeetingIdError('Meeting ID already exists, record will not be saved.');
              setFormatCheck({
                ...formatCheck,
                meetingTitle: false,
              });
            } else {
              const result = dt.split('|');
              updateMeetingDetails(result[0], result[1], result[2], result[3]);
              setMeetingName(result[0]);
              setDescriptionId(result[1]);
              setStartDate(result[2]);
              setEndDate(result[3]);
              setMeetingIdError('');
              setFormatCheck({
                ...formatCheck,
                meetingTitle: true,
              });
            }
          } else {
            setMeetingIdError('CLE not found!');
            setFormatCheck({
              ...formatCheck,
              meetingTitle: false,
            });
          }
        })
        .catch(() => {
          setMeetingIdError('CLE not found!');
          setFormatCheck({
            ...formatCheck,
            meetingTitle: false,
          });
        });
      setLoading(false);
    }
  };

  const setMeetingDetail = (name, id, startdate, enddate) => {
    updateMeetingDetails(name, id, startdate, enddate);

    return name;
  };

  const checkVideoIfExists = (e) => {
    if (e.target.value !== '') {
      setLoading(true);
      setVideoId(e.target.value);
      checkVideoId(e.target.value)
        .then((dt) => {
          setFormatCheck({
            ...formatCheck,
            videoIdCheck: !dt,
          });
        })
        .catch(() => {
          setFormatCheck({
            ...formatCheck,
            videoIdCheck: false,
          });
        });
      setLoading(false);
    }
  };

  // const uploadClick = () => {
  //   if (fileName !== fileGuid) {
  //     setFileGuid(fileName);
  //   }
  // };

  /* useEffect(() => {
    if (fileSize > 0) {
      fileUpload(counter);
    }
  }, [fileGuid, progress]); */

  return (
    <form
      id="videoForm"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <FormHeader>{edit ? 'Update CLE Video' : 'Create CLE Video'}</FormHeader>
      <FormFieldGroup>
        <FormFieldWrapper>
          <Field
            name="meetingID"
            id="meetingID"
            type="number"
            label="Product ID"
            component={ReduxFormInput}
            onBlur={e => getMeeting(e)}
            invalid={!formatCheck.meetingTitle}
            meta={{
              touched: !formatCheck.meetingTitle,
              error: meetingIdError,
            }}
            disabled={edit}
            autoFocus
          />
        </FormFieldWrapper>
        <FormFieldWrapper wide>
          <Field
            name="meetingTitle"
            id="meetingTitle"
            type="text"
            label="Meeting Title"
            input={{
              value: initialValues.meetingTitle !== '' ?
                setMeetingDetail(initialValues.meetingTitle, initialValues.descriptionId,
                  initialValues.startDate, initialValues.endDate)
                : meetingId !== 0 || edit ? meetingName : '',
            }}
            component={ReduxFormInput}
            disabled
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Field
            name="descriptionId"
            id="descriptionId"
            type="text"
            label="Description Id"
            input={{
              value: initialValues.descriptionId !== '' ? initialValues.descriptionId
                : meetingId !== 0 || edit ? descriptionId : '',
            }}
            component={ReduxFormInput}
            disabled
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Field
            name="startDate"
            id="startDate"
            type="text"
            label="Start Date"
            input={{
              value: initialValues.startDate !== '' ? initialValues.startDate
                : meetingId !== 0 || edit ? startDate : '',
            }}
            component={ReduxFormInput}
            disabled
          />
          <Field
            name="endDate"
            id="endDate"
            type="text"
            label="End Date"
            input={{
              value: initialValues.endDate !== '' ? initialValues.endDate
                : meetingId !== 0 || edit ? endDate : '',
            }}
            component={ReduxFormInput}
            disabled
          />
        </FormFieldWrapper>
        {/* <FormFieldWrapper wide>
          <Field
            name="videoTitle"
            id="videoTitle"
            type="text"
            label="Video Title"
            component={ReduxFormInput}
          />
        </FormFieldWrapper> */}
        <FormFieldWrapper>
          <Field
            name="videoLink"
            id="videoLink"
            type="text"
            label="Video ID"
            component={ReduxFormInput}
            onBlur={e => checkVideoIfExists(e)}
            invalid={!formatCheck.videoIdCheck}
            meta={{ touched: !formatCheck.videoIdCheck, error: 'Video Link already exists, record will not be saved.!' }}
          />
          <ReactPlayer
            id="videoplayer"
            ref={player}
            url={
              `https://player.vimeo.com/video/${videoId}?h=75209c19da&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0`
            }
            style={{ display: 'none' }}
          />
          <Field
            name="videoDuration"
            id="videoDuration"
            type="text"
            label="Video Duration"
            input={{
              value: videoDuration,
            }}
            component={ReduxFormInput}
            disabled
          />
        </FormFieldWrapper>
        {/* <FormFieldWrapper wide>
          <input
            ref={fileInputRef}
            withRef
            name="videoPath"
            id="videoPath"
            type="file"
            label="Video Path"
            onChange={handleChange}
            style={{ display: 'none' }}
          />
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              fileInputRef.current.click();
            }}
            disabled={!formatCheck.meetingTitle}
          >
            CHOOSE FILE
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              uploadClick();
            }}
            disabled={fileName === ''}
          >
            UPLOAD
          </Button>
          &nbsp;&nbsp;
          <input
            name="filePath"
            id="filePath"
            type="text"
            value={fileName}
            disabled
          />
          &nbsp;&nbsp;
          <input
            name="progress"
            id="progress"
            type="text"
            style={{ fontWeight: 'bold' }}
            value={showProgress ? `${Math.round(progress)}% Completed` : ''}
            disabled
          />
        </FormFieldWrapper> */}
        <FormFieldWrapper>
          <Field
            name="checkpoint1"
            id="checkpoint1"
            type="text"
            label="Checkpoint 1"
            component={ReduxFormInput}
            invalid={!formatCheck.checkpoint1}
            meta={{ touched: !formatCheck.checkpoint1, error: 'Please enter in HH:MM:SS format.' }}
            onChange={e => onChange(e)}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Field
            name="checkpoint2"
            id="checkpoint2"
            type="text"
            label="Checkpoint 2"
            component={ReduxFormInput}
            invalid={!formatCheck.checkpoint2}
            meta={{ touched: !formatCheck.checkpoint2, error: 'Please enter in HH:MM:SS format.' }}
            onChange={e => onChange(e)}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Field
            name="checkpoint3"
            id="checkpoint3"
            type="text"
            label="Checkpoint 3"
            component={ReduxFormInput}
            invalid={!formatCheck.checkpoint3}
            meta={{ touched: !formatCheck.checkpoint3, error: 'Please enter in HH:MM:SS format.' }}
            onChange={e => onChange(e)}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Field
            name="checkpoint4"
            id="checkpoint4"
            type="text"
            label="Checkpoint 4"
            component={ReduxFormInput}
            invalid={!formatCheck.checkpoint4}
            meta={{ touched: !formatCheck.checkpoint4, error: 'Please enter in HH:MM:SS format.' }}
            onChange={e => onChange(e)}
          />
        </FormFieldWrapper>
        {/* <FormFieldWrapper>
          <Field
            name="minTime"
            id="minTime"
            type="text"
            label="Minimum Time"
            component={ReduxFormInput}
            invalid={!formatCheck.minTime}
            meta={{ touched: !formatCheck.minTime, error: 'Please enter in HH:MM:SS format.' }}
            onChange={e => onChange(e)}
          />
        </FormFieldWrapper> */}
      </FormFieldGroup>
      <FormButtonWrapper>
        <Button
          type="submit"
          disabled={pristine || invalid ||
            !Object.values(formatCheck).every(value => value === true)}
        >
          {edit ? 'Update' : 'Create'}
        </Button>
        <Button
          onClick={() => onClickCloseCancel()}
        >
          Cancel
        </Button>
      </FormButtonWrapper>
    </form>
  );
};

VideoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    meetingID: PropTypes.string,
    meetingTitle: PropTypes.string,
    descriptionId: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    // videoTitle: PropTypes.string,
    videoLink: PropTypes.string,
    checkpoint1: PropTypes.string,
    checkpoint2: PropTypes.string,
    checkpoint3: PropTypes.string,
    checkpoint4: PropTypes.string,
    minTime: PropTypes.string,
  }),
  onClickCloseCancel: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  updateMeetingDetails: PropTypes.func.isRequired,
  // updateFileName: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  // showMessage: PropTypes.func.isRequired,
};

VideoForm.defaultProps = {
  initialValues: {
    meetingID: '',
    meetingTitle: '',
    descriptionId: '',
    startDate: '',
    endDate: '',
    // videoTitle: '',
    videoLink: '',
    checkpoint1: '',
    checkpoint2: '',
    checkpoint3: '',
    checkpoint4: '',
    minTime: '',
  },
};

export default reduxForm({
  form: 'videoForm',
  enableReinitialize: true,
})(VideoForm);
