import React from 'react';
import PropTypes from 'prop-types';

import { courseFormat as courseFormatPropTypes } from '@services/PropTypes';
import { SpeakersGrid } from '@structures/Speakers';
import SimilarCourses from '@components/SimilarCourses';

import CourseDetails from './components/CourseDetails';
import StyledContentArea from './components/StyledContentArea';

const CourseDetailsTab = ({
  instructors,
  inxpoClassroomLink,
  meetingInstance,
  credits,
  parentMeeting,
  similarCourses,
  practiceAreas,
  courseFormat,
  specializationCredits,
  podcastUrl,
  richCourseDescription,
  showCLEOnDemand,
  videoUrl,
}) => (
  <StyledContentArea>
    <CourseDetails
      meetingInstance={meetingInstance}
      credits={credits}
      parentMeeting={parentMeeting}
      practiceAreas={practiceAreas}
      courseFormat={courseFormat}
      specializationCredits={specializationCredits}
      inxpoClassroomLink={inxpoClassroomLink}
      podcastUrl={podcastUrl}
      richCourseDescription={richCourseDescription}
      showCLEOnDemand={showCLEOnDemand}
      videoUrl={videoUrl}
    />
    {!!instructors.length && (
      <SpeakersGrid
        speakers={instructors}
      />
    )}
    {!!similarCourses.length && (
      <SimilarCourses
        similarCourses={similarCourses}
      />
    )}
  </StyledContentArea>
);

CourseDetailsTab.propTypes = {
  instructors: PropTypes.arrayOf(Object),
  similarCourses: PropTypes.arrayOf(Object),
  // eslint-disable-next-line
  meetingInstance: PropTypes.object.isRequired,
  // eslint-disable-next-line
  parentMeeting: PropTypes.object.isRequired,
  credits: PropTypes.arrayOf(Object).isRequired,
  practiceAreas: PropTypes.arrayOf(Object).isRequired,
  courseFormat: courseFormatPropTypes.isRequired,
  specializationCredits: PropTypes.arrayOf(Object),
  inxpoClassroomLink: PropTypes.string,
  podcastUrl: PropTypes.string,
  richCourseDescription: PropTypes.string,
  showCLEOnDemand: PropTypes.func.isRequired,
  videoUrl: PropTypes.string,
};

CourseDetailsTab.defaultProps = {
  instructors: [],
  similarCourses: [],
  specializationCredits: [],
  inxpoClassroomLink: '',
  podcastUrl: '',
  richCourseDescription: '',
  videoUrl: '',
};

CourseDetailsTab.displayName = 'CourseDetailsTab';

export default CourseDetailsTab;
