import React from 'react';
import PropTypes from 'prop-types';

import Input from '@style-guide/components/Input';

import {
  reduxInputPropType,
  reduxMetaPropType,
} from '@services/PropTypes';

import FieldValidationError from '@components/FieldValidationError';

const ReduxFormInput = ({
  input,
  label,
  type,
  meta: {
    touched,
    error,
  },
  ...restProps
}) => (
  <div>
    <Input
      {...input}
      label={label}
      type={type}
      invalid={!!(touched && error)}
      {...restProps}
    />
    {touched && error && (
      <FieldValidationError>{error}</FieldValidationError>
    )}
  </div>
);

ReduxFormInput.propTypes = {
  input: reduxInputPropType.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  meta: reduxMetaPropType.isRequired,
};

ReduxFormInput.defaultProps = {
  type: 'text',
  label: '',
};

ReduxFormInput.displayName = 'ReduxFormInput';

export default ReduxFormInput;
