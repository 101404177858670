import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';

import Button from '@style-guide/components/Button';

import ErrorMessage from '@components/ErrorMessage';
import FormFieldSection from '@components/FormFieldSection';

import ButtonSectionWrapper from './components/ButtonSectionWrapper';
import CoverPhotoSection from './components/CoverPhotoSection';
import MediaUploadSection from './components/MediaUploadSection';

const MediaInfoForm = ({
  handleSubmit,
  loading,
  pristine,
  coverImageOptions,
  error,
  valid,
}) => (
  <form
    id="MediaInfoForm"
    onSubmit={handleSubmit}
  >
    <FormFieldSection
      heading="Profile Picture"
    >
      <Field
        name="profilePhoto"
        component={MediaUploadSection}
        props={{
          isValid: (width, height) => (width >= 300 && height >= 300),
        }}
        subtitle="Photos must be at least 300px by 300px &amp; no more more than 1mb."
      />
    </FormFieldSection>
    <FormFieldSection
      heading="Company Logo"
    >
      <Field
        name="companyLogo"
        component={MediaUploadSection}
        props={{
          isValid: (width, height) => (height >= 120),
        }}
        subtitle="Photos must be at least 120px tall &amp; no more more than 1mb."
      />
    </FormFieldSection>
    <FormFieldSection
      heading="Cover Photo"
    >
      <Field
        name="coverImage"
        component={CoverPhotoSection}
        coverImageOptions={coverImageOptions}
      />
    </FormFieldSection>
    <ButtonSectionWrapper>
      <Button
        type="submit"
        disabled={(pristine || loading) || (!valid && pristine)}
      >
        Save
      </Button>
      {error && (
        <ErrorMessage>
          <span>{error}</span>
        </ErrorMessage>
      )}
    </ButtonSectionWrapper>
  </form>
);

MediaInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  coverImageOptions: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
};

MediaInfoForm.defaultProps = {
  coverImageOptions: [],
  error: '',
};

MediaInfoForm.displayName = 'MediaInfoForm';

export default reduxForm({
  form: 'mediaInfoForm',
  enableReinitialize: true,
})(MediaInfoForm);
