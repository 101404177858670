import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Login from './components/Login';
import NavItem from './components/NavItem';
import ShoppingCart from './components/ShoppingCart';

const UtilityNavContainer = styled.ul`
  display: flex;
  justify-content: flex-end;
`;

UtilityNavContainer.displayName = 'UtilityNavContainer';

const UtilityNav = ({
  checkoutLink,
  loginLink,
  logoutLink,
  navItems,
  isAuthenticated,
  shoppingCartLink,
  userName,
}) => {
  const navItemsWithIDs = navItems.map((link, index) => ({
    id: index,
    ...link,
  }));

  const utilityNavigationItems = navItemsWithIDs.map(navItem => (
    <NavItem
      key={navItem.id}
      description={navItem.description}
      href={navItem.href}
      links={navItem.links}
      text={navItem.text}
      title={navItem.title}
      itemIndex={navItem.id}
    />
  ));

  return (
    <UtilityNavContainer
      id="desktopHeaderUtilityNav"
    >
      {utilityNavigationItems}
      <Login
        isAuthenticated={isAuthenticated}
        userName={userName}
        loginLink={loginLink}
        logoutLink={logoutLink}
      />
      {window.location.pathname !== '/checkout' && (
        <ShoppingCart
          checkoutLink={checkoutLink}
          shoppingCartLink={shoppingCartLink}
        />
      )}
    </UtilityNavContainer>
  );
};

UtilityNav.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  checkoutLink: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
  loginLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
  shoppingCartLink: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

UtilityNav.defaultProps = {
  navItems: [],
  isAuthenticated: false,
  userName: '',
};

UtilityNav.displayName = 'UtilityNav';

export default UtilityNav;
