import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import findIndex from 'lodash/findIndex';

import { breakpoints } from '@style-guide/config';

import removeNonSavedField from '@views/EditDirectory/services/removeNonSavedField';

import AutoSuggestInput from '@style-guide/components/AutoSuggestInput';
import FormFieldWrapper from '@components/FormFieldWrapper';
import RemoveButton from '@components/RemoveButton';

const County = styled.span`
  display: inline-block;
  margin: 5px 10px;

  button {
    margin: 0 0 0 10px;
  }
`;

County.displayName = 'County';

const CountiesContainer = styled.div`
  &:not(:empty) {
    margin: 10px 0;
  }

  @media (min-width: ${breakpoints.m}) {
    &:not(:empty) {
      margin: 10px 15px;
    }
  }
`;

CountiesContainer.displayName = 'CountiesContainer';

const createNewCounty = value => ({
  Deleted: false,
  Id: 0,
  Name: value || '',
  TopicCodeLinks: null,
  IsSaved: false,
});

const CountiesServed = ({
  fields,
  counties,
}) => {
  const renderCounty = (county, index) => {
    if (!fields.get(index).Deleted) {
      return (
        <County
          key={county}
        >
          <span>{fields.get(index).Name}</span>
          <RemoveButton
            action={() => removeNonSavedField(fields, index)}
          />
        </County>
      );
    }

    return undefined;
  };

  const getCountyIndex = (currentCounties, name) =>
    findIndex(currentCounties, county => (county.Name === name));

  return (
    <React.Fragment>
      <FormFieldWrapper>
        <AutoSuggestInput
          id="countiedServed"
          label="Counties Served"
          type="text"
          suggestions={counties}
          reduxInput={{
            onChange: (value) => {
              if (counties.indexOf(value) !== -1) {
                const countyIndex = getCountyIndex(fields.getAll(), value);

                if (countyIndex === -1) {
                  fields.unshift(createNewCounty(value));
                } else if (fields.get(countyIndex).Deleted) {
                  const reAddedCounty = JSON.parse(JSON.stringify(fields.get(countyIndex)));
                  reAddedCounty.Deleted = false;
                  fields.splice(countyIndex, 1, reAddedCounty);
                }
              }
            },
          }}
          onlySuggestedValuesAllowed
        />
      </FormFieldWrapper>
      <CountiesContainer>
        {fields.map(renderCounty)}
      </CountiesContainer>
    </React.Fragment>
  );
};

CountiesServed.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  counties: PropTypes.arrayOf(PropTypes.string),
};

CountiesServed.defaultProps = {
  fields: [],
  counties: [],
};

CountiesServed.displayName = 'CountiesServed';

export default CountiesServed;
