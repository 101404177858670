import styled from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 3px;
  color: ${props => (props.isDisabled ? colors.darkGray : colors.black)};

  > div {
    max-width: ${props => (props.wide ? '485px' : '300px')};
    width: 100%;
  }

  > div:not(:first-child) {
    margin: 30px 0 0 0;
  }
  
  label {
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: inherit !important;
  }

  @media (min-width: ${breakpoints.s}) {
    flex-direction: row;

    > div:not(:first-child) {
      margin: 0 0 0 10px;
    }
  }
`;

CheckBoxWrapper.displayName = 'CheckBoxWrapper';

export default CheckBoxWrapper;
