import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import transparentize from 'polished/lib/color/transparentize';
import FocusTrap from 'focus-trap-react';
import { breakpoints, colors, typography } from '@style-guide/config';
import OhioLogoSVG from '@style-guide/svg/OhioLogoSVG';
import LinkGroup from '@components/LinkGroup';

const MenuItem = styled.li`
  border-right: 1px solid ${transparentize(0.7, colors.white)};
  font-size: ${rem('13px', typography.baseFontSize)};
  flex-grow: 1;
  height: 40px;
  margin: 0 auto;

  button:hover {
    cursor: pointer;
  }

  > div {
    height: 100%;
    width: 100%;
  }

  &.open {
    box-shadow: inset 0 -5px ${colors.yellow};
  }
`;

MenuItem.displayName = 'MenuItem';

const NavItemStyles = css`
  align-items: center;
  color: ${colors.white};
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  padding: 0 1rem;
  text-align: center;
  width: 100%;
`;

const NavItemToggle = styled.button`
  ${NavItemStyles}
`;

NavItemToggle.displayName = 'NavItemToggle';


const NavItemLink = styled.a`
  ${NavItemStyles}
`;

NavItemLink.displayName = 'NavItemLink';

const SubMenu = styled.section`
  background-color: ${transparentize(0.1, colors.white)};
  display: flex;
  justify-content: space-around;
  left: 0;
  padding: 30px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 3;
`;

SubMenu.displayName = 'SubMenu';

const SubMenuDescription = styled.div`
  max-width: 280px;

  p {
    margin-left: 50px;
  }

  @media (min-width: ${breakpoints.xxl}) {
    max-width: 400px;
  }
`;

SubMenuDescription.displayName = 'SubMenuDescription';

const DescriptionHeading = styled.div`
  align-items: center;
  display: flex;
  line-height: 30px;

  h2 {
    margin-left: 10px;
  }
`;

DescriptionHeading.displayName = 'DescriptionHeading';

const SubMenuLinks = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
`;

SubMenuLinks.displayName = 'SubMenuLinks';

class NavItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.tempNavLink = JSON.parse(JSON.stringify(this.props.links));
    this.navLinkGroups = [this.tempNavLink];
    if (this.props.links.length > 5) {
      this.navLinkGroups.push(this.tempNavLink.splice(Math.round(this.props.links.length / 2)));
    }
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      document.addEventListener('keydown', this.handleEscape, true);
    }
  }

  handleClickOutside() {
    this.setState({
      isOpen: false,
    });
  }

  toggleOpenState() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleEscape({ keyCode, key }) {
    if (keyCode === 27 || key === 'Escape') {
      this.setState({
        isOpen: false,
      });
    }
  }

  render() {
    const { itemIndex } = this.props;

    const navItemClassNames = classNames({
      open: this.state.isOpen,
    });

    const renderSubmenuNavItem = () => {
      const navLinksGroupsWithIDs = this.navLinkGroups.map((navLinkGroup, index) => ({
        id: index,
        links: navLinkGroup,
      }));

      const navLinksGroupContainers = navLinksGroupsWithIDs.map(navLinkGroup => (
        <LinkGroup
          key={navLinkGroup.id}
          links={navLinkGroup.links}
        />
      ));

      return (
        <MenuItem
          className={navItemClassNames}
        >
          <FocusTrap
            active={this.state.isOpen}
          >
            <NavItemToggle
              onClick={this.toggleOpenState}
              aria-expanded={this.state.isOpen}
              aria-haspopup="true"
              title={this.props.title}
              id={`desktopHeaderMainNavItemToggle${itemIndex}`}
            >
              {this.props.text}
            </NavItemToggle>
            {this.state.isOpen && (
              <SubMenu
                id={`desktopHeaderMainNavItemContent${itemIndex}`}
              >
                <SubMenuDescription>
                  <DescriptionHeading>
                    <OhioLogoSVG
                      height="40px"
                      width="40px"
                    />
                    <h2>{this.props.text}</h2>
                  </DescriptionHeading>
                  <p>
                    {this.props.description}
                  </p>
                </SubMenuDescription>
                <SubMenuLinks>
                  {navLinksGroupContainers}
                </SubMenuLinks>
              </SubMenu>
            )}
          </FocusTrap>
        </MenuItem>
      );
    };

    const renderSimpleNavItem = () => (
      <MenuItem>
        <NavItemLink
          href={this.props.href}
          title={this.props.title}
        >
          {this.props.text}
        </NavItemLink>
      </MenuItem>
    );

    return (this.props.links.length) ? renderSubmenuNavItem() : renderSimpleNavItem();
  }
}

NavItem.propTypes = {
  description: PropTypes.string,
  href: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.object),
  text: PropTypes.string,
  title: PropTypes.string,
  itemIndex: PropTypes.number.isRequired,
};

NavItem.defaultProps = {
  description: '',
  href: '/',
  links: [],
  title: '',
  text: '',
};

NavItem.displayName = 'NavItem';

export default onClickOutside(NavItem);
