import React from 'react';
import PropTypes from 'prop-types';
import { injectGlobal } from 'styled-components';

import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';

import ImageGallery from '@style-guide/components/ImageGallery';
import PullQuote from '@style-guide/components/PullQuote';
import TextArea from '@style-guide/components/TextArea';
import VideoPlayer from '@style-guide/components/VideoPlayer';

import ContentCard from '@style-guide/structures/ContentCard';
import IconDivider from '@style-guide/structures/IconDivider';
import MapCTA from '@style-guide/structures/MapCTA';
import SubHubDescription from '@style-guide/structures/SubHubDescription';

import { colors } from '@style-guide/config';

import BecomeAMemberBlock from '@components/BecomeAMemberBlock';
import InlinePdf from '@components/InlinePdf';
import ResourcePreviewList from '@components/ResourcePreviewList';

import Contributors from './components/Contributors';
import RelatedCLEs from './components/RelatedCLEs';
import ResourceContentContainer from './components/ResourceContentContainer';
import ResourceUtilities from './components/ResourceUtilities';
import RestrictedOverlay from './components/RestrictedOverlay';
import Tags from './components/Tags';

const BLOCK_MAP = {
  ContentCardBlock: ContentCard,
  IconDividerBlock: IconDivider,
  ImageGalleryBlock: ImageGallery,
  GoogleMapsBlock: MapCTA,
  PdfViewerBlock: InlinePdf,
  PullQuoteBlock: PullQuote,
  SubHubDescriptionBlock: SubHubDescription,
  WysiwygBlock: TextArea,
  YouTubeVideoBlock: VideoPlayer,
};

const ResourceContent = ({
  content,
  contributors,
  tags,
  contentAreaName,
  isMember,
  pageId,
  isBookmarked,
  isSpecialFeature,
  isRestricted,
  createAccountUrl,
  relatedArticles,
  relatedCles,
}) => {
  injectGlobal`
    body {
      background: ${colors.white};
    }
  `;

  const resourceBlocks = JSON.parse(content).map((blockData) => {
    const Block = BLOCK_MAP[blockData.Type];
    const props = mapKeys(blockData.Block, (value, key) => camelCase(key));

    return (
      <Block
        key={blockData.Key}
        {...props}
      />
    );
  });

  return (
    <ResourceContentContainer
      isRestricted={isRestricted}
    >
      {isRestricted && (
        <RestrictedOverlay>
          <BecomeAMemberBlock
            title="GET FULL ACCESS"
            description="OSBA members have full access to all content on the website as well as many other member benefits."
            createAccountLink={createAccountUrl}
            hasBottomMargin
            hasMaxWidth={false}
          />
        </RestrictedOverlay>
      )}
      <ResourceUtilities
        isMember={isMember}
        pageId={pageId}
        isBookmarked={isBookmarked}
      />
      <div
        data-epi-property-name={contentAreaName}
        data-epi-property-render="none"
        data-epi-property-edittype="floating"
      >
        {resourceBlocks}
      </div>
      {isSpecialFeature && !!relatedArticles.length && (
        <ResourcePreviewList
          resources={relatedArticles}
        />
      )}
      {(!isSpecialFeature && !!contributors.length) && (
        <Contributors
          contributors={contributors}
        />
      )}
      {!!tags.length && (
        <Tags
          tags={tags}
        />
      )}
      {!!relatedCles.length && (
        <RelatedCLEs
          CLEs={relatedCles}
        />
      )}
    </ResourceContentContainer>
  );
};

ResourceContent.propTypes = {
  content: PropTypes.string.isRequired,
  contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  contentAreaName: PropTypes.string.isRequired,
  isMember: PropTypes.bool.isRequired,
  pageId: PropTypes.number.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isSpecialFeature: PropTypes.bool.isRequired,
  isRestricted: PropTypes.bool.isRequired,
  createAccountUrl: PropTypes.string.isRequired,
  relatedArticles: PropTypes.arrayOf(PropTypes.object),
  relatedCles: PropTypes.arrayOf(PropTypes.object),
};

ResourceContent.defaultProps = {
  relatedArticles: [],
  relatedCles: [],
};

ResourceContent.displayName = 'ResourceContent';

export default ResourceContent;
