import React from 'react';
import PropTypes from 'prop-types';
import { injectGlobal } from 'styled-components';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import without from 'lodash/without';
import { checkOrderAmount } from '@services/Payment';
import { colors } from '@style-guide/config';

import RightRailContainer from '@components/RightRailContainer';
import { SpeakersList } from '@structures/Speakers';
import UpcomingEvents from '@components/upcomingEvents';
import SubMenu from '@components/ShoppingCartPopUp/SubMenu';
import ShoppingCartItems from '@components/ShoppingCartPopUp/ShoppingCartItems/ShoppingCartItems';

import { Provider } from 'react-redux';
import Store from '@redux';

import AddOns from './components/AddOns';
import EventDetailBodyContainer from './components/EventDetailBodyContainer';
import EventDetailPageContainer from './components/EventDetailPageContainer';
import Description from './components/Description';
import Details from './components/Details';
import Header from './components/Header';
import MainColumnContainer from './components/MainColumnContainer';
import Confirmation from './components/Confirmation';

class EventDetail extends React.Component {
  constructor() {
    super();

    this.state = {
      addOnsActive: [],
      isRegisterType: true,
      isAddonComplete: true,
      windowWidth: window.innerWidth,
      isPurchaseComplete: false,
      showCartPopUp: false,
    };

    this.updateAddOnsActiveState = this.updateAddOnsActiveState.bind(this);
    this.updateWindowWidth = this.updateWindowWidth.bind(this);
    this.orderAmount = this.orderAmount.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.onPurchaseComplete = this.onPurchaseComplete.bind(this);
    this.updateShowCart = this.updateShowCart.bind(this);
    this.onClickCloseCancel = this.onClickCloseCancel.bind(this);

    this.store = Store.get();
  }

  componentDidMount() {
    injectGlobal`
      body {
        background: ${colors.lightGray};
      }
    `;

    window.addEventListener('resize', debounce(this.updateWindowWidth, 100));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.updateWindowWidth, 100));
  }

  onClickCloseCancel() {
    this.setState({
      showCartPopUp: false,
    });
  }

  onPurchaseComplete() {
    this.setState({
      isPurchaseComplete: true,
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  updateShowCart(flag) {
    this.setState({
      showCartPopUp: flag,
    });
  }

  handleResponse(data) {
    if (data && data.result) {
      this.setState({
        isRegisterType: true,
        isAddonComplete: true,
      });
    } else {
      this.setState({
        isRegisterType: false,
        isAddonComplete: true,
      });
    }
  }


  orderAmount(updatedAddons) {
    this.setState({
      isAddonComplete: false,
    });
    checkOrderAmount(this.props.contentGuid, this.state.note,
      this.state.attendanceType, updatedAddons)
      .then(this.handleResponse)
      .catch(this.handleResponse);
  }

  updateWindowWidth() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  updateAddOnsActiveState(id) {
    const currentAddOns = this.state.addOnsActive;
    const addOnAlreadyActive = includes(currentAddOns, id);
    let updatedAddons = [];
    if (addOnAlreadyActive) {
      this.setState({
        addOnsActive: without(currentAddOns, id),
      });
      updatedAddons = without(currentAddOns, id);
    } else {
      this.setState({
        addOnsActive: [
          ...currentAddOns,
          id,
        ],
      });
      updatedAddons = [...currentAddOns, id];
    }
    this.orderAmount(updatedAddons);
  }

  render() {
    const {
      description,
      richCourseDescription,
      location,
      displayName,
      displayPicture,
      speakers,
      brochureLink,
      contentGuid,
      zoomMeetingUrl,
      date,
      isMeetingAvailableUntilDate,
      startTime,
      endTime,
      prices,
      upcomingEvents,
      attendanceTypeOptions,
      addOns,
      hideInstance,
      isRegister,
      isAuthenticated,
      checkoutLink,
      shoppingCartLink,
      eventStoreLink,
      specializationCredits,
      credits,
      myDashboardLink,
      richInstructions,
      isMeetingEnded,
    } = this.props;

    const {
      windowWidth,
      isPurchaseComplete,
    } = this.state;

    const content = (
      <ShoppingCartItems
        checkoutLink={checkoutLink}
        shoppingCartLink={shoppingCartLink}
        continueShoppingLink={eventStoreLink}
        myDashboardLink={myDashboardLink}
      />
    );

    return (
      <React.Fragment>
        {this.state.showCartPopUp && isAuthenticated && (
          <Provider store={this.store}>
            <SubMenu
              heading="Shopping Cart"
              content={content}
              id="desktopHeaderCartContent"
              closeAction={this.onClickCloseCancel}
            />
          </Provider>
        )}
        { isPurchaseComplete === false && (
          <EventDetailPageContainer>
            <Header
              displayName={displayName}
            />
            <EventDetailBodyContainer>
              <MainColumnContainer>
                <Description
                  date={date}
                  displayPicture={displayPicture}
                  description={description}
                  richDescription={richCourseDescription}
                  richInstructions={richInstructions}
                />
                {!!addOns.length && (
                  <AddOns
                    addOns={addOns}
                    addOnsActive={this.state.addOnsActive}
                    updateAddOnsActiveState={this.updateAddOnsActiveState}
                  />
                )}
                {windowWidth < 900 && (
                  <Details
                    brochureLink={brochureLink}
                    contentGuid={contentGuid}
                    zoomMeetingUrl={zoomMeetingUrl}
                    date={date}
                    isMeetingAvailableUntilDate={isMeetingAvailableUntilDate}
                    startTime={startTime}
                    endTime={endTime}
                    prices={prices}
                    location={location}
                    attendanceTypeOptions={attendanceTypeOptions}
                    addOnsActive={this.state.addOnsActive}
                    disableAddToCart={hideInstance}
                    updateShowCart={this.updateShowCart}
                    isRegister={isRegister}
                    isRegisterType={this.state.isRegisterType}
                    isAddonComplete={this.state.isAddonComplete}
                    onPurchaseComplete={this.onPurchaseComplete}
                    specializationCredits={specializationCredits}
                    credits={credits}
                    isMeetingEnded={isMeetingEnded}
                  />
                )}
                {!!speakers.length && (
                  <SpeakersList
                    speakers={speakers}
                  />
                )}
                {!!upcomingEvents.length && (
                  <UpcomingEvents
                    upcomingEvents={upcomingEvents}
                  />
                )}
              </MainColumnContainer>
              {windowWidth >= 900 && (
                <RightRailContainer>
                  <Details
                    brochureLink={brochureLink}
                    contentGuid={contentGuid}
                    zoomMeetingUrl={zoomMeetingUrl}
                    date={date}
                    isMeetingAvailableUntilDate={isMeetingAvailableUntilDate}
                    endTime={endTime}
                    startTime={startTime}
                    prices={prices}
                    location={location}
                    attendanceTypeOptions={attendanceTypeOptions}
                    addOnsActive={this.state.addOnsActive}
                    disableAddToCart={hideInstance}
                    updateShowCart={this.updateShowCart}
                    isRegister={isRegister}
                    isRegisterType={this.state.isRegisterType}
                    isAddonComplete={this.state.isAddonComplete}
                    onPurchaseComplete={this.onPurchaseComplete}
                    specializationCredits={specializationCredits}
                    credits={credits}
                    isMeetingEnded={isMeetingEnded}
                  />
                </RightRailContainer>
              )}
            </EventDetailBodyContainer>
          </EventDetailPageContainer>
        )}
        { isPurchaseComplete === true && (
          <Confirmation
            displayName={displayName}
            zoomMeetingUrl={zoomMeetingUrl}
            date={date}
            endTime={endTime}
            startTime={startTime}
            location={location}
            addOns={addOns}
            addOnsActive={this.state.addOnsActive}
            eventStoreLink={eventStoreLink}
          />
        )}
      </React.Fragment>
    );
  }
}

EventDetail.displayName = 'EventDetail';

EventDetail.propTypes = {
  description: PropTypes.string,
  richCourseDescription: PropTypes.string,
  location: PropTypes.shape({
    displayName: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    line4: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  displayName: PropTypes.string,
  displayPicture: PropTypes.string,
  speakers: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    instructorCity: PropTypes.string,
    company: PropTypes.string,
    pictureLink: PropTypes.string,
  })),
  brochureLink: PropTypes.string,
  contentGuid: PropTypes.string,
  zoomMeetingUrl: PropTypes.string,
  date: PropTypes.string,
  isMeetingAvailableUntilDate: PropTypes.bool.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  prices: PropTypes.arrayOf(Object),
  upcomingEvents: PropTypes.arrayOf(Object),
  // eslint-disable-next-line
  attendanceTypeOptions: PropTypes.object,
  addOns: PropTypes.arrayOf(Object),
  hideInstance: PropTypes.bool,
  isRegister: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  checkoutLink: PropTypes.string,
  shoppingCartLink: PropTypes.string,
  eventStoreLink: PropTypes.string,
  specializationCredits: PropTypes.arrayOf(Object),
  credits: PropTypes.arrayOf(Object),
  myDashboardLink: PropTypes.string,
  richInstructions: PropTypes.string,
  isMeetingEnded: PropTypes.bool.isRequired,
};

EventDetail.defaultProps = {
  description: '',
  richCourseDescription: '',
  location: {
    displayName: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    city: '',
    state: '',
    postalCode: '',
  },
  displayName: '',
  displayPicture: '',
  speakers: [],
  brochureLink: '',
  contentGuid: '',
  zoomMeetingUrl: '',
  date: '',
  startTime: '',
  endTime: '',
  prices: [],
  upcomingEvents: [],
  attendanceTypeOptions: {},
  addOns: [],
  hideInstance: false,
  isRegister: false,
  isAuthenticated: false,
  checkoutLink: '',
  shoppingCartLink: '',
  eventStoreLink: '',
  specializationCredits: [],
  credits: [],
  myDashboardLink: '',
  richInstructions: '',
};

export default EventDetail;
