import React from 'react';
import PropTypes from 'prop-types';
import toString from 'lodash/toString';
import is from 'is_js';
import moment from 'moment';

import { colors } from '@style-guide/config';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import LogoSimpleSVG from '@style-guide/svg/LogoSimpleSVG';

import Button from '@style-guide/components/Button';
import DatePicker from '@style-guide/components/DatePicker';
import Select from '@style-guide/components/Select';

import Cookie from '@services/Cookie';
import Divider from '@components/Divider';

import DatePickerWrapper from './components/DatePickerWrapper';
import DesktopLogo from './components/DesktopLogo';
import DetailsItem from './components/DetailsItem';
import DetailsInner from './components/DetailsInner';
import DetailsTitle from './components/DetailsTitle';
import DetailsWrapper from './components/DetailsWrapper';
import MobileLogo from './components/MobileLogo';
import LogoWrapper from './components/LogoWrapper';
import OptionsInner from './components/OptionsInner';
import OptionsRadioButton from './components/OptionsRadioButton';
import OptionsTitle from './components/OptionsTitle';
import OptionsWrapper from './components/OptionsWrapper';
import SelectWrapper from './components/SelectWrapper';
import Subtitle from './components/Subtitle';
import Title from './components/Title';
import Wrapper from './components/Wrapper';

class BecomeAMemberBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      graduationDate: null,
      lawSchool: '',
      practiceAdmissionDate: null,
      selectedOption: null,
    };

    this.transformedOptions = is.not.empty(props.lawSchools)
      ?
      props.lawSchools.map(school => ({
        disabled: false,
        label: school,
        selected: false,
        value: school,
      }))
      :
      props.lawSchools;

    if (is.not.empty(this.transformedOptions)) {
      this.transformedOptions.unshift({
        disabled: true,
        label: '',
        selected: true,
        value: '',
      });
    }

    this.handleDetailsChange = this.handleDetailsChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderDetails = this.renderDetails.bind(this);
  }

  handleOptionChange(e) {
    this.setState({
      selectedOption: parseInt(e.target.value, 10),
    });
  }

  handleDetailsChange(field, value) {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState);

      newState[field] = value;

      return {
        ...newState,
      };
    });
  }

  handleSubmit() {
    const {
      graduationDate,
      lawSchool,
      practiceAdmissionDate,
      selectedOption,
    } = this.state;
    const { packageBuilderUrl } = this.props;
    // 0 is the ID for active attorney, 4 is for non-active/retired/emeritus lawyer
    const isAttorney = selectedOption === 0 || selectedOption === 4;

    let data = JSON.stringify({
      Date: null,
      LawSchool: null,
    });

    if (isAttorney) {
      data = JSON.stringify({
        Date: practiceAdmissionDate.format('YYYY-MM-DD'),
      });

      // set data cookie for active & emeritus lawyers, remove data cookies for students
      Cookie.set('AdmittedDate', practiceAdmissionDate.format('YYYY-MM-DD'), { path: '/' });
      Cookie.remove('GraduationYear', { path: '/' });
      Cookie.remove('LawSchool', { path: '/' });

      window.location = `${packageBuilderUrl}?type=${selectedOption}&data=${encodeURI(data)}`;
    // 3 is the ID for student
    } else if (selectedOption === 3) {
      data = JSON.stringify({
        Date: graduationDate.format('YYYY-MM-DD'),
        LawSchool: lawSchool,
      });

      // set data cookies for students & remove the cookies for active/emeritus attorneys
      Cookie.set('GraduationYear', graduationDate.format('YYYY-MM-DD'), { path: '/' });
      Cookie.set('LawSchool', lawSchool, { path: '/' });
      Cookie.remove('AdmittedDate', { path: '/' });

      window.location = `${packageBuilderUrl}?type=${selectedOption}&data=${encodeURI(data)}`;
    } else {
      window.location = `${packageBuilderUrl}?type=${selectedOption}&data=${encodeURI(data)}`;

      // remove all data cookies if the membership type is not a student,
      // active attorney, or emeritus lawayer
      Cookie.remove('GraduationYear', { path: '/' });
      Cookie.remove('LawSchool', { path: '/' });
      Cookie.remove('AdmittedDate', { path: '/' });
    }
  }

  renderDetails() {
    const {
      graduationDate,
      lawSchool,
      practiceAdmissionDate,
      selectedOption,
    } = this.state;

    let isBuildPackageDisabled = false;

    if (is.not.null(selectedOption)) {
      const isAttorney = selectedOption === 0 || selectedOption === 4;
      let detailsToRender;

      if (isAttorney) {
        isBuildPackageDisabled = !practiceAdmissionDate;
        detailsToRender = (
          <DetailsItem>
            <DetailsTitle>Date you were admitted to practice in any state
            </DetailsTitle>
            <DatePickerWrapper>
              <DatePicker
                maxDate={moment()}
                onSelect={value => this.handleDetailsChange('practiceAdmissionDate', value)}
                scrollableYearDropdown
                selected={practiceAdmissionDate}
                showYearDropdown
                yearDropdownItemNumber={50}
                placeholderText="mm/dd/yyyy"
              />
            </DatePickerWrapper>
          </DetailsItem>
        );
      } else if (selectedOption === 3) {
        isBuildPackageDisabled = !graduationDate || !lawSchool;
        detailsToRender = (
          <DetailsItem>
            <DetailsTitle>Law School Name*</DetailsTitle>
            {is.not.empty(this.transformedOptions) && (
              <SelectWrapper>
                <Select
                  id="lawSchool"
                  name="lawSchool"
                  onChange={e => this.handleDetailsChange('lawSchool', e.target.value)}
                  options={this.transformedOptions}
                  value={lawSchool}
                />
              </SelectWrapper>
            )}
            <DetailsTitle>Anticipated Date of Graduation*</DetailsTitle>
            <DatePickerWrapper>
              <DatePicker
                minDate={moment()}
                onSelect={value => this.handleDetailsChange('graduationDate', value)}
                scrollableYearDropdown
                selected={graduationDate}
                showYearDropdown
                yearDropdownItemNumber={6}
                placeholderText="mm/dd/yyyy"
              />
            </DatePickerWrapper>
          </DetailsItem>
        );
      }
      return (
        <DetailsWrapper>
          <Divider
            hasDecorator={false}
          />
          <DetailsInner>
            {detailsToRender}
            <DetailsItem>
              <Button
                disabled={isBuildPackageDisabled}
                onClick={() => this.handleSubmit()}
              >
                Build My Membership Plan
              </Button>
            </DetailsItem>
          </DetailsInner>
          <Divider
            hasDecorator={false}
          />
        </DetailsWrapper>
      );
    }

    return undefined;
  }

  render() {
    const {
      types,
      title,
      subHeading,
      description,
    } = this.props;

    return (
      <Wrapper>
        <YellowLineSVG />
        <Title>{title}</Title>
        <Subtitle
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        {types && (
          <React.Fragment>
            <OptionsTitle>{subHeading}</OptionsTitle>
            <OptionsWrapper>
              <OptionsInner>
                {types.map(type => (
                  <OptionsRadioButton
                    id={toString(type.id)}
                    isChecked={type.id === this.state.selectedOption}
                    key={type.id}
                    label={type.name}
                    name={type.name}
                    onChange={this.handleOptionChange}
                    value={type.id}
                  />
                ))}
              </OptionsInner>
              <LogoWrapper>
                <MobileLogo>
                  <LogoSimpleSVG
                    color={colors.lightGray}
                    height="120px"
                  />
                </MobileLogo>
                <DesktopLogo>
                  <LogoSimpleSVG
                    color={colors.lightGray}
                    height="200px"
                  />
                </DesktopLogo>
              </LogoWrapper>
            </OptionsWrapper>
          </React.Fragment>
        )}
        {this.renderDetails()}
      </Wrapper>
    );
  }
}

BecomeAMemberBlock.propTypes = {
  description: PropTypes.string,
  lawSchools: PropTypes.arrayOf(PropTypes.string).isRequired,
  packageBuilderUrl: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  title: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.object).isRequired,
};

BecomeAMemberBlock.defaultProps = {
  title: '',
  subHeading: '',
  description: '',
};

BecomeAMemberBlock.displayName = 'BecomeAMemberBlock';

export default BecomeAMemberBlock;
