import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '@style-guide/config';

import CLEContentCard from '@components/CLEContentCard';
import SpeakerCard from '@components/SpeakerCard';

import SpeakersContainer from './SpeakersContainer';

const SpeakerListItem = styled.div`
  border-bottom: 1px solid ${colors.lightGray};
  padding: 15px;
  width: 100%;
`;

SpeakerListItem.displayName = 'SpeakerListItem';

const SpeakersList = ({
  speakers,
}) => {
  const speakerCards = speakers.map(speaker => (
    <SpeakerListItem>
      <SpeakerCard
        key={speaker.sourceId}
        speaker={speaker}
      />
    </SpeakerListItem>
  ));

  return (
    <CLEContentCard>
      <h2>Speakers</h2>
      <SpeakersContainer>
        {speakerCards}
      </SpeakersContainer>
    </CLEContentCard>
  );
};

SpeakersList.displayName = 'SpeakersList';

SpeakersList.propTypes = {
  speakers: PropTypes.arrayOf(PropTypes.object),
};

SpeakersList.defaultProps = {
  speakers: [],
};

export default SpeakersList;
