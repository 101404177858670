import React from 'react';
import PropTypes from 'prop-types';

import Button from '@style-guide/components/Button';
import YellowLineSVG from '@style-guide/svg/YellowLineSVG';

import CopyWrapper from './components/CopyWrapper';
import Image from './components/Image';
import Subtitle from './components/Subtitle';
import Title from './components/Title';
import Wrapper from './components/Wrapper';

const DashboardHeroImage = ({
  imgSrc,
  title,
  subtitle,
  buttonLabel,
  buttonLink,
}) => (
  <Wrapper
    imgSrc={imgSrc}
  >
    <Image
      imgSrc={imgSrc}
    />
    <CopyWrapper>
      {(title || subtitle) && (
        <YellowLineSVG />
      )}
      {title && (
        <Title>{title}</Title>
      )}
      {subtitle && (
        <Subtitle>{subtitle}</Subtitle>
      )}
      {buttonLabel && buttonLink && (
        <Button
          onClick={() => { window.location = buttonLink; }}
        >
          <span Style="white-space:initial">{buttonLabel}</span>
        </Button>
      )}
    </CopyWrapper>
  </Wrapper>
);

DashboardHeroImage.propTypes = {
  /** a URL for the hero image. */
  imgSrc: PropTypes.string.isRequired,
  /** optional title. */
  title: PropTypes.string,
  /** optional subtitle. */
  subtitle: PropTypes.string,
  /** optional label for the button. */
  buttonLabel: PropTypes.string,
  /** optional link to navigate to when the button is pressed. */
  buttonLink: PropTypes.string,
};

DashboardHeroImage.defaultProps = {
  title: '',
  subtitle: '',
  buttonLabel: '',
  buttonLink: '',
};

DashboardHeroImage.displayName = 'DashboardHeroImage';

export default DashboardHeroImage;
