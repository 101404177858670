import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveLawSchoolInfo } from '@redux/modules/lawSchoolInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import LawSchoolInfoForm from './components/LawSchoolInfoForm';

class LawSchoolInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasWarning: false,
    };

    this.submit = this.submit.bind(this);
    this.setWarning = this.setWarning.bind(this);
  }

  setWarning(hasWarning) {
    this.setState({
      hasWarning,
    });
  }

  submit(formValues) {
    this.props.dispatch(saveLawSchoolInfo(formValues));
  }

  render() {
    const {
      lawSchoolInfo,
      lawSchools,
      loading,
      years,
    } = this.props;

    const initialValues = {
      GraduationYear: `${lawSchoolInfo.GraduationYear}`,
      LawSchoolName: lawSchoolInfo.LawSchoolName,
    };

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <LawSchoolInfoForm
                initialValues={initialValues}
                years={years}
                lawSchools={lawSchools.sort()}
                onSubmit={this.submit}
                loading={loading}
                setWarning={this.setWarning}
                hasSaveError={!!error}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
          hasWarning={this.state.hasWarning}
          title="Law School Information"
        />
      </FormAccordionContainer>
    );
  }
}

LawSchoolInfoContent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  lawSchoolInfo: PropTypes.shape({
    LawSchoolName: PropTypes.string,
    GraduationYear: PropTypes.number,
  }).isRequired,
  lawSchools: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
};

LawSchoolInfoContent.defaultProps = {
  error: '',
  loading: false,
};

const mapStateToProps = ({ lawSchoolInfo }) => ({
  error: lawSchoolInfo.error,
  lawSchoolInfo,
  loading: lawSchoolInfo.loading,
});

LawSchoolInfoContent.displayName = 'LawSchoolInfoContent';

export default connect(mapStateToProps)(LawSchoolInfoContent);
