import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';

import { colors } from '@style-guide/config';

const TabButton = styled.button`
  background-color: ${props => (props.active ? colors.red : transparentize(0.5, colors.white))};
  color: ${colors.white};
  font-weight: 700;
  text-transform: uppercase;
  border: 0;
  height: 65px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${colors.red};
  }
`;

TabButton.displayName = 'TabButton';

export default TabButton;
