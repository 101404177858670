import React from 'react';
import PropTypes from 'prop-types';

import Button from '@style-guide/components/Button';

import { courseFormat as courseFormatPropTypes } from '@services/PropTypes';

import MobileButtons from './components/MobileButtons';
import DesktopButtons from './components/DesktopButtons';
import Wrapper from './components/Wrapper';
import TabButton from './components/TabButton';
import ButtonWrapper from './components/ButtonWrapper';
import TabButtonWrapper from './components/TabButtonWrapper';

const TabMenu = ({
  activeTab,
  updateTabState,
  systemRequirementsExist,
  courseMaterialsExist,
  cleOnDemandExist,
  courseFormat,
}) => (
  <Wrapper>
    <MobileButtons>
      <ButtonWrapper>
        <Button
          onClick={() => updateTabState('courseDetails')}
          variant={activeTab === 'courseDetails' ? 'primary' : 'ghost'}
          style={{
            width: '100%',
          }}
        >
          Course Details
        </Button>
      </ButtonWrapper>
      {cleOnDemandExist && (
        <ButtonWrapper>
          <Button
            onClick={() => updateTabState('cleOnDemand')}
            variant={activeTab === 'cleOnDemand' ? 'primary' : 'ghost'}
            style={{
              width: '100%',
            }}
          >
            Video
          </Button>
        </ButtonWrapper>
      )}
      {courseMaterialsExist && (
        <ButtonWrapper>
          <Button
            onClick={() => updateTabState('courseMaterials')}
            variant={activeTab === 'courseMaterials' ? 'primary' : 'ghost'}
            style={{
              width: '100%',
            }}
          >
            Course Materials
          </Button>
        </ButtonWrapper>
      )}
      {systemRequirementsExist && (
        <ButtonWrapper>
          <Button
            onClick={() => updateTabState('systemRequirements')}
            variant={activeTab === 'systemRequirements' ? 'primary' : 'ghost'}
            style={{
              width: '100%',
            }}
          >
            System Requirements
          </Button>
        </ButtonWrapper>
      )}
      {courseFormat.sourceId === '30' && (
        <ButtonWrapper>
          <Button
            variant={activeTab === 'courseQuiz' ? 'primary' : 'ghost'}
            onClick={() => updateTabState('courseQuiz')}
            style={{
              width: '100%',
            }}
          >
            Course Quiz
          </Button>
        </ButtonWrapper>
      )}
    </MobileButtons>
    <DesktopButtons>
      <TabButtonWrapper>
        <TabButton
          onClick={() => updateTabState('courseDetails')}
          active={activeTab === 'courseDetails'}
        >
          Course Details
        </TabButton>
      </TabButtonWrapper>
      {courseMaterialsExist && (
        <TabButtonWrapper>
          <TabButton
            onClick={() => updateTabState('courseMaterials')}
            active={activeTab === 'courseMaterials'}
          >
            Course Materials
          </TabButton>
        </TabButtonWrapper>
      )}
      {cleOnDemandExist && (
        <TabButtonWrapper>
          <TabButton
            onClick={() => updateTabState('cleOnDemand')}
            active={activeTab === 'cleOnDemand'}
          >
            Video
          </TabButton>
        </TabButtonWrapper>
      )}
      {systemRequirementsExist && (
        <TabButtonWrapper>
          <TabButton
            active={activeTab === 'systemRequirements'}
            onClick={() => updateTabState('systemRequirements')}
          >
            System Requirements
          </TabButton>
        </TabButtonWrapper>
      )}
      {courseFormat.sourceId === '30' && (
        <TabButtonWrapper>
          <TabButton
            active={activeTab === 'courseQuiz'}
            onClick={() => updateTabState('courseQuiz')}
          >
            Course Quiz
          </TabButton>
        </TabButtonWrapper>
      )}
    </DesktopButtons>
  </Wrapper>
);

TabMenu.propTypes = {
  updateTabState: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  courseMaterialsExist: PropTypes.bool.isRequired,
  cleOnDemandExist: PropTypes.bool.isRequired,
  systemRequirementsExist: PropTypes.bool.isRequired,
  courseFormat: courseFormatPropTypes.isRequired,
};

TabMenu.displayName = 'TabMenu';

export default TabMenu;
