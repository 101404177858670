import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DatePicker from '@style-guide/components/DatePicker';

import {
  reduxInputPropType,
  reduxMetaPropType,
} from '@services/PropTypes';

import FieldValidationError from '@components/FieldValidationError';

const ReduxFormDatePicker = ({
  input,
  label,
  meta: {
    error,
  },
  ...restProps
}) => (
  <div>
    <DatePicker
      invalid={!!error}
      label={label}
      onSelect={(newValue) => {
        if (newValue) {
          input.onChange(newValue.format('l'));
        } else {
          input.onChange(null);
        }
      }}
      selected={input.value ? moment(input.value) : undefined}
      {...restProps}
    />
    {error && (
      <FieldValidationError>{error}</FieldValidationError>
    )}
  </div>
);

ReduxFormDatePicker.propTypes = {
  input: reduxInputPropType.isRequired,
  label: PropTypes.string,
  meta: reduxMetaPropType.isRequired,
  type: PropTypes.string,
};

ReduxFormDatePicker.defaultProps = {
  label: '',
  type: 'text',
};

ReduxFormDatePicker.displayName = 'ReduxFormDatePicker';

export default ReduxFormDatePicker;
