import React from 'react';
import PropTypes from 'prop-types';

import RecommendedArticlesSection from './components/RecommendedArticlesSection';
import SearchSection from './components/SearchSection';
import Wrapper from './components/Wrapper';

const PracticeLibrarySearch = ({
  introCopy,
  otherResources,
  otherResourcesHeading,
  practiceAreas,
  practiceAreaHeading,
  publications,
  publicationsHeading,
  recommendedArticles,
  recommendedArticlesHeading,
  searchPageUrl,
  searchHeading,
  userPracticeAreas,
}) => (
  <Wrapper>
    <SearchSection
      introCopy={introCopy}
      otherResources={otherResources}
      otherResourcesHeading={otherResourcesHeading}
      practiceAreas={practiceAreas}
      practiceAreaHeading={practiceAreaHeading}
      publications={publications}
      publicationsHeading={publicationsHeading}
      searchPageUrl={searchPageUrl}
      searchHeading={searchHeading}
      userPracticeAreas={userPracticeAreas}
    />
    <RecommendedArticlesSection
      recommendedArticles={recommendedArticles}
      recommendedArticlesHeading={recommendedArticlesHeading}
    />
  </Wrapper>
);

PracticeLibrarySearch.propTypes = {
  introCopy: PropTypes.string,
  otherResources: PropTypes.arrayOf(PropTypes.object),
  otherResourcesHeading: PropTypes.string,
  practiceAreas: PropTypes.arrayOf(PropTypes.object),
  practiceAreaHeading: PropTypes.string,
  publications: PropTypes.arrayOf(PropTypes.object),
  publicationsHeading: PropTypes.string,
  recommendedArticlesHeading: PropTypes.string,
  recommendedArticles: PropTypes.arrayOf(PropTypes.object),
  searchHeading: PropTypes.string,
  searchPageUrl: PropTypes.string.isRequired,
  userPracticeAreas: PropTypes.arrayOf(PropTypes.object),
};

PracticeLibrarySearch.defaultProps = {
  introCopy: '',
  otherResources: [],
  otherResourcesHeading: '',
  practiceAreas: [],
  practiceAreaHeading: '',
  publications: [],
  publicationsHeading: '',
  recommendedArticles: [],
  recommendedArticlesHeading: '',
  searchHeading: '',
  userPracticeAreas: [],
};

PracticeLibrarySearch.displayName = 'PracticeLibrarySearch';

export default PracticeLibrarySearch;
