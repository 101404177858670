import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import Button from '@style-guide/components/Button';
import ComponentDiv from './components/ComponentDiv';
import Name from './components/Name';

const ActionButton = styled.button`
  border: none;
  cursor: pointer;
`;

ActionButton.displayName = 'ActionButton';

const ViewColumn = styled.td`
  background-color: #FFFFFF;
`;

ViewColumn.displayName = 'ViewColumn';

const ImageStyle = {
  height: '24px',
  width: '24px',
};

const CLEVideosResult = ({
  initialValues,
  viewId,
  onClickViewButton,
  onClickEditButton,
  onClickCloseCancel,
  onClickDelete,
}) => (
  <React.Fragment>
    <tr style={{
      color: initialValues.videoLink == null || initialValues.videoLink === '' ? 'red' : '',
      backgroundColor: initialValues.videoLink == null || initialValues.videoLink === '' ? 'aliceblue' : '',
    }}
    >
      <td>{initialValues.meetingID}</td>
      <td>{initialValues.descriptionId}</td>
      <td>{initialValues.meetingTitle}</td>
      <td>{initialValues.videoLink}</td>
      <td>{initialValues.startDate != null ? moment(initialValues.startDate).format('MM/DD/YYYY') : ''}</td>
      <td>{initialValues.endDate != null ? moment(initialValues.endDate).format('MM/DD/YYYY') : ''}</td>
      <td>{initialValues.modifiedBy}</td>
      <td>
        <ActionButton
          title="Edit"
          onClick={() => onClickEditButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Edit.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="View"
          onClick={() => onClickViewButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Detail.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="Delete"
          onClick={() => onClickDelete(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Delete.png" alt="" style={ImageStyle} />
        </ActionButton>
      </td>
    </tr>
    {viewId && viewId === initialValues.contentGuidString && (
    <tr>
      <ViewColumn colSpan="6">
        <ComponentDiv>
          <Name>{initialValues.videoTitle}</Name>
          <p><span>Product ID: </span>{initialValues.meetingID}</p>
          <p><span>Meeting Title: </span>{initialValues.meetingTitle}</p>
          <p><span>Description ID: </span>{initialValues.descriptionId}</p>
          <p><span>Start Date: </span>{initialValues.startDate}</p>
          <p><span>End Date: </span>{initialValues.endDate}</p>
          {/* <p><span>Video Title: </span>{initialValues.videoTitle}</p> */}
          <p><span>Video ID: </span>{initialValues.videoLink}</p>
          {initialValues.checkpoint1 !== '' && initialValues.checkpoint1 !== null &&
            <p><span>Checkpoint 1: </span>{initialValues.checkpoint1}</p>}
          {initialValues.checkpoint2 !== '' && initialValues.checkpoint2 !== null &&
            <p><span>Checkpoint 2: </span>{initialValues.checkpoint2}</p>}
          {initialValues.checkpoint3 !== '' && initialValues.checkpoint3 !== null &&
            <p><span>Checkpoint 3: </span>{initialValues.checkpoint3}</p>}
          {initialValues.checkpoint4 !== '' && initialValues.checkpoint4 !== null &&
            <p><span>Checkpoint 4: </span>{initialValues.checkpoint4}</p>}
          {/* <p><span>Minimum Time: </span>{initialValues.minTime}</p> */}
          <p><span>Author: </span>{initialValues.modifiedBy}</p>
          <p><span>Added On: </span>{moment(initialValues.createdDate).format('MM/DD/YYYY')}</p>
          <Button
            onClick={() => onClickCloseCancel()}
          >
            Close
          </Button>
        </ComponentDiv>
      </ViewColumn>
    </tr>
    )}
  </React.Fragment>
);


CLEVideosResult.displayName = 'CLEVideosResult';

CLEVideosResult.propTypes = {
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    meetingID: PropTypes.string,
    meetingTitle: PropTypes.string,
    descriptionId: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    videoTitle: PropTypes.string,
    videoLink: PropTypes.string,
    checkpoint1: PropTypes.string,
    checkpoint2: PropTypes.string,
    checkpoint3: PropTypes.string,
    checkpoint4: PropTypes.string,
    minTime: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  viewId: PropTypes.string,
  onClickViewButton: PropTypes.func.isRequired,
  onClickEditButton: PropTypes.func.isRequired,
  onClickCloseCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

CLEVideosResult.defaultProps = {
  initialValues: {
    meetingID: '',
    meetingTitle: '',
    descriptionId: '',
    startDate: '',
    endDate: '',
    videoTitle: '',
    videoLink: '',
    checkpoint1: '',
    checkpoint2: '',
    checkpoint3: '',
    checkpoint4: '',
    minTime: '',
    createdDate: '',
    modifiedBy: '',
  },
  viewId: '',
};

export default CLEVideosResult;
