import React from 'react';
import PropTypes from 'prop-types';

import TextArea from '@style-guide/components/TextArea';
import styled from 'styled-components';
import YellowLine from './components/YellowLine';

import Wrapper from './components/Wrapper';

const ImageTag = styled.img`
  margin-bottom: 10px;
  max-width: 300px;
  max-height: 300px;
`;

ImageTag.displayName = 'ImageTag';

const AngleSpan = styled.span`
  font-weight: bold;
  color: #FDBA2B;
  padding-right: 5px;
  font-size: 14px;
`;

AngleSpan.displayName = 'AngleSpan';

const LinkTag = styled.a`
  color: #C3002F;
  text-decoration: underline;
  font-size: 14px;
`;

LinkTag.displayName = 'LinkTag';

const HeadingSpan = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

HeadingSpan.displayName = 'HeadingSpan';

const EBarFeatureBlock = ({
  hasBottomMargin,
  hasTopMargin,
  headline,
  image,
  summary,
  cTALabel,
  cTAUrl,
}) => (
  <Wrapper
    hasTopMargin={hasTopMargin}
    hasBottomMargin={hasBottomMargin}
  >
    {image && (
      <ImageTag src={image} alt="" />
    )}
    <YellowLine />
    <HeadingSpan>{headline}</HeadingSpan>
    <br />
    <TextArea
      htmlString={summary}
    />
    {cTALabel && cTAUrl && (
      <div>
        <AngleSpan>
          &gt;
        </AngleSpan>
        <LinkTag
          href={cTAUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cTALabel}
        </LinkTag>
      </div>
    )}
  </Wrapper>
);

EBarFeatureBlock.propTypes = {
  /** Whether or not a 30px bottom margin is applied. */
  hasBottomMargin: PropTypes.bool,
  /** Whether or not a 30px top margin is applied. */
  hasTopMargin: PropTypes.bool,
  headline: PropTypes.string,
  image: PropTypes.string,
  summary: PropTypes.string,
  cTALabel: PropTypes.string,
  cTAUrl: PropTypes.string,
};

EBarFeatureBlock.defaultProps = {
  hasBottomMargin: true,
  hasTopMargin: true,
  headline: 'Feature Block',
  image: '',
  summary: '',
  cTALabel: '',
  cTAUrl: '',
};

EBarFeatureBlock.displayName = 'EBarFeatureBlock';

export default EBarFeatureBlock;
