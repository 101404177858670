import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import Button from '@style-guide/components/Button';
import { sCOAnnouncementsSubHeaderOptions, sCOAnnouncementsDateTypeOptions } from '@config';
import ComponentDiv from './components/ComponentDiv';
import EBarSCOAnnouncementResultTr from './components/EBarSCOAnnouncementResultTr';
import Name from './components/Name';
import ListParagraph from '../../../EBarHome/components/EBarHome/EBarHomeContent/components/Shared/components/ListParagraph';


const ActionButton = styled.button`
  border: none;
  cursor: pointer;
`;

ActionButton.displayName = 'ActionButton';

const ViewColumn = styled.td`
  background-color: #FFFFFF;
`;

ViewColumn.displayName = 'ViewColumn';

const ImageStyle = {
  height: '24px',
  width: '24px',
};

const EBarSCOAnnouncementsResult = ({
  initialValues,
  viewId,
  onClickViewButton,
  onClickEditButton,
  onClickCloseCancel,
  onClickDelete,
}) => (
  <React.Fragment>
    <EBarSCOAnnouncementResultTr
      isActiveDateNull={!initialValues.activeDateString}
    >
      <td>{!initialValues.subHeaderString ? '' : sCOAnnouncementsSubHeaderOptions.filter(c =>
        c.value === initialValues.subHeaderString)[0].label}
      </td>
      <td>{initialValues.title}</td>
      <td>{initialValues.cite}</td>
      <td>{!initialValues.dateTypeString ? '' : sCOAnnouncementsDateTypeOptions.filter(c =>
        c.value === initialValues.dateTypeString)[0].label}
      </td>
      <td>{!initialValues.effectivePostedDateString ? '' : moment(initialValues.effectivePostedDateString).format('MM/DD/YYYY')}</td>
      <td>{initialValues.modifiedBy}</td>
      <td>{!initialValues.activeDateString ? '' : moment(initialValues.activeDateString).format('MM/DD/YYYY')}</td>
      <td>
        <ActionButton
          title="Edit"
          onClick={() => onClickEditButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Edit.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="View"
          onClick={() => onClickViewButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Detail.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="Delete"
          onClick={() => onClickDelete(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Delete.png" alt="" style={ImageStyle} />
        </ActionButton>
      </td>
    </EBarSCOAnnouncementResultTr>
    {viewId && viewId === initialValues.contentGuidString && (
    <tr>
      <ViewColumn colSpan="8">
        <ComponentDiv>
          <Name>{initialValues.title}</Name>
          <p>{!initialValues.subHeaderString ? '' : sCOAnnouncementsSubHeaderOptions.filter(c =>
            c.value === initialValues.subHeaderString)[0].label}
          </p>
          {/* eslint-disable-next-line react/no-danger */}
          <ListParagraph dangerouslySetInnerHTML={{ __html: initialValues.summary }} />
          <p><span>Cite: </span>{initialValues.cite}</p>
          <p>
            <span>
              {!initialValues.dateTypeString ? '' : sCOAnnouncementsDateTypeOptions.filter(c =>
                c.value === initialValues.dateTypeString)[0].label} Date:
            </span>&nbsp;{!initialValues.effectivePostedDateString ? '' : moment(initialValues.effectivePostedDateString).format('MM/DD/YYYY')}
          </p>
          <p><span>CTA Label:</span> {initialValues.cTALabel}</p>
          <p><span>CTA Url:</span> {initialValues.cTAUrl}</p>
          <p><span>Active Date:</span> {!initialValues.activeDateString ? '' : moment(initialValues.activeDateString).format('MM/DD/YYYY')}</p>
          <p><span>Author:</span> {initialValues.modifiedBy}</p>
          <p><span>Date Added:</span> {moment(initialValues.createdDate).format('MM/DD/YYYY')}</p>
          <Button
            onClick={() => onClickCloseCancel()}
          >
            Close
          </Button>
        </ComponentDiv>
      </ViewColumn>
    </tr>
    )}
  </React.Fragment>
);


EBarSCOAnnouncementsResult.displayName = 'EBarSCOAnnouncementsResult';

EBarSCOAnnouncementsResult.propTypes = {
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeaderString: PropTypes.string,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateTypeString: PropTypes.string,
    summary: PropTypes.string,
    createdDate: PropTypes.string,
    activeDateString: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  viewId: PropTypes.string,
  onClickViewButton: PropTypes.func.isRequired,
  onClickEditButton: PropTypes.func.isRequired,
  onClickCloseCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

EBarSCOAnnouncementsResult.defaultProps = {
  initialValues: {
    contentGuidString: '',
    subHeaderString: '',
    title: '',
    cite: '',
    cTALabel: '',
    cTAUrl: '',
    effectivePostedDateString: '',
    dateTypeString: '',
    summary: '',
    createdDate: '',
    activeDateString: '',
    modifiedBy: '',
  },
  viewId: '',
};

export default EBarSCOAnnouncementsResult;
