import React from 'react';
import PropTypes from 'prop-types';
import { UnmountClosed } from 'react-collapse';
import { presets } from 'react-motion';

import Checkbox from '@style-guide/components/Checkbox';

import Arrow from './shared/Arrow';
import Body from './shared/Body';
import ClearButton from './shared/ClearButton';
import ClearWrapper from './shared/ClearWrapper';
import Header from './shared/Header';
import Title from './shared/Title';
import Wrapper from './shared/Wrapper';

class CourtsFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: true,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened,
    }));
  }

  handleKeyPress(group) {
    this.toggleCollapse(group);
  }

  render() {
    const {
      filterValue,
      filterClearAll,
      filterAppendOrRemove,
      filterOptions,
      ...restProps
    } = this.props;

    const {
      isOpened,
    } = this.state;

    return (
      <Wrapper
        {...restProps}
      >
        <Header
          onClick={this.toggleCollapse}
          isOpened={isOpened}
          id="CourtsFilterCollapseToggle"
        >
          <Title>Courts</Title>
          <Arrow isOpened={isOpened} />
        </Header>
        <UnmountClosed
          presets={presets.gentle}
          isOpened={isOpened}
        >
          <ClearWrapper>
            &#40;
            <ClearButton
              onClick={() => filterClearAll()}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  filterClearAll();
                }
              }}
              tabIndex={0}
              id="areasOfLawClearAll"
            >
              Clear All
            </ClearButton>
            &#41;
          </ClearWrapper>

          <Body
            id="filterAreaOfLawWrapper"
          >
            <React.Fragment>
              {this.props.filterOptions.map((item, itemIndex) => {
                const isChecked = item.itemState;

                return (
                  <Checkbox
                    key={item.id}
                    label={item.name}
                    value={item.name}
                    onChange={() => filterAppendOrRemove(item.id, !item.itemState)}
                    id={`filterAreaOfLawOption${itemIndex}`}
                    isChecked={isChecked}
                    name="areasOfLaw"
                  />
                );
              })}
            </React.Fragment>
          </Body>
        </UnmountClosed>
      </Wrapper>
    );
  }
}

CourtsFilter.propTypes = {
  filterValue: PropTypes.arrayOf(PropTypes.object),
  filterClearAll: PropTypes.func.isRequired,
  filterAppendOrRemove: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.object),
};

CourtsFilter.defaultProps = {
  filterValue: [],
  filterOptions: [],
};

CourtsFilter.displayName = 'CourtsFilter';

export default CourtsFilter;
