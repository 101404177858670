import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoints, colors, stickyHeader } from '@style-guide/config';
import ClearFix from '@style-guide/mixins/ClearFix';
import Alert from '@components/Alert';
import Login from './components/Login';
import ShoppingCart from './components/ShoppingCart';
import NavMenu from './components/NavMenu';

const Logo = styled.a`
  display: flex;
  float: left;

  img {
    height: ${stickyHeader.stickyHeaderHeight};
  }
`;

Logo.displayName = 'Logo';

const MobileNavContainer = styled.section`
  ${ClearFix()}

  background-color: ${colors.darkBlue};
  border-bottom: 2px solid ${colors.white};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;

  @media (min-width: ${breakpoints.l}) {
    display: none;
  }

  > ul {
    float: right;
  }
`;

MobileNavContainer.displayName = 'MobileNavContainer';

const MobileNav = ({
  alertTitle,
  alertMessage,
  checkoutLink,
  headerData,
  headerLogo,
  primaryNavItems,
  isAuthenticated,
  loginLink,
  logoutLink,
  shoppingCartLink,
  userName,
}) => (
  <MobileNavContainer>
    {(alertTitle || alertMessage) && (
      <Alert
        title={alertTitle}
        message={alertMessage}
      />
    )}
    <Logo
      href="/?nr"
      id="mobileHeaderLogo"
    >
      <img src={headerLogo} alt="OSBA Logo" />
    </Logo>
    <ul>
      <Login
        isAuthenticated={isAuthenticated}
        loginLink={loginLink}
        logoutLink={logoutLink}
      />
      {window.location.pathname !== checkoutLink && (
        <ShoppingCart
          shoppingCartLink={shoppingCartLink}
          checkoutLink={checkoutLink}
        />
      )}
      <NavMenu
        primaryNavigationItems={primaryNavItems}
        functionalLinks={headerData.functionalLinks}
        legalLinks={headerData.legalLinks}
        isAuthenticated={isAuthenticated}
        shoppingCartLink={shoppingCartLink}
        userName={userName}
        flyoutData={headerData.flyout}
      />
    </ul>
  </MobileNavContainer>
);

MobileNav.propTypes = {
  alertTitle: PropTypes.string,
  alertMessage: PropTypes.string,
  checkoutLink: PropTypes.string.isRequired,
  headerData: PropTypes.shape({
    flyout: PropTypes.object,
    functionalLinks: PropTypes.array,
    legalLinks: PropTypes.array,
    primaryNavigationItems: PropTypes.array,
    utilityNavigationItems: PropTypes.array,
  }).isRequired,
  headerLogo: PropTypes.string.isRequired,
  primaryNavItems: PropTypes.arrayOf(PropTypes.object),
  isAuthenticated: PropTypes.bool,
  loginLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
  shoppingCartLink: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

MobileNav.defaultProps = {
  alertTitle: '',
  alertMessage: '',
  primaryNavItems: [],
  isAuthenticated: false,
  userName: '',
};

MobileNav.displayName = 'MobileNav';

export default MobileNav;
