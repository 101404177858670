import React from 'react';
import PropTypes from 'prop-types';
import styled, { injectGlobal } from 'styled-components';
import concat from 'lodash/concat';
import { breakpoints, colors } from '@style-guide/config';
import FooterAccordion from './components/FooterAccordion';

const PrimaryLinksContainer = styled.div`
  background-color: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  padding: 0;
  width: 100%;
  font-size: 14px;

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    padding: 80px 55px;
  }
`;

PrimaryLinksContainer.displayName = 'PrimaryLinksContainer';

const FooterPrimaryLinks = ({
  linkGroups,
  linkGroupsMember,
  linkGroupsNonMember,
  linkGroupsLoggedOut,
  isAuthenticated,
  isMember,
}) => {
  injectGlobal`
    [data-react-component="FooterPrimaryLinks"] {
      flex-grow: 1;
      width: 80%;
    }
  `;

  let visibleLinkGroups = linkGroups;
  let userRole;

  if (isAuthenticated) {
    if (isMember) {
      visibleLinkGroups = concat(linkGroupsMember, visibleLinkGroups);
      userRole = 'authedMember';
    } else {
      visibleLinkGroups = concat(linkGroupsNonMember, visibleLinkGroups);
      userRole = 'authedNonMember';
    }
  } else {
    visibleLinkGroups = concat(linkGroupsLoggedOut, visibleLinkGroups);
    userRole = 'unauthed';
  }

  const linkGroupsWithIDs = visibleLinkGroups.map((linkGroup, index) => ({
    id: index,
    ...linkGroup,
  }));

  const linkGroupAccordions = linkGroupsWithIDs.map((linkGroup, index) => {
    if (index === 0) {
      return (
        <FooterAccordion
          key={linkGroup.id}
          linkGroup={linkGroup}
          groupName={`footerLinks${userRole}`}
        />
      );
    }

    return (
      <FooterAccordion
        key={linkGroup.id}
        linkGroup={linkGroup}
        groupName={`footerPrimaryLinks${index}`}
      />
    );
  });

  return (
    <PrimaryLinksContainer
      id="footerPrimaryLinksContainer"
    >
      {linkGroupAccordions}
    </PrimaryLinksContainer>
  );
};

FooterPrimaryLinks.propTypes = {
  linkGroups: PropTypes.arrayOf(PropTypes.shape({
    titleLinkData: PropTypes.object,
    children: PropTypes.array,
  })),
  linkGroupsMember: PropTypes.arrayOf(PropTypes.shape({
    titleLinkData: PropTypes.object,
    children: PropTypes.array,
  })),
  linkGroupsNonMember: PropTypes.arrayOf(PropTypes.shape({
    titleLinkData: PropTypes.object,
    children: PropTypes.array,
  })),
  linkGroupsLoggedOut: PropTypes.arrayOf(PropTypes.shape({
    titleLinkData: PropTypes.object,
    children: PropTypes.array,
  })),
  isAuthenticated: PropTypes.bool,
  isMember: PropTypes.bool,
};

FooterPrimaryLinks.defaultProps = {
  linkGroups: [],
  linkGroupsMember: [],
  linkGroupsNonMember: [],
  linkGroupsLoggedOut: [],
  isAuthenticated: false,
  isMember: false,
};

FooterPrimaryLinks.displayName = 'FooterPrimaryLinks';

export default FooterPrimaryLinks;
