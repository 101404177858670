import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  colors,
  typography,
} from '@style-guide/config';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.darkGray};
  width: 100%;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

HeaderWrapper.displayName = 'HeaderWrapper';

const Title = styled.h2`
  color: ${colors.darkBlue};
  letter-spacing: 1px;
  font-size: ${rem('12px', typography.baseFontSize)};
  margin-bottom: 15px;
`;

Title.displayName = 'Title';

const Edition = styled.span`
  color: ${colors.darkRed};
  font-style: italic;
`;

Edition.displayName = 'Edition';

const Header = ({
  title,
  volumeNumber,
}) => (
  <HeaderWrapper>
    <Title>{title}</Title>
    {volumeNumber && (<Edition>VOL# {volumeNumber}</Edition>)}
  </HeaderWrapper>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  volumeNumber: PropTypes.string,
};

Header.defaultProps = {
  volumeNumber: '',
};

Header.displayName = 'Header';

export default Header;
