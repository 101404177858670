import React from 'react';
import PropTypes from 'prop-types';
import styled, { injectGlobal } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography, breakpoints, colors } from '@style-guide/config';
import NavigationLinks from './components/NavigationLinks';
import SocialLinks from './components/SocialLinks';

injectGlobal`
  [data-react-component="FooterSecondaryLinks"] {
    width: 100%;
  }
`;

const SecondaryLinksContainer = styled.div`
  align-items: center;
  background-color: ${colors.darkRed};
  display: flex;
  flex-direction: column;
  font-size: ${rem('14px', typography.baseFontSize)};
  font-weight: 700;
  justify-content: space-between;
  padding: 2rem 0 1.5rem;

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    padding: 0 2rem;
  }
`;

SecondaryLinksContainer.displayName = 'SecondaryLinksContainer';

const FooterSecondaryLinks = ({
  links,
  isAuthenticated,
  loginLink,
  logoutLink,
}) => {
  const { functionalLinks, socialLinks } = links;

  const functionalLinksWithIDs = functionalLinks.map((link, index) => ({
    id: index,
    ...link,
  }));

  const socialLinksWithIDs = socialLinks.map((link, index) => ({
    id: index,
    ...link,
  }));

  return (
    <SecondaryLinksContainer>
      <SocialLinks
        links={socialLinksWithIDs}
      />
      <NavigationLinks
        links={functionalLinksWithIDs}
        isAuthenticated={isAuthenticated}
        loginLink={loginLink}
        logoutLink={logoutLink}
      />
    </SecondaryLinksContainer>
  );
};

FooterSecondaryLinks.propTypes = {
  links: PropTypes.shape({
    functionalLinks: PropTypes.array,
    socialLinks: PropTypes.array,
  }),
  isAuthenticated: PropTypes.bool,
  loginLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
};

FooterSecondaryLinks.defaultProps = {
  links: {
    functionalLinks: [],
    socialLinks: [],
  },
  isAuthenticated: false,
};

FooterSecondaryLinks.displayName = 'FooterSecondaryLinks';

export default FooterSecondaryLinks;
