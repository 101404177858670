import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { required } from '@services/FormValidators';

import FormFieldGroup from '@components/FormFieldGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import FormFieldSubGroup from '@components/FormFieldSubGroup';
import FormHeader from '@components/FormHeader';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormSelect from '@components/ReduxFormSelect';
import ReduxFormTextAreaInput from '@components/ReduxFormTextAreaInput';
import ReduxFormDatePicker from '@components/ReduxFormDatePicker';
import Button from '@style-guide/components/Button';
import moment from 'moment';
import FormButtonWrapper from './components/FormButtonWrapper';

function buildCourtOrAreaOfLawOptions(filterOptions) {
  const selectOptions = [{ label: '--Select--', value: '' }];

  Object.values(filterOptions).forEach((option) => {
    selectOptions.push({
      label: option.name,
      value: option.id,
    });
  });

  return selectOptions;
}

const CaseForm = ({
  handleSubmit,
  pristine,
  onClickCloseCancel,
  edit,
  onSubmit,
  invalid,
  areasOfLaw,
  courts,
}) => (
  <form
    id="caseForm"
    onSubmit={handleSubmit(onSubmit)}
  >
    <FormHeader>{edit ? 'Update Case' : 'Create Case'}</FormHeader>
    <FormFieldWrapper>
      <span><b>*Required</b></span>
    </FormFieldWrapper>
    <FormFieldGroup>
      <FormFieldWrapper wide>
        <Field
          name="title"
          id="title"
          type="text"
          label="Case Title*"
          component={ReduxFormInput}
          validate={required}
          autoFocus
        />
        <Field
          name="caseName"
          id="caseName"
          type="text"
          label="Case Name"
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <Field
          name="summary"
          id="summary"
          label="Case Summary"
          props={{
            rows: '5',
            maxLength: '2000',
          }}
          component={ReduxFormTextAreaInput}
        />
      </FormFieldWrapper>
      <FormFieldSubGroup>
        <FormFieldWrapper wide>
          <Field
            name="practiceId"
            id="practiceId"
            label="Practice Area"
            options={buildCourtOrAreaOfLawOptions(areasOfLaw)}
            component={ReduxFormSelect}
          />
        </FormFieldWrapper>
        <FormFieldWrapper wide>
          <Field
            name="courtId"
            id="courtId"
            label="Court"
            options={buildCourtOrAreaOfLawOptions(courts)}
            component={ReduxFormSelect}
          />
        </FormFieldWrapper>
      </FormFieldSubGroup>
      <FormFieldWrapper wide>
        <Field
          name="webCitation"
          id="webCitation"
          type="text"
          label="Web Citation"
          component={ReduxFormInput}
        />
        <Field
          name="casemakerLink"
          id="casemakerLink"
          type="text"
          label="Decisis Link"
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <Field
          name="pDFLink"
          id="pDFLink"
          type="text"
          label="PDF Link"
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <FormFieldSubGroup>
          <Field
            component={ReduxFormDatePicker}
            id="dateDecidedString"
            label="Date Decided"
            maxDate={moment().add(31, 'days')}
            name="dateDecidedString"
            scrollableYearDropdown
            showYearDropdown
            yearDropdownItemNumber={50}
          />
          <Field
            component={ReduxFormDatePicker}
            id="activeDateString"
            label="Active Date"
            maxDate={moment().add(31, 'days')}
            name="activeDateString"
            scrollableYearDropdown
            showYearDropdown
            yearDropdownItemNumber={50}
          />
        </FormFieldSubGroup>
      </FormFieldWrapper>
    </FormFieldGroup>
    <FormButtonWrapper>
      <Button
        type="submit"
        disabled={pristine || invalid}
      >
        {edit ? 'Update' : 'Create'}
      </Button>
      <Button
        onClick={() => onClickCloseCancel()}
      >
        Cancel
      </Button>
    </FormButtonWrapper>
  </form>
);

CaseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    caseName: PropTypes.string,
    webCitation: PropTypes.string,
    casemakerLink: PropTypes.string,
    pDFLink: PropTypes.string,
    dateDecidedString: PropTypes.string,
    activeDateString: PropTypes.string,
    dateAddedString: PropTypes.string,
    authoredBy: PropTypes.string,
    practiceId: PropTypes.number,
    courtId: PropTypes.number,
  }),
  onClickCloseCancel: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  areasOfLaw: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  courts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
};

CaseForm.defaultProps = {
  initialValues: {
    summary: '',
    caseName: '',
    webCitation: '',
    casemakerLink: '',
    pDFLink: '',
    dateDecidedString: '',
    activeDateString: '',
    dateAddedString: '',
    authoredBy: '',
  },
};

export default reduxForm({
  form: 'caseForm',
  enableReinitialize: true,
})(CaseForm);
