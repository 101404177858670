import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import transparentize from 'polished/lib/color/transparentize';
import { Caret } from '@style-guide/mixins';
import { typography, colors } from '@style-guide/config';

const caretSettings = {
  color: colors.white,
};

const NavAccordionToggleButton = styled.button`
  ${Caret(caretSettings)}

  align-items: center;
  border-bottom: 2px solid transparent;
  color: ${colors.white};
  display: flex;
  font-size: ${rem('16px', typography.baseFontSize)};
  height: ${rem('70px', typography.baseFontSize)};
  justify-content: space-between;
  padding: 0 30px 0 20px;
  width: 100%;

  &.open {
    border-bottom: 2px solid ${transparentize(0.8, colors.white)};
  }

  &:not(.open) {
    transition: 100ms border 400ms;
  }
`;

NavAccordionToggleButton.displayName = 'NavAccordionToggleButton';

const NavAccordionToggle = ({
  isOpen,
  toggleOpenState,
  title,
  text,
  id,
}) => (
  <NavAccordionToggleButton
    className={isOpen ? 'open' : ''}
    aria-expanded={isOpen}
    onClick={toggleOpenState}
    title={title}
    id={id}
  >
    {text}
  </NavAccordionToggleButton>
);

NavAccordionToggle.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  toggleOpenState: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

NavAccordionToggle.defaultProps = {
  isOpen: false,
  text: '',
};

NavAccordionToggle.displayName = 'NavAccordionToggle';

export default NavAccordionToggle;
