import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';
import { colors } from '@style-guide/config';

const NavAccordionContentSection = styled.section`
  max-height: 0;
  overflow: hidden;
  padding: 0 25px 0 45px;
  transition: 500ms max-height;

  &.open {
    max-height: 150vh;
  }

  li,
  a {
    color: ${colors.white};
  }

  li {
    margin: 0;

    &:not(:last-child) {
      border-bottom: 2px solid ${transparentize(0.8, colors.white)};
    }

    > a {
      align-items: center;
      display: flex;
      height: 50px;
    }
  }

  ul:only-child li:last-child {
    border-bottom: none;
  }
`;

NavAccordionContentSection.displayName = 'NavAccordionContentSection';

const NavAccordionContent = ({
  isOpen,
  content,
  id,
}) => (
  <NavAccordionContentSection
    className={isOpen ? 'open' : ''}
    id={id}
  >
    {content}
  </NavAccordionContentSection>
);

NavAccordionContent.propTypes = {
  isOpen: PropTypes.bool,
  content: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

NavAccordionContent.defaultProps = {
  isOpen: false,
};

NavAccordionContent.displayName = 'NavAccordionContent';

export default NavAccordionContent;
