import React from 'react';
import { connect } from 'react-redux';
import { touch } from 'redux-form';
import PropTypes from 'prop-types';

import AutoSuggestInput from '@style-guide/components/AutoSuggestInput';

import {
  reduxInputPropType,
  reduxMetaPropType,
} from '@services/PropTypes';
import FieldValidationError from '@components/FieldValidationError';

const ReduxFormAutoSuggestInput = ({
  dispatch,
  input,
  label,
  type,
  suggestions,
  onlySuggestedValuesAllowed,
  meta: {
    touched,
    error,
    form,
  },
  ...restProps
}) => {
  const setTouched = () => dispatch(touch(form, [input.name]));

  return (
    <div>
      <AutoSuggestInput
        reduxInput={input}
        label={label}
        type={type}
        invalid={!!(touched && error)}
        suggestions={suggestions}
        onlySuggestedValuesAllowed={onlySuggestedValuesAllowed}
        setTouched={setTouched}
        {...restProps}
      />
      {touched && error && (
        <FieldValidationError>{error}</FieldValidationError>
      )}
    </div>
  );
};

ReduxFormAutoSuggestInput.propTypes = {
  input: reduxInputPropType.isRequired,
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  onlySuggestedValuesAllowed: PropTypes.bool,
  meta: reduxMetaPropType.isRequired,
};

ReduxFormAutoSuggestInput.defaultProps = {
  type: 'text',
  label: '',
  suggestions: [],
  onlySuggestedValuesAllowed: false,
};

ReduxFormAutoSuggestInput.displayName = 'ReduxFormAutoSuggestInput';

export default connect()(ReduxFormAutoSuggestInput);
