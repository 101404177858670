import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import moment from 'moment';
import { Provider } from 'react-redux';
import DatePicker from '@style-guide/components/DatePicker';
import Button from '@style-guide/components/Button';
import Select from '@style-guide/components/Select';
import LinkButton from '@style-guide/components/LinkButton';
import {
  eBarCaseCreate,
  eBarCaseUpdate,
  eBarCaseDelete,
} from '@services/EBarCase';
import LoadingOverlay from '@components/LoadingOverlay';
import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import Store from '@redux';
import { exportOptions } from '@config';
import PopUpModal from './components/PopUpModal/PopUpModal';
import DatePickerWrapper from './components/DatePickerWrapper';
import ButtonWrapper from './components/ButtonWrapper';
import SearchDiv from './components/SearchDiv';
import HeaderDiv from './components/HeaderDiv';
import CaseResultDiv from './components/CaseResultDiv';
import BlockWrapper from './components/BlockWrapper';
import Title from './components/Title';
import InnerDiv from './components/InnerDiv';
import Wrapper from './components/Wrapper';
import { CaseTable, ColumnHeaders } from './components/CaseTable';
import CaseResult from './components/CaseResult/CaseResult';
import CaseForm from './components/CaseForm/CaseForm';
import ModalBody from './components/ModalBody';
import MessageModalBody from './components/MessageModalBody';
import ErrorSpan from './components/ErrorSpan';

class EBarAdminCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      casesState: this.props.cases,
      create: false,
      edit: false,
      viewId: '',
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
      startDateState: moment(this.props.startDate),
      endDateState: moment(this.props.endDate),
      selectedExportOption: '',
      searchErrorMessage: '',
    };

    this.store = Store.get();

    this.handleExportDropdownChange = this.handleExportDropdownChange.bind(this);
    this.validateSearchParameters = this.validateSearchParameters.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onClickViewButton = this.onClickViewButton.bind(this);
    this.onClickEditButton = this.onClickEditButton.bind(this);
    this.onClickCloseCancel = this.onClickCloseCancel.bind(this);
    this.onClickCreateLink = this.onClickCreateLink.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.getAreaOfLaw = this.getAreaOfLaw.bind(this);
    this.getCourt = this.getCourt.bind(this);
  }

  onClickSearch() {
    // Validate search parameters
    if (!this.validateSearchParameters()) {
      return;
    }
    this.setState({
      loading: true,
      showMessagePopUp: false,
      messagePopUpTitle: '',
      searchErrorMessage: '',
    });
    const formattedStartDate = this.state.startDateState.format('MM/DD/YYYY');
    const formattedEndDate = this.state.endDateState.format('MM/DD/YYYY');
    fetch(`/EBarAdminCasePage/Search?startDate=${formattedStartDate}&endDate=${formattedEndDate}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then((data) => {
        this.setState({
          viewId: '',
          create: false,
          edit: false,
          casesState: data,
          formValues: undefined,
          loading: false,
          showPopUp: false,
          deleteGuid: '',
          showMessagePopUp: false,
          messagePopUpTitle: '',
          searchErrorMessage: '',
        });
      });
  }

  onChangeStartDate(value) {
    this.setState({
      startDateState: value,
    });
  }

  onChangeEndDate(value) {
    this.setState({
      endDateState: value,
    });
  }

  onClickViewButton(viewContentId) {
    this.setState({
      viewId: viewContentId,
      create: false,
      edit: false,
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onClickCloseCancel() {
    this.setState({
      viewId: '',
      create: false,
      edit: false,
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onClickEditButton(viewContentId) {
    this.setState({
      viewId: '',
      create: false,
      edit: true,
      showPopUp: false,
      showMessagePopUp: false,
      deleteGuid: '',
      messagePopUpTitle: '',
      formValues: (is.not.null(this.state.casesState) && is.not.undefined(this.state.casesState)
      && this.state.casesState.length > 0) ?
        this.state.casesState.filter(item => item.contentGuidString === viewContentId)[0] :
        undefined,
    });
  }

  onClickCreateLink() {
    this.setState({
      viewId: '',
      edit: false,
      formValues: undefined,
      create: true,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onSubmit(data) {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    this.setState({
      viewId: '',
      loading: true,
    });
    const finalPostData = this.functionReplace({ ...data });
    if (this.state.edit) {
      eBarCaseUpdate(finalPostData)
        .then(() => {
          this.setState({
            viewId: '',
            create: false,
            edit: false,
            formValues: undefined,
            showPopUp: false,
            deleteGuid: '',
            showMessagePopUp: true,
            messagePopUpTitle: 'Case has been updated successfully',
            loading: false,
          });
        })
        .catch(() => {
          this.setState({
            viewId: '',
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Update case failed',
          });
        });
    } else if (this.state.create) {
      eBarCaseCreate(finalPostData)
        .then(() => {
          setTimeout(() => {
            this.setState({
              viewId: '',
              create: false,
              edit: false,
              formValues: undefined,
              startDateState: moment(this.props.startDate),
              endDateState: moment(this.props.endDate),
              showPopUp: false,
              deleteGuid: '',
              showMessagePopUp: true,
              messagePopUpTitle: 'Case has been created successfully',
              loading: false,
            });
          }, 5000);
        })
        .catch(() => {
          this.setState({
            viewId: '',
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Create case failed',
          });
        });
    }
  }

  onConfirmDelete(value) {
    this.setState({
      viewId: '',
      showPopUp: false,
      loading: true,
    });

    eBarCaseDelete(value)
      .then(() => {
        this.setState({
          viewId: '',
          create: false,
          edit: false,
          formValues: undefined,
          showPopUp: false,
          deleteGuid: '',
          showMessagePopUp: true,
          messagePopUpTitle: 'Case has been deleted successfully',
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          viewId: '',
          loading: false,
          showPopUp: false,
          deleteGuid: '',
          showMessagePopUp: true,
          messagePopUpTitle: 'Delete Case Failed',
        });
      });
  }

  onClickDelete(value) {
    this.setState({
      create: false,
      edit: false,
      viewId: '',
      formValues: undefined,
      showPopUp: true,
      deleteGuid: value,
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onDeleteCancel() {
    this.setState({
      create: false,
      edit: false,
      viewId: '',
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  getAreaOfLaw(value) {
    const result = this.props.areasOfLaw.filter(x => x.id === value)[0].name;
    return result;
  }

  getCourt(value) {
    const result = this.props.courts.filter(x => x.id === value)[0].name;
    return result;
  }

  handleExportDropdownChange(event) {
    // get the node of the selected option
    const optionNode = event.target.options[event.target.selectedIndex];

    // the filterName is the text of the option element
    // and the value is the value that comes across
    this.setState({
      selectedExportOption: optionNode.value,
    });
  }

  // To Validate search Parameters
  validateSearchParameters() {
    // Check if EndDate (To) is greater than or equal to StartDate (From)
    if (this.state.endDateState.diff(this.state.startDateState, 'days') < 0) {
      this.setState({
        searchErrorMessage: '(To) Date should be greater than or equal to (From) Date',
      });
      return false;
    }
    // Check if Difference between EndDate (To) and StartDate (From) is greater than 15 days.
    if (this.state.endDateState.diff(this.state.startDateState, 'days') > 15) {
      this.setState({
        searchErrorMessage: 'Difference between (To) Date and (From) Date should not be greater than 15 days',
      });
      return false;
    }
    return true;
  }

/* eslint-disable */
  functionReplace(dataObject) {
    dataObject.summary = this.ReplaceAngleBrackets(dataObject.summary);
    return dataObject;
  }

  ReplaceAngleBrackets(summary) {
    if(!summary) {
      return summary;
    }
    summary = summary.replaceAll('<', '&lt;');
    summary = summary.replaceAll('>', '&gt;');
    return summary;
  }

  convertReplaceString(summaryString) {
    if(!summaryString || !summaryString.summary) {
      return summaryString;
    }
    summaryString.summary = summaryString.summary.replaceAll('&lt;', '<');
    summaryString.summary = summaryString.summary.replaceAll('&gt;', '>');
    return summaryString;
  }
  /* eslint-enable */


  render() {
    const {
      loading,
      casesState,
      formValues,
      edit,
      viewId,
      startDateState,
      endDateState,
      create,
      showPopUp,
      deleteGuid,
      showMessagePopUp,
      messagePopUpTitle,
      selectedExportOption,
      searchErrorMessage,
    } = this.state;

    const {
      areasOfLaw,
      courts,
    } = this.props;

    return (
      <BlockWrapper>
        {loading && <LoadingOverlay />}
        {showPopUp && deleteGuid && !showMessagePopUp && (
          <PopUpModal
            dismissable={false}
            body={(
              <ModalBody
                yesFunction={this.onConfirmDelete}
                noFunction={this.onDeleteCancel}
                deleteGuidString={deleteGuid}
              />
            )}
            closeAction={this.onDeleteCancel}
          />
        )}
        {showMessagePopUp && messagePopUpTitle && !showPopUp && (
          <PopUpModal
            dismissable={false}
            body={(
              <MessageModalBody
                title={messagePopUpTitle}
                okFunction={this.onClickSearch}
              />
            )}
            closeAction={this.onClickSearch}
          />
        )}
        <Wrapper>
          <YellowLineSVG />
          <HeaderDiv>
            <Title>Manage Cases</Title>
            <InnerDiv>
              <Select
                id="exportOptions"
                name="exportOptions"
                label="Export Type"
                placeholder="Select Export option"
                onChange={this.handleExportDropdownChange}
                options={exportOptions}
              />
              <LinkButton
                disabled={!selectedExportOption}
                href={selectedExportOption ? `/EBarAdminCasePage/ExportCases?id=${selectedExportOption}&startDate=${startDateState.format('MM/DD/YYYY')}&endDate=${endDateState.format('MM/DD/YYYY')}` : '#'}
                id="exportCasesButton"
                target={selectedExportOption ? '_blank' : ''}
                rel={selectedExportOption ? 'noopener noreferrer' : ''}
              >
                EXPORT
              </LinkButton>
              <Button
                onClick={() => this.onClickCreateLink()}
                disabled={create}
              >
                Create
              </Button>
            </InnerDiv>
          </HeaderDiv>
          {(create || edit) && (
          <Provider store={this.store}>
            <CaseForm
              initialValues={formValues}
              areasOfLaw={areasOfLaw}
              courts={courts}
              onSubmit={this.onSubmit}
              onClickCloseCancel={this.onClickCloseCancel}
              edit={edit}
            />
          </Provider>
          )}
          <SearchDiv>
            <DatePickerWrapper>
              <DatePicker
                id="FromDate"
                maxDate={moment().add(31, 'days')}
                onSelect={value => this.onChangeStartDate(value)}
                scrollableYearDropdown
                selected={startDateState}
                showYearDropdown
                yearDropdownItemNumber={50}
                placeholderText="mm/dd/yyyy"
                label="From"
              />
            </DatePickerWrapper>
            <DatePickerWrapper>
              <DatePicker
                id="ToDate"
                maxDate={moment().add(31, 'days')}
                onSelect={value => this.onChangeEndDate(value)}
                scrollableYearDropdown
                selected={endDateState}
                showYearDropdown
                yearDropdownItemNumber={50}
                placeholderText="mm/dd/yyyy"
                label="To"
              />
            </DatePickerWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => this.onClickSearch()}
              >
                Search
              </Button>
            </ButtonWrapper>
            <ErrorSpan>{searchErrorMessage}</ErrorSpan>
          </SearchDiv>
          {is.not.null(casesState) && casesState.length > 0 && (
            <CaseResultDiv>
              <CaseTable>
                <thead>
                  <ColumnHeaders>
                    <th><h3>TITLE</h3></th>
                    <th><h3>CASE NAME</h3></th>
                    <th><h3>PRACTICE AREA</h3></th>
                    <th><h3>AUTHOR</h3></th>
                    <th><h3>ACTIVE DATE</h3></th>
                    <th><h3>ACTIONS</h3></th>
                  </ColumnHeaders>
                </thead>
                <tbody>
                  {casesState.map(c => (
                    <CaseResult
                      key={c.contentGuidString}
                      initialValues={this.convertReplaceString(c)}
                      viewId={viewId}
                      onClickViewButton={this.onClickViewButton}
                      onClickEditButton={this.onClickEditButton}
                      onClickCloseCancel={this.onClickCloseCancel}
                      onClickDelete={this.onClickDelete}
                      getAreaOfLaw={this.getAreaOfLaw}
                      getCourt={this.getCourt}
                    />
                  ))}
                </tbody>
              </CaseTable>
            </CaseResultDiv>
          )}
        </Wrapper>
      </BlockWrapper>
    );
  }
}

EBarAdminCase.propTypes = {
  cases: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    caseName: PropTypes.string,
    webCitation: PropTypes.string,
    casemakerLink: PropTypes.string,
    pDFLink: PropTypes.string,
    dateDecidedString: PropTypes.string,
    activeDateString: PropTypes.string,
    dateAddedString: PropTypes.string,
    authoredBy: PropTypes.string,
    practiceId: PropTypes.number,
    courtId: PropTypes.number,
  })).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  areasOfLaw: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  courts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
};

EBarAdminCase.defaultProps = {
};

EBarAdminCase.displayName = 'EBarAdminCase';

export default EBarAdminCase;
