import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { colors, typography } from '@style-guide/config';
import ErrorMessage from '@components/ErrorMessage';
import ResourceBookmark from './Shared/ResourceBookmark/ResourceBookmark';

const ListItem = styled.li`
  font-size: ${rem('16px', typography.baseFontSize)};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  transition: opacity .3s linear;
  transition: margin-bottom .3s linear;
  position: relative;

  &.fadeOut {
    opacity: 0;
    height: 0;
    margin-bottom: 0px;
  }
`;

const ListItemTitle = styled.a`
  text-decoration: underline;
  color: ${colors.black};
  display: inline-block;
  padding-right: 20px;

  &:hover {
    color: ${colors.red};
  }
`;

const CopyWrapper = styled.p`
  font-size: 1rem;
`;

CopyWrapper.displayName = 'CopyWrapper';

const Wrapper = styled.p`
  font-size: 1rem;
  display: flex;
`;

Wrapper.displayName = 'Wrapper';

const CaseTitle = styled.span`
  font-weight: bold;
`;

CaseTitle.displayName = 'CaseTitle';

const PracticeArea = styled.span`
  display: block;
  color: ${colors.darkRed};
  font-size: ${rem('14px', typography.baseFontSize)};
  font-style: italic;
  font-weight: 600;
`;

PracticeArea.displayName = 'PracticeArea';

const PDFLink = styled.span`
  text-decoration: underline;
`;

PDFLink.displayName = 'PDFLink';

class GreenbookListItem extends React.Component {
  constructor(props) {
    super(props);

    this.errorMessageRef = React.createRef();
    this.listItemRef = React.createRef();
    this.convertReplaceString = this.convertReplaceString.bind(this);
  }

  /* eslint-disable */
  convertReplaceString(summaryString) {
    if (!summaryString) {
      return summaryString;
    }
    summaryString = summaryString.replaceAll('&lt;', '<');
    summaryString = summaryString.replaceAll('&gt;', '>');

    return summaryString;
  };
  /* eslint-enable */


  render() {
    return (
      <ListItem innerRef={this.listItemRef} key={this.props.guid}>
        <CopyWrapper>
          <PracticeArea>{this.props.practiceName}</PracticeArea>
        </CopyWrapper>
        <CopyWrapper>
          <CaseTitle>{this.props.title}</CaseTitle>
        </CopyWrapper>
        <CopyWrapper>
          {(this.props.caseName != null) && (
          <span>{this.props.caseName} | </span>
          )}
          <span>{this.props.cite} | </span>
          {(this.props.courtName != null) && (
          <span>{this.props.courtName} | </span>
          )}
          {this.props.dateType != null && this.props.dateType !== '' && this.props.date != null && (
          <span>{this.props.dateType}: </span>
          )}
          <span>{this.props.date != null && this.props.date !== '' ? moment(this.props.date).format('LL') : ''}</span>
          <br />
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: this.convertReplaceString(this.props.summary) }} />
        </CopyWrapper>
        <Wrapper>
          {this.props.ctaLabel != null && (
          <PDFLink>
            <a
              href={this.props.ctaUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              &gt; {this.props.ctaLabel}
            </a>
          </PDFLink>
          )}
          <ResourceBookmark
            contentGuidString={this.props.guid}
            type={this.props.contentType}
            isBookmarked={this.props.isBookmarked}
          />
        </Wrapper>
        {(this.props.ctaLabel == null) && (
          <br />
        )}
      </ListItem>
    );
  }
}

GreenbookListItem.propTypes = {
  guid: PropTypes.string,
  title: PropTypes.string,
  cite: PropTypes.string,
  caseName: PropTypes.string,
  dateType: PropTypes.string,
  date: PropTypes.string,
  summary: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  isBookmarked: PropTypes.bool,
  contentType: PropTypes.number,
  practiceName: PropTypes.string,
  courtName: PropTypes.string,
};

GreenbookListItem.defaultProps = {
  guid: '',
  title: '',
  cite: '',
  caseName: '',
  dateType: '',
  date: '',
  summary: '',
  ctaLabel: '',
  ctaUrl: '',
  isBookmarked: false,
  contentType: '',
  practiceName: '',
  courtName: '',
};

ListItem.displayName = 'ListItem';
ListItemTitle.displayName = 'ListItemTitle';
ErrorMessage.displayName = 'ErrorMessage';

export default GreenbookListItem;
