import React from 'react';
import PropTypes from 'prop-types';

import Select from '@style-guide/components/Select';

import {
  reduxInputPropType,
  reduxMetaPropType,
} from '@services/PropTypes';

import FieldValidationError from '@components/FieldValidationError';

const ReduxFormSelect = ({
  input,
  label,
  meta: {
    touched,
    error,
  },
  ...restProps
}) => (
  <div>
    <Select
      {...input}
      label={label}
      invalid={!!(touched && error)}
      {...restProps}
    />
    {touched && error && (
      <FieldValidationError>{error}</FieldValidationError>
    )}
  </div>
);

ReduxFormSelect.propTypes = {
  input: reduxInputPropType.isRequired,
  label: PropTypes.string,
  meta: reduxMetaPropType.isRequired,
};

ReduxFormSelect.defaultProps = {
  label: '',
};

ReduxFormSelect.displayName = 'ReduxFormSelect';

export default ReduxFormSelect;
