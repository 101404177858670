import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { breakpoints, colors, typography } from '@style-guide/config';

import LinkButton from '@style-guide/components/LinkButton';

const COLOR_MAP = {
  red: colors.darkRed,
  blue: colors.darkBlue,
};

const HEADING_COLOR_MAP = {
  yellow: colors.yellow,
  blue: colors.blue,
};

const CarouselCTAWrapper = styled.div`
  &:not(.is-selected) {
    opacity: 0;
    transition: opacity 500ms;
  }
`;

CarouselCTAWrapper.displayName = 'CarouselCTAWrapper';

const CarouselCell = styled.div`
  align-items: ${props => (props.alignment === 'top-aligned' ? 'flex-start' : 'center')};
  background-color: ${props => COLOR_MAP[props.color]};
  display: flex;
  justify-content: flex-start;
  min-height: 100%;
  width: 100vw;

  img {
    width: 100%;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: relative;
  }

  @media (min-width: ${breakpoints.s}) {
    height: 260px;
    width: 360px;

    img {
      width: 100%;
    }
  }
`;

CarouselCell.displayName = 'CarouselCell';

const CarouselCTAContainer = styled.div`
  color: ${colors.white};
  position: absolute;
  padding: 30px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    left: 0px;
    top: 0px;
  }
`;

CarouselCTAContainer.displayName = 'CarouselCTAContainer';

const CTAHeading = styled.div`
  color: ${props => HEADING_COLOR_MAP[props.color]};
  font-size: ${rem('30px', typography.baseFontSize)};
  font-weight: 600;
  padding: 20px 0 0;
`;

CTAHeading.displayName = 'CTAHeading';

const CTASubHeading = styled.div`
  font-size: ${rem('30px', typography.baseFontSize)};
  font-weight: 100;
  margin-bottom: 20px;
`;

CTASubHeading.displayName = 'CTASubHeading';

const CarouselCTA = ({
  componentColor,
  alignment,
  headingColor,
  buttonColor,
  image,
  heading,
  subHeading,
  buttonText,
  buttonLink,
}) => (
  <CarouselCTAWrapper>
    <CarouselCell
      color={componentColor}
      alignment={alignment}
    >
      <img src={image} alt="" />
      <CarouselCTAContainer>
        <CTAHeading
          color={headingColor}
        >
          {heading}
        </CTAHeading>
        <CTASubHeading
          dangerouslySetInnerHTML={{
            __html: subHeading,
          }}
        />
        <LinkButton
          variant={buttonColor}
          href={buttonLink}
        >
          {buttonText}
        </LinkButton>
      </CarouselCTAContainer>
    </CarouselCell>
  </CarouselCTAWrapper>
);

CarouselCTA.displayName = 'CarouselCTA';

CarouselCTA.propTypes = {
  componentColor: PropTypes.oneOf([
    'red',
    'blue',
    'none',
  ]),
  alignment: PropTypes.oneOf([
    'top-aligned',
    'none',
  ]),
  headingColor: PropTypes.oneOf([
    'yellow',
    'blue',
    'none',
  ]),
  buttonColor: PropTypes.oneOf([
    'primary',
    'secondary',
  ]),
  image: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

CarouselCTA.defaultProps = {
  componentColor: 'red',
  alignment: 'none',
  image: '',
  heading: '',
  subHeading: '',
  headingColor: 'none',
  buttonColor: 'primary',
};

export default CarouselCTA;
