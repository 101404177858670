import React from 'react';
import PropTypes from 'prop-types';
import styled, { injectGlobal } from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

injectGlobal`
  [data-react-component="FooterLogo"] {
    width: 100%;

    @media (min-width: ${breakpoints.l}) {
      width: auto;
    }
  }
`;

const LogoContainer = styled.div`
  align-items: center;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    display: block;
    margin: 25px 0;
  }

  @media (min-width: ${breakpoints.l}) {
    height: 100%;
    min-height: 300px;
  }
`;

LogoContainer.displayName = 'LogoContainer';

const FooterLogo = ({ logoSrc }) => (
  <LogoContainer>
    <a
      href="/?nr"
      id="footerLogo"
    >
      <img src={logoSrc} alt="OSBA logo" />
    </a>
  </LogoContainer>
);

FooterLogo.propTypes = {
  logoSrc: PropTypes.string.isRequired,
};

FooterLogo.displayName = 'FooterLogo';

export default FooterLogo;
