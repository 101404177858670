import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import styled from 'styled-components';
import chunk from 'lodash/chunk';

import { breakpoints } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import { reduxInputPropType } from '@services/PropTypes';

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px; 
  position: relative;

  img {
    max-height: 150px;
    max-width: 100%;
    transition: border 0.5s ease-all;
    margin-bottom: 60px;
  }

  @media(min-width: ${breakpoints.l}) {
    margin: ${props => (props.hasMarginLarge ? '0px 15px 30px 15px' : '0px 0px 30px 0px')};

  }

  @media(min-width: ${breakpoints.xl}) {
    width: calc(33% - 15px);
    max-height: 250px;
    margin: ${props => (props.hasMarginXLarge ? '0px 15px 30px 15px' : '0px 0px 30px 0px')};
  }
`;

ItemWrapper.displayName = 'ItemWrapper';

const Wrapper = styled.div`
  padding: 15px 0px;
  width: 100%;
`;

Wrapper.displayName = 'Wrapper';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

Row.displayName = 'Row';

const StyledButton = styled(Button)`
  margin: 0 !important;
  align-self: flex-end;
  position: absolute;
  bottom: 0px;
  right: ${props => (props.isSelected ? 'calc(50% - 61px)' : 'calc(50% - 52px)')};
`;

const CoverPhotoSection = ({
  input,
  coverImageOptions,
}) => {
  const groupedOptions = is.not.empty(coverImageOptions)
    ?
    chunk(coverImageOptions, 3)
    :
    [];
  const groupedOptionsWithIds = is.not.empty(groupedOptions)
    ?
    groupedOptions.map((group, index) => {
      const itemsWithIds = group.map((item, itemIndex) => ({
        id: itemIndex,
        ...item,
      }));

      return ({
        id: index,
        items: [
          ...itemsWithIds,
        ],
      });
    })
    :
    [];

  return (
    <Wrapper>
      {is.not.empty(groupedOptionsWithIds) && groupedOptionsWithIds.map(group => (
        <Row
          key={group.id}
        >
          {group.items.map((option, index) => (
            <ItemWrapper
              key={option.id}
              hasMarginL={index === 0 || index === 2}
              hasMarginXLarge={index === 1}
            >
              <img
                src={option.value}
                alt="cover"
              />
              <StyledButton
                variant={input.value.url === option.value ? 'primary' : 'ghost'}
                isSelected={input.value.url === option.value}
                onClick={(e) => {
                  e.preventDefault();
                  input.onChange({ url: option.value });
                }}
              >
                {input.value.url === option.value ? 'SELECTED' : 'SELECT'}
              </StyledButton>
            </ItemWrapper>
          ))}
        </Row>
      ))}
    </Wrapper>
  );
};

CoverPhotoSection.propTypes = {
  coverImageOptions: PropTypes.arrayOf(PropTypes.object),
  input: reduxInputPropType.isRequired,
};

CoverPhotoSection.defaultProps = {
  coverImageOptions: [],
};

CoverPhotoSection.displayName = 'CoverPhotoSection';

export default CoverPhotoSection;
