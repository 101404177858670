import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '@style-guide/components/Button';

import { required } from '@services/FormValidators';

import ErrorMessage from '@components/ErrorMessage';
import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormAutoSuggestInput from '@components/ReduxFormAutoSuggestInput';
import ReduxFormSelect from '@components/ReduxFormSelect';

const LawSchoolInfoForm = ({
  handleSubmit,
  hasSaveError,
  invalid,
  lawSchools,
  loading,
  pristine,
  warning,
  years,
}) => (
  <form
    id="lawSchoolInfoForm"
    onSubmit={handleSubmit}
  >
    <FormFieldWrapper>
      <Field
        component={ReduxFormAutoSuggestInput}
        id="lawSchoolName"
        label="Law School Name*"
        name="LawSchoolName"
        onlySuggestedValuesAllowed
        suggestions={lawSchools}
        validate={required}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormSelect}
        id="graduationYear"
        label="Graduation Year"
        name="GraduationYear"
        options={years.map(year => ({
          label: year,
          value: year,
        }))}
      />
    </FormFieldWrapper>
    {warning && (
      <FormFieldWrapper>
        <div><ErrorMessage warn>{warning}</ErrorMessage></div>
      </FormFieldWrapper>
    )}
    <Button
      disabled={(pristine || loading || invalid) && !hasSaveError}
      type="submit"
    >
      Save
    </Button>
  </form>
);

LawSchoolInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hasSaveError: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  lawSchools: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  warning: PropTypes.string,
  years: PropTypes.arrayOf(PropTypes.number).isRequired,
};

LawSchoolInfoForm.defaultProps = {
  hasSaveError: false,
  warning: '',
};

LawSchoolInfoForm.displayName = 'LawSchoolInfoForm';

const warn = ({
  LawSchoolName,
}, props) => {
  const warnings = {};

  if (!props.loading && (!LawSchoolName)) {
    /* this is part of redux-form API */
    /* eslint-disable no-underscore-dangle */
    warnings._warning = 'Missing required fields';
    props.setWarning(true);
  } else {
    warnings._warning = '';
    props.setWarning(false);
  }

  return warnings;
};

export default reduxForm({
  enableReinitialize: true,
  form: 'lawSchoolInfoForm',
  warn,
})(LawSchoolInfoForm);
