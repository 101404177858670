import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  facebookUrl,
  instagramUrl,
  twitterUrl,
  linkedinUrl,
} from '@services/FormValidators';
import Button from '@style-guide/components/Button';

import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormInput from '@components/ReduxFormInput';

const SocialMediaInfoForm = ({
  handleSubmit,
  loading,
  pristine,
  hasSaveError,
  invalid,
}) => (
  <form
    id="socialMediaInfoForm"
    onSubmit={handleSubmit}
  >
    <FormFieldWrapper>
      <Field
        id="twitterUrl"
        label="Twitter"
        name="TwitterUrl"
        component={ReduxFormInput}
        validate={twitterUrl}
        placeholder="twitter.com/OSBA"
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        id="linkedInUrl"
        label="LinkedIn"
        name="LinkedInUrl"
        component={ReduxFormInput}
        validate={linkedinUrl}
        placeholder="linkedin.com/company/ohio-state-bar-association"
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        id="instagramUrl"
        label="Instagram"
        name="InstagramUrl"
        component={ReduxFormInput}
        validate={instagramUrl}
        placeholder="instagram.com/ohiobar"
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        id="facebookUrl"
        label="Facebook"
        name="FacebookUrl"
        component={ReduxFormInput}
        validate={facebookUrl}
        placeholder="facebook.com/OhioBar"
      />
    </FormFieldWrapper>
    <Button
      type="submit"
      disabled={(pristine || loading || invalid) && !hasSaveError}
    >
      Save
    </Button>
  </form>
);

SocialMediaInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
};

SocialMediaInfoForm.defaultProps = {
  hasSaveError: false,
};

SocialMediaInfoForm.displayName = 'SocialMediaInfoForm';

export default reduxForm({
  form: 'socialMediaInfoForm',
  enableReinitialize: true,
})(SocialMediaInfoForm);
