import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';

import Button from '@style-guide/components/Button';

import FormFieldSection from '@components/FormFieldSection';

import InvolvementsList from './components/InvolvementsList';

const AffiliationsInfoForm = ({
  handleSubmit,
  involvementTypes,
  loading,
  invalid,
  pristine,
  hasSaveError,
}) => (
  <form
    id="AffiliationsInfoForm"
    onSubmit={handleSubmit}
  >
    <FormFieldSection
      heading="Professional Involvement"
    >
      <FieldArray
        name="Involvement"
        involvementTypes={involvementTypes}
        component={InvolvementsList}
        rerenderOnEveryChange
      />
    </FormFieldSection>
    <Button
      type="submit"
      disabled={((pristine || loading) && !hasSaveError) || invalid}
    >
      Save
    </Button>
  </form>
);

AffiliationsInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  involvementTypes: PropTypes.arrayOf(PropTypes.string),
  invalid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
};

AffiliationsInfoForm.defaultProps = {
  hasSaveError: false,
  involvementTypes: [],
};

AffiliationsInfoForm.displayName = 'AffiliationsInfoForm';

export default reduxForm({
  form: 'affiliationsInfoForm',
  enableReinitialize: true,
})(AffiliationsInfoForm);
