import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';
import { colors } from '@style-guide/config';
import NavItem from './components/NavItem';
import Search from './components/Search';

const Logo = styled.img`
  display: table;
`;

Logo.displayName = 'Logo';

const NavMenu = styled.ul`
  background-color: ${transparentize(0.1, colors.darkBlue)};
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`;

NavMenu.displayName = 'NavMenu';

const NavContainer = styled.nav`
  display: flex;
  align-items: flex-end;
`;

NavContainer.displayName = 'NavContainer';

const MainNav = ({
  navItems,
  headerLogo,
}) => {
  const navItemsWithIDs = navItems.map((link, index) => ({
    id: index,
    ...link,
  }));

  const primaryNavigationItems = navItemsWithIDs.map(navItem => (
    <NavItem
      key={navItem.id}
      description={navItem.description}
      href={navItem.href}
      links={navItem.links}
      text={navItem.text}
      title={navItem.title}
      itemIndex={navItem.id}
    />
  ));

  return (
    <NavContainer aria-label="Main Navigation">
      <a
        href="/?nr"
        id="desktopHeaderLogo"
      >
        <Logo src={headerLogo} alt="OSBA Logo" />
      </a>
      <NavMenu>
        {primaryNavigationItems}
        <Search />
      </NavMenu>
    </NavContainer>
  );
};

MainNav.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
  })),
  headerLogo: PropTypes.string.isRequired,
};

MainNav.defaultProps = {
  navItems: [],
};

MainNav.displayName = 'MainNav';

export default MainNav;
