import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { injectGlobal } from 'styled-components';

import { colors } from '@style-guide/config';
import Store from '@redux';

import ShoppingCartContent from './components/ShoppingCartContent';

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    injectGlobal`
      body {
        background-color: ${colors.lightGray};
      }
    `;
  }

  render() {
    const { checkoutLink } = this.props;

    return (
      <Provider store={this.store}>
        <ShoppingCartContent
          checkoutLink={checkoutLink}
        />
      </Provider>
    );
  }
}

ShoppingCart.propTypes = {
  checkoutLink: PropTypes.string.isRequired,
};

ShoppingCart.displayName = 'ShoppingCart';

export default ShoppingCart;
