import React from 'react';
import { connect } from 'react-redux';

import ReduxFormRadioButton from '@components/ReduxFormRadioButton';

/* eslint-disable */
const PreferredEmailStateContainer = ({
  contactInfoFormState,
  input,
  label,
  meta,
  ...restProps
}) => (
  <div>
    <ReduxFormRadioButton
      disabled={!contactInfoFormState.values[restProps.emailField]}
      input={input}
      label={label}
      meta={meta}
      {...restProps}
    />
  </div>
);
/* eslint-enable */

PreferredEmailStateContainer.displayName = 'PreferredEmailStateContainer';

export default connect(state => ({
  contactInfoFormState: state.form.contactInfoForm,
}))(PreferredEmailStateContainer);
