import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import { colors } from '@style-guide/config';

const DividerContainer = styled.div`
  border-bottom: 1px solid ${colors.gray};
  margin: ${props => (props.margin ? props.margin : '10px 0')};
  width: ${props => (props.width ? props.width : 'auto')};

  svg {
    display: block;
    margin-bottom: -1px;
  }
`;

DividerContainer.displayName = 'DividerContainer';

const Divider = ({
  margin,
  width,
  hasDecorator,
}) => (
  <DividerContainer
    margin={margin}
    width={width}
  >
    {hasDecorator && <YellowLineSVG />}
  </DividerContainer>
);

Divider.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.string,
  hasDecorator: PropTypes.bool,
};

Divider.defaultProps = {
  margin: undefined,
  width: undefined,
  hasDecorator: true,
};

Divider.displayName = 'Divider';

export default Divider;
