import React from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';
import FlickityStyles from 'flickity/css/flickity.css';
import styled from 'styled-components';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import hideVisually from 'polished/lib/mixins/hideVisually';

import { breakpoints, colors } from '@style-guide/config';
import OhioLogoSVG from '@style-guide/svg/OhioLogoSVG';

import CarouselCTA from './components/CarouselCTA';

const horizontalMargin = '50px';

const CarouselContainer = styled.div`
  ${FlickityStyles}

  margin: 20px 0 50px;
  width: 100%;

  .flickity-slider {
    display: flex;
  }

  .flickity-page-dots {
    bottom: -30px;

    .dot {
      background: ${colors.gray};
      opacity: 1;

      &.is-selected {
        background-color: ${colors.darkRed};
      }
    }

    @media (min-width: ${breakpoints.s}) {
      bottom: -40px;
    }
  }

  .flickity-prev-next-button {
    background: none;
    z-index: 1;

    &:hover {
      background: none;
    }

    .arrow {
      fill: ${colors.darkRed};
    }

    @media (max-width: 479px) {
      top: calc(100% + 22px);

      &:disabled {
        opacity: 0;
      }
    }

    @media (min-width: ${breakpoints.s}) {
      svg {
        transform: scale(1.5);
      }

      &.next {
        right: -50px;
      }

      &.previous {
        left: -50px;
      }
    }
  }

  @media (min-width: ${breakpoints.s}) {
    margin: 30px ${horizontalMargin} 50px;
    width: calc(100% - (${horizontalMargin}*2));
  }
`;

CarouselContainer.displayName = 'CarouselContainer';

const CarouselContentSection = styled.div`
  align-items: center;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 60px;

  > svg {
    width: 70px;
    margin-bottom: 10px;
    top: -35px;
  }

  @media (min-width: ${breakpoints.s}) {
    > svg {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    height: 560px;
    padding: 0 50px 30px;
  }
`;

CarouselContentSection.displayName = 'CarouselContentSection';

const AccessibilityHeader = styled.h2`
  ${hideVisually()}
`;

AccessibilityHeader.displayName = 'AccessibilityHeader';

const Carousel = ({
  variant,
  items,
  ...restProps
}) => {
  const defaults = {
    dynamic: {
      arrowShape: 'M 10,50 L 10,50 L 70,85 L 70,50  L 70,15 L 10,50 Z',
      groupCells: true,
    },
    simple: {
      arrowShape: 'M 10,50 L 10,50 L 70,85 L 70,50  L 70,15 L 10,50 Z',
      percentPosition: false,
    },
  };

  const flickityOptions = {
    ...defaults[variant],
    ...restProps,
  };

  const carouselCells = JSON.parse(items).map((blockData) => {
    const props = mapKeys(blockData.Block, (value, key) => camelCase(key));

    return (
      <CarouselCTA
        key={blockData.Key}
        {...props}
      />
    );
  });

  return (
    <CarouselContentSection>
      <AccessibilityHeader>
        Additional resources carousel with {carouselCells.length} slides
      </AccessibilityHeader>
      <OhioLogoSVG />
      <CarouselContainer>
        <Flickity
          options={flickityOptions}
          reloadOnUpdate
        >
          {carouselCells}
        </Flickity>
      </CarouselContainer>
    </CarouselContentSection>
  );
};

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  /** Which carousel variant to use. Can be `dynamic` or `simple` */
  variant: PropTypes.oneOf([
    'dynamic',
    'simple',
  ]),
  items: PropTypes.string,
};

Carousel.defaultProps = {
  variant: 'dynamic',
  items: '[]',
};

export default Carousel;
