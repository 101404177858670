import 'babel-polyfill';
import 'url-polyfill';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';

import {
  ArticleListCTA,
  CardCTA,
  ContentCard,
  GridContainerCTA,
  Hero,
  HomePageHero,
  IAmHero,
  IconDivider,
  LegislativeReportCTA,
  MapCTA,
  NewsCTA,
  SearchCTA,
  SubHubDescription,
} from '@style-guide/structures';

import {
  Advertisement,
  Button,
  EmbeddedFrame,
  GoogleAd,
  ImageGallery,
  PullQuote,
  RadioButton,
  TextArea,
  VideoPlayer,
} from '@style-guide/components';

import AccountOverviewCTASection from '@components/AccountOverviewCTASection';
import Alert from '@components/Alert';
import BecomeAMemberBlock from '@components/BecomeAMemberBlock';
import Carousel from '@components/Carousel';
import CTASection from '@components/CTASection';
import FindALawyerSearchBlock from '@components/FindALawyerSearchBlock';
import HubCTA from '@components/HubCTA';
import InlinePdf from '@components/InlinePdf';
import MarketingCTASection from '@components/MarketingCTASection';
import OverlayCTASection from '@components/OverlayCTASection';
import MyBookmarks from '@components/MyBookmarks';
import ResourcePreviewList from '@components/ResourcePreviewList';
import SpecializationActionBar from '@components/SpecializationActionBar';

import CareerCenter from '@structures/CareerCenter';
import Flyout from '@structures/Flyout';
import HubCTAContainer from '@structures/HubCTAContainer';
import LeftNav from '@structures/LeftNav';
import MarketingPreferencesInfo from '@structures/MarketingPreferencesInfo';
import MemberDirectorySearchBlock from '@structures/MemberDirectorySearchBlock';
import PaymentInformation from '@structures/PaymentInformation';
import PurchaseHistory from '@structures/PurchaseHistory';

import BecomeAMember from '@views/BecomeAMember';
import CalqResults from '@views/CalqResults';
import Checkout from '@views/Checkout';
import CLECertificatePage from '@views/CLECertificatePage';
import CLEClassroom from '@views/CLEClassroom';
import CLEDetail from '@views/CLEDetail';
import CLEResults from '@views/CLEResults';
import {
  Committees,
  Sections,
} from '@views/CommitteesAndSections';
import DirectoryProfile from '@views/DirectoryProfile';
import {
  AffiliationsInfo,
  EditDirectoryIntro,
  EditDirectoryNonMemberCTA,
  EducationInfo,
  HonorsInfo,
  LinksInfo,
  MediaInfo,
  OverviewInfo,
} from '@views/EditDirectory';
import EventClassroom from '@views/EventClassroom';
import EventDetail from '@views/EventDetail';
import EventSearchResultsAsync from '@views/EventSearchResults';
import FindALawyerResults from '@views/FindALawyerResults';
import {
  FooterLegalLinks,
  FooterLogo,
  FooterPrimaryLinks,
  FooterSecondaryLinks,
} from '@views/Footer';
import Header from '@views/Header';
import {
  DashboardHero,
  MemberDashboard,
} from '@views/MemberDashboard';
import MembershipPackages from '@views/MembershipPackages';
import {
  CompanyInfo,
  ContactInfo,
  DemographicInfo,
  ExperienceInfo,
  LawSchoolInfo,
  PersonalInfo,
  SocialMediaInfo,
} from '@views/MyAccount';
import MyCLE from '@views/MyCLE';
import MyEvents from '@views/MyEvents';
import PracticeAreas from '@views/PracticeAreas';
import PracticeLibraryResults from '@views/PracticeLibraryResults';
import PracticeLibrarySearch from '@views/PracticeLibrarySearch';
import {
  ResourceBody,
  ResourceHeading,
} from '@views/Resource';
import ShoppingCartBody from '@views/ShoppingCart';
import SiteSearchResults from '@views/SiteSearchResults';
import { EBarDashboardHero, EBarHome } from '@views/EBarHome';
import EBarAdminCase from '@views/EBarAdminCase';
import EBarNewsCTA from '@views/EBarNewsCTA';
import EBarEnactmentsCTA from '@views/EBarEnactmentsCTA';
import EBarAttorneysCTA from '@views/EBarAttorneysCTA';
import EBarResourcesCTA from '@views/EBarResourcesCTA';
import EBarSnippetBlock from '@views/EBarRightContentBlocks/EBarSnippetBlock';
import EBarFeatureBlock from '@views/EBarRightContentBlocks/EBarFeatureBlock';
import EBarContentResourcesListBlock from '@views/EBarRightContentBlocks/EBarContentResourcesListBlock';
import EBarBoardOfProfessionalsCTA from '@views/EBarBoardOfProfessionalsCTA';
import EBarSCOAnnouncementsCTA from '@views/EBarSCOAnnouncementsCTA';
import EBarOtherAnnouncementsCTA from '@views/EBarOtherAnnouncementsCTA';
import EBarEthicsCTA from '@views/EBarEthicsCTA';
import EBarMemberCenterCTA from '@views/EBarMemberCenterCTA';
import EBarManageEmailsCTA from '@views/EBarManageEmailsCTA';
import EBarJobsCTA from '@views/EBarJobsCTA';
import EBarSearchPage from '@views/EBarSearchPage';
import {
  EBarResourceBody,
  EBarResourceHeading,
} from '@views/EBarCase';
import EBarAdminHome from '@views/EBarAdminHome';
import EmailBlock from '@views/CommunicationPreferences/EmailBlock';
import TopicOfInterestBlock from '@views/CommunicationPreferences/TopicOfInterestBlock';
import MemberBenefitInformationBlock from '@views/CommunicationPreferences/MemberBenefitInformationBlock';
import ENewsLettersBlock from '@views/CommunicationPreferences/ENewsLettersBlock';
import CLEAdminHome from '@views/CLEAdminHome';
import CLEVideosCTA from '@views/CLEVideosCTA';
import CLEOnDemand from '@views/CLEOnDemand';

import Store from '@redux';

require('react-datepicker/dist/react-datepicker.css');

// initialize the store
Store.initialize();

// Dictionary of available component types to be able to get a class from its literal name
const ComponentTypes = {
  Advertisement,
  AffiliationsInfo,
  Alert,
  ArticleListCTA,
  BecomeAMember,
  BecomeAMemberBlock,
  Button,
  CalqResults,
  CardCTA,
  CareerCenter,
  Carousel,
  Checkout,
  CLEAdminHome,
  CLEVideosCTA,
  CLEOnDemand,
  CLECertificatePage,
  CLEClassroom,
  CLEDetail,
  CLEResults,
  Committees,
  CompanyInfo,
  ContactInfo,
  CTASection,
  ContentCard,
  DashboardHero,
  DemographicInfo,
  EBarDashboardHero,
  EBarHome,
  EBarAdminHome,
  EBarAdminCase,
  EBarResourceBody,
  EBarResourceHeading,
  EditDirectoryIntro,
  DirectoryProfile,
  EditDirectoryNonMemberCTA,
  EducationInfo,
  ExperienceInfo,
  EmbeddedFrame,
  EmailBlock,
  TopicOfInterestBlock,
  MemberBenefitInformationBlock,
  ENewsLettersBlock,
  EventSearchResultsAsync,
  EventDetail,
  EventClassroom,
  FindALawyerResults,
  FindALawyerSearchBlock,
  Flyout,
  FooterLegalLinks,
  FooterLogo,
  FooterPrimaryLinks,
  FooterSecondaryLinks,
  GoogleAd,
  GridContainerCTA,
  Header,
  Hero,
  HomePageHero,
  HonorsInfo,
  HubCTA,
  HubCTAContainer,
  IAmHero,
  IconDivider,
  ImageGallery,
  InlinePdf,
  LawSchoolInfo,
  LeftNav,
  LegislativeReportCTA,
  LinksInfo,
  MapCTA,
  MarketingCTASection,
  MarketingPreferencesInfo,
  MediaInfo,
  MemberDashboard,
  MemberDirectorySearchBlock,
  MembershipPackages,
  AccountOverviewCTASection,
  MyCLE,
  MyEvents,
  EBarNewsCTA,
  EBarEnactmentsCTA,
  EBarEthicsCTA,
  EBarAttorneysCTA,
  EBarResourcesCTA,
  EBarBoardOfProfessionalsCTA,
  EBarSCOAnnouncementsCTA,
  EBarOtherAnnouncementsCTA,
  EBarMemberCenterCTA,
  EBarManageEmailsCTA,
  EBarJobsCTA,
  EBarSearchPage,
  NewsCTA,
  OverlayCTASection,
  OverviewInfo,
  PaymentInformation,
  PersonalInfo,
  PracticeAreas,
  PracticeLibraryResults,
  PullQuote,
  PurchaseHistory,
  MyBookmarks,
  PracticeLibrarySearch,
  RadioButton,
  ResourceBody,
  ResourceHeading,
  ResourcePreviewList,
  SearchCTA,
  Sections,
  ShoppingCartBody,
  SiteSearchResults,
  SocialMediaInfo,
  SpecializationActionBar,
  EBarSnippetBlock,
  EBarFeatureBlock,
  EBarContentResourcesListBlock,
  SubHubDescription,
  TextArea,
  VideoPlayer,
};

document.addEventListener('DOMContentLoaded', () => {
  /** Mounts a React component based on container element attributes */
  const initialize = (componentContainer) => {
    // Pass initial state from attribute on component container element
    const props = JSON.parse(componentContainer.dataset.props || '{}');

    props.propertyName = componentContainer.dataset.epiPropertyName;

    // Render the component by resolving its class based on name
    ReactDOM.render(
      React.createElement(
        ComponentTypes[componentContainer.dataset.reactComponent],
        props,
      ),
      componentContainer,
    );
  };

  // Mount React components
  const reactComponents = document.querySelectorAll('[data-react-component]');

  // Using a for loop here because of IE
  // https://css-tricks.com/snippets/javascript/loop-queryselectorall-matches/
  for (let i = 0; i < reactComponents.length; i += 1) {
    initialize(reactComponents[i]);
  }
});
