import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@style-guide/config';

/**
# LiveSVG
An SVG live events icon
 */
const LiveSVG = ({
  height,
  width,
  color,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 51 77"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Live events icon</title>
    <desc>An icon for live event CLEs</desc>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        fill={color}
        fillRule="nonzero"
      >
        <path
          d="M42.9,8.1 C45.2,10.3 47,13 48.3,15.9 C49.6,18.9 50.3,22.1 50.3,25.5 C50.3,25.6 50.3,25.7 50.3,
            25.8 C50.3,25.9 50.3,26.1 50.3,26.2 C50.3,26.3 50.3,26.5 50.3,26.6 C50.3,28.1 50.2,29.5 49.9,
            30.8 C49.6,32.1 49.2,33.4 48.6,34.6 L30.8,72.7 C30.5,73.2 30.2,73.7 29.8,74.1 C29.4,74.5 29,
            74.9 28.5,75.2 C28,75.5 27.5,75.7 26.9,75.9 C26.3,76.1 25.7,76.2 25.1,76.2 C24.5,76.2 23.9,
            76.1 23.3,75.9 C22.7,75.7 22.2,75.5 21.7,75.2 C21.3,74.9 20.8,74.5 20.4,74.1 C20,73.7 19.7,
            73.2 19.4,72.6 L1.7,34.8 C1.2,33.6 0.8,32.3 0.5,30.9 C0.2,29.5 0,28.1 0,26.6 C0,26.5 0,26.4 0,
            26.3 C0,26.2 0,26.1 0,26 C0,25.9 0,25.9 0,25.8 C0,25.7 0,25.6 0,25.6 C0,22.2 0.7,19 2,16 C3.3,
            13 5.1,10.4 7.4,8.2 C9.6,6 12.2,4.2 15.2,2.9 C18.2,1.6 21.4,0.9 24.8,0.9 C24.9,0.9 25,0.9 25.1,
            0.9 C25.2,0.9 25.2,0.9 25.3,0.9 C25.4,0.9 25.4,0.9 25.5,0.9 C25.6,0.9 25.6,0.9 25.8,0.9 C29.2,
            0.9 32.4,1.6 35.4,2.9 C38.2,4.1 40.7,5.9 42.9,8.1 Z M38.4,36.6 C39.6,35.1 40.6,33.4 41.3,31.5 C42,
            29.6 42.4,27.7 42.4,25.5 C42.4,23.1 42,20.9 41.1,18.8 C40.2,16.7 39,14.9 37.4,13.4 C35.8,11.9 34,
            10.6 31.9,9.7 C29.8,8.8 27.6,8.4 25.2,8.4 C22.8,8.4 20.6,8.9 18.5,9.7 C16.4,10.6 14.6,11.8 13,
            13.4 C11.4,14.9 10.2,16.8 9.3,18.8 C8.4,21 8,23.2 8,25.6 C8,27.7 8.3,29.7 9.1,31.6 C9.8,33.5 10.8,
            35.1 12,36.6 C12.8,35.7 13.8,34.9 14.9,34.2 C16,33.5 17.1,33 18.4,32.6 C17.4,31.7 16.7,30.6 16.1,
            29.4 C15.5,28.1 15.2,26.8 15.2,25.3 C15.2,22.6 16.2,20.2 18.1,18.2 C20.1,16.2 22.4,15.3 25.3,
            15.3 C28,15.3 30.4,16.3 32.4,18.2 C34.4,20.2 35.3,22.5 35.3,25.3 C35.3,26.8 35,28.1 34.5,
            29.4 C33.9,30.7 33.2,31.8 32.2,32.7 C33.4,33 34.6,33.6 35.7,34.3 C36.6,34.8 37.5,35.6 38.4,
            36.6 Z M25.2,42.7 C27.2,42.7 29,42.4 30.8,41.8 C32.6,41.2 34.2,40.3 35.6,39.2 C34.4,38 32.9,
            37 31.3,36.3 C29.6,35.6 27.9,35.2 26,35.2 C25.8,35.2 25.7,35.2 25.5,35.2 C25.4,35.2 25.2,35.2 25.1,
            35.2 C25,35.2 24.9,35.2 24.7,35.2 C24.6,35.2 24.4,35.2 24.2,35.2 C22.3,35.2 20.6,35.6 18.9,
            36.3 C17.2,37 15.8,37.9 14.5,39.1 C16,40.2 17.6,41.1 19.4,41.8 C21.3,42.4 23.2,42.7 25.2,
            42.7 Z M18.9,25.2 C18.9,26.9 19.5,28.3 20.7,29.6 C21.9,30.8 23.4,31.5 25.1,31.5 C26.8,31.5 28.3,
            30.9 29.5,29.6 C30.7,28.4 31.3,26.9 31.3,25.2 C31.3,23.5 30.7,22 29.5,20.8 C28.3,19.6 26.8,19 25.1,
            19 C23.4,19 21.9,19.6 20.7,20.8 C19.5,22 18.9,23.5 18.9,25.2 Z"
        />
      </g>
    </g>
  </svg>
);

LiveSVG.propTypes = {
  /** height for the SVG. Can be px, rem, percentage, etc. */
  height: PropTypes.string,
  /** width for the SVG. Can be px, rem, percentage, etc. */
  width: PropTypes.string,
  /** a color for the icon */
  color: PropTypes.string,
};

LiveSVG.defaultProps = {
  height: '77px',
  width: '40px',
  color: colors.white,
};

LiveSVG.displayName = 'LiveSVG';

export default LiveSVG;
