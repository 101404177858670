import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import moment from 'moment';
import toLower from 'lodash/toLower';
import replace from 'lodash/replace';
import styled from 'styled-components';
import Divider from '@components/Divider';
import SocialShare from '@components/SocialShare';
import { LinkButton } from '@style-guide/components';
// import RadioButton from '@style-guide/components/RadioButton';
import { breakpoints, colors } from '@style-guide/config';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons/faCalendarPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from './components/Title';
import DataItem from './components/DataItem';
import Wrapper from './components/Wrapper';
// import ButtonWrapper from './components/ButtonWrapper';
import renderCredits from './utilities/renderCredits';
import SpecializationCreditWrapper from './components/SpecializationCreditWrapper';
import SpecializationCreditIcon from './components/SpecializationCreditIcon';

const EventItemContainer = styled.div`
  margin-bottom: 20px;
`;

EventItemContainer.displayName = 'EventItemContainer';

const BtnOuterDiv = styled.div`
  margin-bottom: 8px;
  margin-top: 8px;
`;

BtnOuterDiv.displayName = 'BtnOuterDiv';

const BtnContainer = styled.span`
  a {
    font-size: 14px;
    padding: 8px 14px;
    margin: 4px 8px 4px 0px;
  }
`;

BtnContainer.displayName = 'BtnContainer';

const Headings = styled.div`
  display: none;
  padding: 10px;

  span {
    font-weight: bold;
    flex: 1;
  }

  @media (min-width: ${breakpoints.xl}) {
    display: flex;
  }
`;

Headings.displayName = 'Headings';


// we need to display a properly formatting string if the date is on a single day
// or a range (date - date) if it's multi-day
function handleDateParsing(startDate, endDate) {
  // need to create moment objects out of the dates for easy comparison
  const startDateToCompare = moment(startDate);
  const endDateToCompare = moment(endDate);
  // use moment to compare the dates to see if it's multi-day or one day
  if (endDateToCompare.diff(startDateToCompare, 'days') >= 1) {
    return (`${moment(startDate).format('MMMM D, YYYY')} - ${moment(endDate).format('MMMM D, YYYY')}`);
  }
  return (moment(startDate).format('MMMM D, YYYY'));
}

const MyEventListItem = ({
  title,
  events,
  isAddToCalendar,
  isPastEvent,
  // onChangeRadioButton,
}) => (
  <EventItemContainer>
    <h3>{moment(title).format('MMMM YYYY')}</h3>
    <Divider hasDecorator />
    {events.map(event => (
      <Wrapper key={event.id}>
        <Title>{event.courseTitle}</Title>
        {event.date && (
          <DataItem>
            <strong>Date:</strong><span> {handleDateParsing(event.startDate, event.endDate)}</span>
          </DataItem>
        )}
        {event.startTime && event.endTime && (
          <DataItem>
            <strong>Time:</strong><span> {replace(toLower(event.startTime), ' ', '')} - {replace(toLower(event.endTime), ' ', '')}</span>
          </DataItem>
        )}
        {(is.not.empty(event.possibleCredits) && !isPastEvent) && (
        <DataItem>
          <strong>Credits: </strong><span>{renderCredits(event.possibleCredits)}</span>
        </DataItem>
        )}
        {(is.not.empty(event.possibleCredits) && isPastEvent) && (
          <DataItem>
            <strong>Possible Credit: </strong><span>{renderCredits(event.possibleCredits)}</span>
          </DataItem>
        )}
        {(is.not.empty(event.earnedCredits) && isPastEvent) && (
          <DataItem>
            <strong>Credit Earned: </strong><span>{renderCredits(event.earnedCredits)}</span>
          </DataItem>
        )}
        {event.specializationCredit && (
          <SpecializationCreditWrapper>
            <SpecializationCreditIcon />
            <strong>Specialization Credit:</strong><span> {event.specializationCredit}</span>
          </SpecializationCreditWrapper>
        )}
        {event.eventLocation && (
          <DataItem>
            <strong>Location: </strong><span>{replace(event.eventLocation, ' - ', ', ')}</span>
          </DataItem>
        )}
        {/* <ButtonWrapper>
          <RadioButton
            label="In Person"
            value="In Person"
            onChange={() => onChangeRadioButton(event.aptifyCourseId, event.attendanceType)}
            isChecked={event.attendanceType === 'In Person'}
            id={`inPerson_${event.aptifyCourseId}`}
            name="virtualInPerson"
            disabled={isPastEvent || event.attendanceType === ''}
          />
          <RadioButton
            label="Virtual"
            value="Virtual"
            onChange={() => onChangeRadioButton(event.aptifyCourseId, event.attendanceType)}
            isChecked={event.attendanceType === 'Virtual'}
            id={`virtual_${event.aptifyCourseId}`}
            name="virtualInPerson"
            disabled={isPastEvent || event.attendanceType === ''}
          />
        </ButtonWrapper> */}
        <BtnOuterDiv>
          {isAddToCalendar && (
            <BtnContainer>
              <LinkButton
                href={`/MyEventsPage/AddToCalendar?startDate=${event.startDate}&startTime=${event.startTime}&endDate=${event.startDate}&endTime=${event.endTime}&title=${event.courseTitle}&location=${event.eventLocation}`}
                target="_parent"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faCalendarPlus}
                  color={colors.white}
                  style={{ height: '10px', width: '10px' }}
                /> &nbsp;
                ADD TO CALENDAR
              </LinkButton>
            </BtnContainer>
          )}
          {/* eslint-disable */}
          {(event.detailLink !== '') &&
            <BtnContainer>
              <LinkButton href={event.classroomLink}>
                Meeting Details
              </LinkButton>
            </BtnContainer>
          }
          {/* eslint-enable */}
        </BtnOuterDiv>
        <SocialShare
          shareUrl={event.detailLink}
          articleTitle={event.courseTitle}
        />
      </Wrapper>
    ))}
  </EventItemContainer>
);

MyEventListItem.propTypes = {
  title: PropTypes.string,
  isAddToCalendar: PropTypes.bool,
  isPastEvent: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.shape({
      date: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      title: PropTypes.string,
      eventLocation: PropTypes.string,
      detailLink: PropTypes.string,
    }),
  })),
  // onChangeRadioButton: PropTypes.func,
};

MyEventListItem.defaultProps = {
  title: '',
  events: [],
  isAddToCalendar: false,
  isPastEvent: false,
  // onChangeRadioButton: () => { },
};

export default MyEventListItem;
