import styled from 'styled-components';

import { colors } from '@style-guide/config';

const ClearWrapper = styled.div`
  padding: ${props => (props.noCollapse ? '0px 0px 15px 0px' : '15px 0px')};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: ${colors.white};
`;

ClearWrapper.displayName = 'ClearWrapper';

export default ClearWrapper;
