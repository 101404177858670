import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RemoveButton from '@components/RemoveButton';
import { removeFromCart } from '@redux/modules/cart';
import startCase from 'lodash/startCase';

import CartItemContainer from './components/CartItemContainer';
import CartSummaryItemContainer from './components/CartSummaryItemContainer';
import ItemLink from './components/ItemLink';
import ItemName from './components/ItemName';
import ItemPrice from './components/ItemPrice';

const CartItem = ({
  dispatch,
  variant,
  displayName,
  price,
  note,
  contentGuid,
  city,
  date,
  detailUrl,
  productType,
  attendanceType,
  applyAutomatically,
  soldOut,
}) => {
  const removeItem = () => {
    dispatch(removeFromCart(contentGuid));
  };

  let productTypeFormatted;

  switch (productType) {
    case 'MeetingInstance':
      productTypeFormatted = 'CLE';
      break;
    case 'EventInstance':
      productTypeFormatted = 'Event';
      break;
    case 'CLEAddOn':
      productTypeFormatted = 'CLE Add-On';
      break;
    default: {
      productTypeFormatted = '';
      break;
    }
  }

  const renderItemDetails = () => (
    <React.Fragment>
      {productTypeFormatted && (
        <p>
          <strong><em>Type:</em></strong>{` ${productTypeFormatted}`}
        </p>
      )}
      {date && (
        <p>
          <strong><em>Date:</em></strong>{` ${date}`}
        </p>
      )}
      {city && (
        <p>
          <strong><em>City:</em></strong>{` ${city}`}
        </p>
      )}
      {attendanceType && (
        <p>
          { /* eslint-disable-line no-use-before-define */}
          <strong><em>Attendance Type:</em></strong>{` ${startCase([attendanceType])}`}
        </p>
      )}
      {note && (
        <p>
          <strong><em>Special Requests:</em></strong>{` ${note}`}
        </p>
      )}
    </React.Fragment>
  );

  if (variant === 'summary-item') {
    return (
      <CartSummaryItemContainer>
        <div>
          {detailUrl
            ?
            (
              <ItemName>
                <ItemLink href={detailUrl}>
                  {displayName}
                </ItemLink>
              </ItemName>
            )
            :
            (
              <ItemName>{displayName}</ItemName>
            )
          }
          <ItemPrice>{price}</ItemPrice>
        </div>
        {renderItemDetails()}
      </CartSummaryItemContainer>
    );
  }
  const soldOutStyle = {
    color: 'red',
    fontSize: 'small',
  };
  return (
    <CartItemContainer>
      <div>
        {detailUrl
          ?
          (
            <ItemName>
              <ItemLink href={detailUrl}>
                {displayName}
              </ItemLink>
            </ItemName>
          )
          :
          (
            <ItemName>{displayName}</ItemName>
          )
        }
        <ItemPrice>
          {price}
          {!applyAutomatically && (
            <RemoveButton
              screenReaderLabel="Remove Item"
              action={removeItem}
              onClick={removeItem}
            />
          )}
        </ItemPrice>
      </div>
      {renderItemDetails()}
      {soldOut && (
      <p style={soldOutStyle}>This event has reached capacity.
         Please contact OSBA Member Services at 1-800-232-7124
         for additional options and information.
      </p>
      )}
    </CartItemContainer>
  );
};

CartItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  variant: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  note: PropTypes.string,
  contentGuid: PropTypes.string,
  city: PropTypes.string,
  date: PropTypes.string,
  detailUrl: PropTypes.string,
  productType: PropTypes.string,
  attendanceType: PropTypes.string,
  applyAutomatically: PropTypes.bool,
  soldOut: PropTypes.bool,
};

CartItem.defaultProps = {
  variant: 'default',
  note: '',
  contentGuid: '',
  city: '',
  date: '',
  detailUrl: '',
  productType: '',
  attendanceType: '',
  applyAutomatically: false,
  soldOut: false,
};

CartItem.displayName = 'CartItem';

export default connect()(CartItem);
