import styled from 'styled-components';

import { ClearFix } from '@style-guide/mixins';

const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  line-height: 1.25em;
  margin-bottom: ${props => (props.hasBottomMargin ? '30px' : '0')};
  margin-top: ${props => (props.hasTopMargin ? '30px' : '0')};
  width: 100%;

  ${props => (props.hasTopMargin || props.hasBottomMargin ? ClearFix() : '')}
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
