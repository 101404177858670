import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';

import Button from '@style-guide/components/Button';

import FormFieldSection from '@components/FormFieldSection';
import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormCheckbox from '@components/ReduxFormCheckbox';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormTextAreaInput from '@components/ReduxFormTextAreaInput';

import Admissions from './components/Admissions';
import CountiesServed from './components/CountiesServed';

const OverviewInfoForm = ({
  handleSubmit,
  loading,
  pristine,
  hasSaveError,
  counties,
  states,
  invalid,
}) => (
  <form
    id="overviewInfoForm"
    onSubmit={handleSubmit}
  >
    <FormFieldWrapper wide>
      <Field
        id="tagline"
        label="Tagline"
        name="Tagline"
        props={{
          rows: '5',
          maxLength: '2000',
        }}
        component={ReduxFormTextAreaInput}
      />
    </FormFieldWrapper>
    <FormFieldWrapper wide>
      <Field
        id="biography"
        label="Bio/About"
        name="Biography"
        props={{
          rows: '5',
          maxLength: '2000',
        }}
        component={ReduxFormTextAreaInput}
      />
    </FormFieldWrapper>
    <FormFieldSection
      heading="Admissions"
    >
      <FieldArray
        name="Admissions"
        props={{
          states,
        }}
        component={Admissions}
        rerenderOnEveryChange
      />
    </FormFieldSection>
    <FormFieldWrapper wide>
      <Field
        id="courtsOfAdmission"
        label="Courts of Admission"
        name="CourtsOfAdmission"
        props={{
          rows: '5',
          maxLength: '2000',
        }}
        component={ReduxFormTextAreaInput}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        id="priceArrangements"
        label="Price range, fee arrangements, fixed or reduced fees"
        name="PriceArrangements"
        component={ReduxFormInput}
      />
    </FormFieldWrapper>
    <FormFieldSection>
      <FieldArray
        name="CountiesServed"
        props={{
          counties,
        }}
        component={CountiesServed}
        rerenderOnEveryChange
      />
    </FormFieldSection>
    <FormFieldSection
      heading="Other Services"
    >
      <FormFieldWrapper wide>
        <div>
          <Field
            id="isProbono"
            label="Probono, Free or Fixed Rate Consultation"
            name="IsProbono"
            props={{
              value: true,
            }}
            type="checkbox"
            component={ReduxFormCheckbox}
          />
        </div>
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <div>
          <Field
            id="mediation"
            label="Mediation / Alternative Dispute Resolution"
            name="Mediation"
            props={{
              value: true,
            }}
            type="checkbox"
            component={ReduxFormCheckbox}
          />
        </div>
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <div>
          <Field
            id="urgentCalls"
            label="Urgent Calls"
            name="UrgentCalls"
            props={{
              value: true,
            }}
            type="checkbox"
            component={ReduxFormCheckbox}
          />
        </div>
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <div>
          <Field
            id="afterHoursCalls"
            label="After Hours Calls"
            name="AfterHourCalls"
            props={{
              value: true,
            }}
            type="checkbox"
            component={ReduxFormCheckbox}
          />
        </div>
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <div>
          <Field
            id="houseCalls"
            label="House Calls"
            name="HouseCalls"
            props={{
              value: true,
            }}
            type="checkbox"
            component={ReduxFormCheckbox}
          />
        </div>
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <div>
          <Field
            id="limitedScopeRepresentation"
            label="Limited Scope Representation"
            name="LimitedScopeRepresentation"
            props={{
              value: true,
            }}
            type="checkbox"
            component={ReduxFormCheckbox}
          />
        </div>
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <div>
          <Field
            id="acceptingNewClients"
            label="Accepting New Clients"
            name="AcceptingNewClients"
            props={{
              value: true,
            }}
            type="checkbox"
            component={ReduxFormCheckbox}
          />
        </div>
      </FormFieldWrapper>
    </FormFieldSection>
    <Button
      type="submit"
      disabled={((pristine || loading) && !hasSaveError) || invalid}
    >
      Save
    </Button>
  </form>
);

OverviewInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
  counties: PropTypes.arrayOf(PropTypes.string),
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
};

OverviewInfoForm.defaultProps = {
  hasSaveError: false,
  counties: [],
};

OverviewInfoForm.displayName = 'OverviewInfoForm';

export default reduxForm({
  form: 'overviewInfoForm',
  enableReinitialize: true,
})(OverviewInfoForm);
