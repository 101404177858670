import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import Button from '@style-guide/components/Button';
import ComponentDiv from './components/ComponentDiv';
import Name from './components/Name';
import ListParagraph from '../../../EBarHome/components/EBarHome/EBarHomeContent/components/Shared/components/ListParagraph';

const ActionButton = styled.button`
  border: none;
  cursor: pointer;
`;

ActionButton.displayName = 'ActionButton';

const ViewColumn = styled.td`
  background-color: #FFFFFF;
`;

ViewColumn.displayName = 'ViewColumn';

const ImageStyle = {
  height: '24px',
  width: '24px',
};

const EBarEnactmentsResult = ({
  initialValues,
  viewId,
  onClickViewButton,
  onClickEditButton,
  onClickCloseCancel,
  onClickDelete,
}) => (
  <React.Fragment>
    <tr style={{ color: initialValues.addedDate == null ? 'red' : '', backgroundColor: initialValues.addedDate == null ? 'aliceblue' : '' }}>
      <td>{initialValues.title}</td>
      <td>{initialValues.billNumber}</td>
      <td>{initialValues.effectiveDate != null ? moment(initialValues.effectiveDate).format('MM/DD/YYYY') : ''}</td>
      <td>{initialValues.addedDate != null ? moment(initialValues.addedDate).format('MM/DD/YYYY') : ''}</td>
      <td>{initialValues.modifiedBy}</td>
      <td>
        <ActionButton
          title="Edit"
          onClick={() => onClickEditButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Edit.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="View"
          onClick={() => onClickViewButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Detail.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="Delete"
          onClick={() => onClickDelete(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Delete.png" alt="" style={ImageStyle} />
        </ActionButton>
      </td>
    </tr>
    {viewId && viewId === initialValues.contentGuidString && (
    <tr>
      <ViewColumn colSpan="6">
        <ComponentDiv>
          <Name>{initialValues.title}</Name>
          {/* eslint-disable-next-line react/no-danger */}
          <ListParagraph dangerouslySetInnerHTML={{ __html: initialValues.summary }} />
          <p><span>Bill Number: </span>{initialValues.billNumber}</p>
          <p><span>CTA Label: </span>{initialValues.ctaLabel}</p>
          <p><span>CTA URL: </span>{initialValues.ctaUrl}</p>
          <p><span>Effective Date:</span> {initialValues.effectiveDate != null ? moment(initialValues.effectiveDate).format('MM/DD/YYYY') : ''}</p>
          <p><span>Active Date:</span> {initialValues.addedDate != null ? moment(initialValues.addedDate).format('MM/DD/YYYY') : ''}</p>
          <p><span>Author: </span>{initialValues.modifiedBy}</p>
          <Button
            onClick={() => onClickCloseCancel()}
          >
            Close
          </Button>
        </ComponentDiv>
      </ViewColumn>
    </tr>
    )}
  </React.Fragment>
);


EBarEnactmentsResult.displayName = 'EBarEnactmentsResult';

EBarEnactmentsResult.propTypes = {
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    billNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  viewId: PropTypes.string,
  onClickViewButton: PropTypes.func.isRequired,
  onClickEditButton: PropTypes.func.isRequired,
  onClickCloseCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

EBarEnactmentsResult.defaultProps = {
  initialValues: {
    summary: '',
    billNumber: '',
    effectiveDate: '',
    title: '',
    addedDate: '',
    ctaLabel: '',
    ctaUrl: '',
    modifiedBy: '',
  },
  viewId: '',
};

export default EBarEnactmentsResult;
