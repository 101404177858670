import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

const FormFieldSectionContainer = styled.div`
  background-color: ${colors.white};
  padding: 0 15px 15px 15px;
  margin: 0;
`;

FormFieldSectionContainer.displayName = 'FormFieldSectionContainer';

const FormFieldSectionContent = styled.div`
  padding: 15px 0 0;

  @media (min-width: ${breakpoints.s}) {
    padding: 15px;
  }
`;

FormFieldSectionContent.displayName = 'FormFieldSectionContent';

const FormFieldSection = ({
  children,
}) => (
  <FormFieldSectionContainer>
    <FormFieldSectionContent>
      {children}
    </FormFieldSectionContent>
  </FormFieldSectionContainer>
);

FormFieldSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

FormFieldSection.defaultProps = {
  children: undefined,
};

export default FormFieldSection;
