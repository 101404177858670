import React from 'react';
import is from 'is_js';
import PropTypes from 'prop-types';

import TabTitle from '../TabTitle';

import Wrapper from './components/Wrapper';
import CourseMaterialsItem from './components/CourseMaterialsItem';
import CourseMaterialsList from './components/CourseMaterialsList';
import CourseMaterialsItemWrapper from './components/CourseMaterialsItemWrapper';
import CourseMaterialsLink from './components/CourseMaterialsLink';

const CourseMaterialsTab = ({
  courseMaterials,
  eventInstance,
}) => (
  <Wrapper>
    <TabTitle>{eventInstance.displayName}</TabTitle>
    <CourseMaterialsList>
      {is.not.empty(courseMaterials) && courseMaterials.map(item => (
        <CourseMaterialsItemWrapper>
          <CourseMaterialsItem
            fileName={item.fileName}
            fileType={item.fileType}
            url={item.url}
            sizeFormattedString={item.sizeFormattedString}
          />
        </CourseMaterialsItemWrapper>
      ))}
      {((eventInstance.ctaLabel1 != null && eventInstance.ctaLabel1 !== '') || (eventInstance.ctaUrl1 != null && eventInstance.ctaUrl1 !== '')) && (
        <CourseMaterialsItemWrapper>
          <CourseMaterialsLink
            ctaLabel={eventInstance.ctaLabel1}
            ctaUrl={eventInstance.ctaUrl1}
          />
        </CourseMaterialsItemWrapper>
      )}
      {((eventInstance.ctaLabel2 != null && eventInstance.ctaLabel2 !== '') || (eventInstance.ctaUrl2 != null && eventInstance.ctaUrl2 !== '')) && (
        <CourseMaterialsItemWrapper>
          <CourseMaterialsLink
            ctaLabel={eventInstance.ctaLabel2}
            ctaUrl={eventInstance.ctaUrl2}
          />
        </CourseMaterialsItemWrapper>
      )}
      {((eventInstance.ctaLabel3 != null && eventInstance.ctaLabel3 !== '') || (eventInstance.ctaUrl3 != null && eventInstance.ctaUrl3 !== '')) && (
        <CourseMaterialsItemWrapper>
          <CourseMaterialsLink
            ctaLabel={eventInstance.ctaLabel3}
            ctaUrl={eventInstance.ctaUrl3}
          />
        </CourseMaterialsItemWrapper>
      )}
    </CourseMaterialsList>

  </Wrapper>
);

CourseMaterialsTab.propTypes = {
  courseMaterials: PropTypes.arrayOf(PropTypes.object),
  eventInstance: PropTypes.shape({
    displayName: PropTypes.string,
    ctaLabel1: PropTypes.string,
    ctaUrl1: PropTypes.string,
    ctaLabel2: PropTypes.string,
    ctaUrl2: PropTypes.string,
    ctaLabel3: PropTypes.string,
    ctaUrl3: PropTypes.string,
  }).isRequired,
};

CourseMaterialsTab.defaultProps = {
  courseMaterials: [],
};

CourseMaterialsTab.displayName = 'CourseMaterialsTab';

export default CourseMaterialsTab;
