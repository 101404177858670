import React from 'react';
import PropTypes from 'prop-types';

import SpeakerCardContainer from './components/SpeakerCardContainer';
import SpeakerCompany from './components/SpeakerCompany';
import SpeakerName from './components/SpeakerName';

/* eslint-disable */
const SpeakerCard = ({ speaker }) => (
  <SpeakerCardContainer>
    {speaker.pictureLink && (
      <div>
        <img src={speaker.pictureLink} alt="CLE Speaker" />
      </div>
    )}
    <div>
      <SpeakerName>{`${speaker.firstName} ${speaker.lastName}`}{speaker.title && `, ${speaker.title}`}</SpeakerName>
      <SpeakerCompany>{speaker.instructorCity}</SpeakerCompany>
      <SpeakerCompany>{speaker.company}</SpeakerCompany>
    </div>
  </SpeakerCardContainer>
);

SpeakerCard.displayName = 'SpeakerCard';
/* eslint-enable */

SpeakerCard.propTypes = {
  speaker: PropTypes.shape({
    DisplayName: PropTypes.string,
  }),
};

SpeakerCard.defaultProps = {
  speaker: {
    DisplayName: '',
  },
};

export default SpeakerCard;
