import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import toNumber from 'lodash/toNumber';
import Button from '@style-guide/components/Button';

import ErrorMessage from '@components/ErrorMessage';
import FormFieldSet from '@components/FormFieldSet';
import FormFieldSubGroup from '@components/FormFieldSubGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import RadioButtonGroup from '@components/RadioButtonGroup';
import ReduxFormAutoSuggestInput from '@components/ReduxFormAutoSuggestInput';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormRadioButton from '@components/ReduxFormRadioButton';
import ReduxFormSelect from '@components/ReduxFormSelect';

const CompanyInfoForm = ({
  companyTypeComparisonValue,
  CompanyTypeId,
  companyTypes,
  counties,
  employmentStatuses,
  firmSizes,
  handleSubmit,
  hasSaveError,
  invalid,
  jobTitles,
  loading,
  OSBAEmploymentStatusTypeID,
  pristine,
  states,
  Title,
  warning,
}) => {
  const employmentStatusRadioButtons = employmentStatuses.map(({
    text,
    value,
  }) => (
    <li
      key={text}
    >
      <Field
        component={ReduxFormRadioButton}
        id={`employmentStatusRadioButton${value}`}
        label={text}
        name="OSBAEmploymentStatusTypeID"
        props={{
          value,
        }}
        type="radiobutton"
      />
    </li>
  ));

  const companyTypeOptions = companyTypes.map(type => ({
    disabled: type.disabled,
    label: type.text,
    value: type.value,
  }));

  const companyTypeOptionsWithDefault = [
    {
      disabled: false,
      label: '',
      value: 0,
    },
    ...companyTypeOptions,
  ];

  const isCompanyInfoRequired = `${OSBAEmploymentStatusTypeID}` !== '3' && `${OSBAEmploymentStatusTypeID}` !== '0';

  return (
    <form
      id="companyInfoForm"
      onSubmit={handleSubmit}
    >
      <FormFieldWrapper>
        <FormFieldSet
          label="Employment Status"
        >
          <RadioButtonGroup>
            {employmentStatusRadioButtons}
          </RadioButtonGroup>
        </FormFieldSet>
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          component={ReduxFormSelect}
          id="title"
          label="Job Title"
          name="Title"
          options={jobTitles.map(jobTitle => ({
            label: jobTitle.text,
            value: jobTitle.text,
          }))}
        />
        {Title === 'Other' && (
          <Field
            component={ReduxFormInput}
            id="specialJobTitle"
            label="Other Job Title"
            name="SpecialJobTitle"
          />
        )}
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          component={ReduxFormInput}
          id="companyName"
          label={`Company Name${isCompanyInfoRequired ? '*' : ''}`}
          name="CompanyName"
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          component={ReduxFormAutoSuggestInput}
          id="companyCounty"
          label="County"
          name="CompanyCounty"
          onlySuggestedValuesAllowed
          suggestions={counties}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          component={ReduxFormInput}
          id="companyAddressLine1"
          label="Street"
          name="CompanyAddressLine1"
        />
        <Field
          component={ReduxFormInput}
          id="companyAddressLine2"
          label="PO Box / Suite"
          name="CompanyAddressLine2"
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          component={ReduxFormInput}
          id="companyCity"
          label="City"
          name="CompanyCity"
        />
        <FormFieldSubGroup>
          <Field
            component={ReduxFormSelect}
            id="companyState"
            label="State"
            name="CompanyState"
            options={states.map(state => ({
              label: state.value,
              value: state.value,
            }))}
          />
          <Field
            component={ReduxFormInput}
            id="companyZipCode"
            label="Zip Code"
            name="CompanyZipCode"
            type="tel"
          />
        </FormFieldSubGroup>
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          component={ReduxFormSelect}
          id="companyTypeId"
          label={`Practice Type${isCompanyInfoRequired ? '*' : ''}`}
          name="CompanyTypeId"
          options={companyTypeOptionsWithDefault}
        />
        {toNumber(CompanyTypeId) === companyTypeComparisonValue && (
          <Field
            component={ReduxFormSelect}
            id="numberOfLawyers"
            label={`Size of Firm${isCompanyInfoRequired ? '*' : ''}`}
            name="NumberOfLawyers"
            options={firmSizes.map(firmSize => ({
              label: firmSize.text,
              value: toNumber(firmSize.value),
            }))}
          />
        )}
      </FormFieldWrapper>
      {warning && (
        <FormFieldWrapper>
          <div><ErrorMessage warn>{warning}</ErrorMessage></div>
        </FormFieldWrapper>
      )}
      <Button
        disabled={(pristine || loading || invalid) && !hasSaveError}
        type="submit"
      >
        Save
      </Button>
    </form>
  );
};

CompanyInfoForm.propTypes = {
  companyTypeComparisonValue: PropTypes.number.isRequired,
  CompanyTypeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  companyTypes: PropTypes.arrayOf(PropTypes.object),
  counties: PropTypes.arrayOf(PropTypes.string),
  employmentStatuses: PropTypes.arrayOf(PropTypes.object),
  firmSizes: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  hasSaveError: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  jobTitles: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  OSBAEmploymentStatusTypeID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  states: PropTypes.arrayOf(PropTypes.object),
  Title: PropTypes.string,
  warning: PropTypes.string,
};


CompanyInfoForm.defaultProps = {
  CompanyTypeId: 0,
  companyTypes: [],
  counties: [],
  employmentStatuses: [],
  firmSizes: [],
  hasSaveError: false,
  jobTitles: [],
  states: [],
  Title: '',
  warning: '',
};

CompanyInfoForm.displayName = 'CompanyInfoForm';

const validate = ({
  CompanyName,
  CompanyTypeId,
  NumberOfLawyers,
  OSBAEmploymentStatusTypeID,
}) => {
  const errors = {};

  if (`${OSBAEmploymentStatusTypeID}` !== '3' && !CompanyName) {
    errors.CompanyName = 'Required';
  } else if (`${OSBAEmploymentStatusTypeID}` !== '3' && !NumberOfLawyers) {
    errors.NumberOfLawyers = 'Required';
  } else if (
    `${OSBAEmploymentStatusTypeID}` !== '3'
        &&
        (!CompanyTypeId || CompanyTypeId === '0')
  ) {
    errors.CompanyTypeId = 'Required';
  }
  return errors;
};

const warn = ({
  CompanyName,
  CompanyTypeId,
  NumberOfLawyers,
  OSBAEmploymentStatusTypeID,
}, props) => {
  const warnings = {};

  if (
    (`${OSBAEmploymentStatusTypeID}` !== '3' && !CompanyName)
    ||
    (`${OSBAEmploymentStatusTypeID}` !== '3' && !CompanyTypeId)
    ||
    // TODO: add check for "NOT 'other' CompanyTypeID"
    (`${OSBAEmploymentStatusTypeID}` !== '3' && !NumberOfLawyers)
  ) {
    /* this is part of redux-form API */
    /* eslint-disable no-underscore-dangle */
    warnings._warning = 'Missing required fields';
    props.setWarning(true);
  } else {
    props.setWarning(false);
  }

  return warnings;
};

const CompanyInfoReduxForm = reduxForm({
  form: 'companyInfoForm',
  enableReinitialize: true,
  validate,
  warn,
})(CompanyInfoForm);

/* following example at https://redux-form.com/7.4.2/examples/selectingformvalues/ */
const selector = formValueSelector('companyInfoForm');

const CompanyInfoFormConnected = connect((state) => {
  const CompanyName = selector(state, 'CompanyName');
  const CompanyTypeId = selector(state, 'CompanyTypeId');
  const NumberOfLawyers = selector(state, 'NumberOfLawyers');
  const OSBAEmploymentStatusTypeID = selector(state, 'OSBAEmploymentStatusTypeID');
  const Title = selector(state, 'Title');

  return {
    CompanyName,
    CompanyTypeId,
    NumberOfLawyers,
    OSBAEmploymentStatusTypeID,
    Title,
  };
})(CompanyInfoReduxForm);

export default CompanyInfoFormConnected;
