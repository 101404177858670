import ApiGenerator from '@services/ApiGenerator';

const HYDRATE = 'osba/account/ENEWSLETTERS_INFO_HYDRATE';
const SAVE = 'osba/account/ENEWSLETTERS_INFO_SAVE';
const SAVE_ERROR = 'osba/account/ENEWSLETTERS_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/ENEWSLETTERS_INFO_SAVE_RECEIVED';

const initialState = {
  eNewsLettersGreenBook: true,
  frequencyGreenBook: 1,
  allPracticeAreasSelected: 1,
  allCourtsSelected: 1,
  PracticeAreasSelected: [],
  CourtsSelected: [],
  eNewsLettersBriefs: true,
  loading: false,
  error: '',
  message: '',
};

const flattenArray = (inputArray) => {
  const flattenedArray = inputArray.map(item => item.name);
  return flattenedArray;
};

const flattenArrayPost = (inputArrayPost) => {
  const flattenedArrayPost = inputArrayPost.map(item => item.Name);
  return flattenedArrayPost;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.data,
        message: '',
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...action.data,
        loading: true,
        message: '',
        error: '',
      };
    case SAVE_RECEIVED:
      return {
        ...state,
        ...action.data,
        PracticeAreasSelected: flattenArrayPost(action.data.practiceAreasSelected),
        CourtsSelected: flattenArrayPost(action.data.courtsSelected),
        message: action.data.message,
        loading: false,
      };
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const hydrateEnewsLettersInfo = propsData => ({
  type: HYDRATE,
  data: {
    eNewsLettersGreenBook: propsData.eNewsLettersGreenBook,
    frequencyGreenBook: propsData.frequencyGreenBook,
    allPracticeAreasSelected: propsData.allPracticeAreasSelected,
    allCourtsSelected: propsData.allCourtsSelected,
    PracticeAreasSelected: flattenArray(propsData.practiceAreasSelected),
    CourtsSelected: flattenArray(propsData.courtsSelected),
    eNewsLettersBriefs: propsData.eNewsLettersBriefs,
  },
});

export const saveEnewsLettersInfo = formData => ({
  type: SAVE,
  data: {
    eNewsLettersGreenBook: formData.eNewsLettersGreenBook,
    frequencyGreenBook: formData.frequencyGreenBook,
    allPracticeAreasSelected: formData.allPracticeAreasSelected,
    allCourtsSelected: formData.allCourtsSelected,
    practiceAreasSelected: formData.PracticeAreasSelected,
    courtsSelected: formData.CourtsSelected,
    eNewsLettersBriefs: formData.eNewsLettersBriefs,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case SAVE:
      api('post', '/ENewsLettersBlock/Save', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
