import React from 'react';
import PropTypes from 'prop-types';

import {
  eBarSendDailyReportMail,
  eBarSendWeeklyReportMail,
  eBarSendTestEmails,
} from '@services/EBarManageEmails';

import Input from '@style-guide/components/Input';
import LoadingOverlay from '@components/LoadingOverlay';
import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import Button from '@style-guide/components/Button';
import RadioButton from '@style-guide/components/RadioButton';
import Store from '@redux';
import PopUpModal from './components/PopUpModal/PopUpModal';
import RefreshButton from './components/RefreshButton';
import BlockWrapper from './components/BlockWrapper';
import { CaseTable, ColumnHeaders } from './components/CaseTable';
import EBarManageEmailsResultDiv from './components/EBarManageEmailsResultDiv';
import EBarManageEmailsJobResult from './components/EBarManageEmailsJobResult/EBarManageEmailsJobResult';
import ButtonWrapper from './components/ButtonWrapper';
import RefreshButtonWrapper from './components/RefreshButtonWrapper';
import BodyDiv from './components/BodyDiv';
import HeaderDiv from './components/HeaderDiv';
import Title from './components/Title';
import Wrapper from './components/Wrapper';
import MessageModalBody from './components/MessageModalBody';


/**
# EBar ManageEmailsCTA
A CTA containing list of links to OSBA Member Center Information items.
 */
class EBarManageEmailsCTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      eBarManageEmailsJobsHistory: this.props.eBarManageEmailsJobs.length > 0 ?
        (this.props.eBarManageEmailsJobs.filter(history => history.ReportType === 'Daily').length > 10 ?
          this.props.eBarManageEmailsJobs.filter(history => history.ReportType === 'Daily').slice(0, 10) :
          this.props.eBarManageEmailsJobs.filter(history => history.ReportType === 'Daily')) : [],
      headingState: this.props.heading,
      showMessagePopUp: false,
      showEmailErrorMessage: false,
      prevPage: 0,
      nextPage: this.props.eBarManageEmailsJobs.filter(history => history.ReportType === 'Daily').length > 0 ?
        Math.ceil(this.props.eBarManageEmailsJobs.filter(history => history.ReportType === 'Daily').length / 10) - 1 : 0,
      messagePopUpTitle: '',
      totalPage: this.props.eBarManageEmailsJobs.filter(history => history.ReportType === 'Daily').length > 0 ?
        Math.ceil(this.props.eBarManageEmailsJobs.filter(history => history.ReportType === 'Daily').length / 10) : 0,
      eBarManageEmailsJobsState: this.props.eBarManageEmailsJobs,
      dailyWeekly: 'Daily',
      testEmailId: '',
    };

    this.store = Store.get();

    this.onClickCloseCancel = this.onClickCloseCancel.bind(this);
    this.onChangeRadioButton = this.onChangeRadioButton.bind(this);
    this.onClickSend = this.onClickSend.bind(this);
    this.onTestClickSend = this.onTestClickSend.bind(this);
    this.saveTestEmail = this.saveTestEmail.bind(this);
    this.onClickJobHistory = this.onClickJobHistory.bind(this);

    this.onClickJobHistory();
  }

  onClickCloseCancel() {
    this.setState({
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onChangeRadioButton(e) {
    const TotalPage = this.state.eBarManageEmailsJobsState.filter(history => history.ReportType
      === e.target.value).length > 0 ? Math.ceil(this.state.eBarManageEmailsJobsState
        .filter(history => history.ReportType === e.target.value).length / 10) : 0;
    this.setState({
      dailyWeekly: e.target.value,
      eBarManageEmailsJobsHistory:
        this.state.eBarManageEmailsJobsState
          .filter(history => history.ReportType === e.target.value).slice(0,
            this.state.eBarManageEmailsJobsState.length > 10 ? 10 :
              this.state.eBarManageEmailsJobsState.length),
      totalPage: TotalPage,
      prevPage: 0,
      nextPage: TotalPage - 1,
    });
  }

  onClickSend() {
    this.setState({
      loading: true,
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
    if (this.state.dailyWeekly === 'Daily') {
      eBarSendDailyReportMail()
        .then(() => {
          this.setState({
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Daily Report Mail Job Started !!',
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Daily Report Mail Job Failed !!',
          });
        });
    } else if (this.state.dailyWeekly === 'Weekly') {
      eBarSendWeeklyReportMail()
        .then(() => {
          this.setState({
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Weekly Report Mail Job Started !!',
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Weekly Report Mail Job Failed !!',
          });
        });
    }
  }

  onTestClickSend() {
    this.setState({
      loading: true,
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
    eBarSendTestEmails(this.state.dailyWeekly, this.state.testEmailId)
      .then(() => {
        this.setState({
          loading: false,
          testEmailId: '',
          showMessagePopUp: true,
          messagePopUpTitle: 'Test Email Sent',
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          testEmailId: '',
          showMessagePopUp: true,
          messagePopUpTitle: 'Test Email failed !!',
        });
      });
  }

  onClickJobHistory() {
    this.setState({
      loading: true,
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
    fetch('/EBarManageEmailsCallToActionBlock/GetJobHistory', {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then((data) => {
        const TotalPage = data.filter(history => history.ReportType === 'Daily').length > 0 ?
          Math.ceil(data.filter(history => history.ReportType === 'Daily').length / 10) : 0;
        this.setState({
          loading: false,
          showMessagePopUp: false,
          eBarManageEmailsJobsState: data,
          eBarManageEmailsJobsHistory: data.length > 0 ?
            (data.length > 10 ? data.filter(history => history.ReportType === 'Daily').slice(0, 10) :
              data.filter(history => history.ReportType === 'Daily')) : [],
          totalPage: TotalPage,
          prevPage: 0,
          nextPage: TotalPage - 1,
          dailyWeekly: 'Daily',
        });
      });
  }

  onClickPrevious() {
    const PrevPage = this.state.prevPage;

    this.setState({
      eBarManageEmailsJobsHistory:
        this.state.eBarManageEmailsJobsState
          .filter(history => history.ReportType ===
              this.state.dailyWeekly).slice((PrevPage - 1) * 10, PrevPage * 10),
      prevPage: this.state.prevPage - 1,
      nextPage: this.state.nextPage + 1,
    });
  }

  onClickNext() {
    const PrevPage = this.state.prevPage + 1;
    const jobHistory = this.state.eBarManageEmailsJobsState;

    this.setState({
      eBarManageEmailsJobsHistory:
        jobHistory.filter(history => history.ReportType === this.state.dailyWeekly)
          .slice(PrevPage * 10, (PrevPage + 1) * 10),
      prevPage: this.state.prevPage + 1,
      nextPage: this.state.nextPage - 1,
    });
  }

  saveTestEmail(event) {
    this.setState({
      testEmailId: event.target.value,
    });

    const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(event.target.value) && event.target.value.length > 0) {
      this.setState({
        showEmailErrorMessage: true,
      });
    } else {
      this.setState({
        showEmailErrorMessage: false,
      });
    }
  }

  render() {
    const {
      loading,
      eBarManageEmailsJobsState,
      eBarManageEmailsJobsHistory,
      headingState,
      showMessagePopUp,
      showEmailErrorMessage,
      totalPage,
      prevPage,
      nextPage,
      messagePopUpTitle,
    } = this.state;

    return (
      <BlockWrapper>
        {loading && <LoadingOverlay />}
        {showMessagePopUp && messagePopUpTitle && (
          <PopUpModal
            dismissable={false}
            body={(
              <MessageModalBody
                title={messagePopUpTitle}
                okFunction={this.onClickCloseCancel}
              />
            )}
            closeAction={this.onClickCloseCancel}
          />
        )}
        <Wrapper>
          <YellowLineSVG />
          <HeaderDiv>
            <Title>{!headingState ? 'Manage Emails' : headingState}</Title>
          </HeaderDiv>
          <BodyDiv>
            <ButtonWrapper>
              <RadioButton
                label="Daily"
                value="Daily"
                onChange={this.onChangeRadioButton}
                isChecked={this.state.dailyWeekly === 'Daily'}
                id="daily"
                name="weekly"
              />
              <RadioButton
                label="Weekly"
                value="Weekly"
                onChange={this.onChangeRadioButton}
                isChecked={this.state.dailyWeekly === 'Weekly'}
                id="weekly"
                name="weekly"
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => this.onClickSend()}
              >
                Send
              </Button>
            </ButtonWrapper>
          </BodyDiv>
          <BodyDiv>
            <Input
              id="emailInput"
              type="text"
              placeholder="Email"
              label="Send Test Email"
              onChange={this.saveTestEmail}
              value={this.state.testEmailId}
            />
            &nbsp;
            <ButtonWrapper>
              <Button
                disabled={!this.state.testEmailId || this.state.showEmailErrorMessage}
                onClick={this.onTestClickSend}
              >
                Send
              </Button>
            </ButtonWrapper>
          </BodyDiv>
          {showEmailErrorMessage && (<p>Invalid Email!!</p>)}
          {(eBarManageEmailsJobsHistory.length > 0) && (eBarManageEmailsJobsState.length > 0) && (
            <EBarManageEmailsResultDiv>
              <RefreshButtonWrapper>
                <RefreshButton
                  onClick={() => this.onClickJobHistory()}
                >
                  Refresh
                </RefreshButton>
              </RefreshButtonWrapper>
              <CaseTable>
                <thead>
                  <ColumnHeaders>
                    <th><h3>Date</h3></th>
                    <th><h3>Report Type</h3></th>
                    <th><h3>Job Status</h3></th>
                  </ColumnHeaders>
                </thead>
                <tbody>
                  {eBarManageEmailsJobsHistory.map(c => (
                    <EBarManageEmailsJobResult
                      key={c.Id}
                      initialValues={c}
                    />
                  ))}
                </tbody>
              </CaseTable>
              {totalPage > 0 && (
              <ButtonWrapper>
                <Button
                  onClick={() => this.onClickPrevious()}
                  disabled={prevPage === 0}
                >
                  Previous
                </Button>
                <Button
                  onClick={() => this.onClickNext()}
                  disabled={nextPage < 1}
                >
                  Next
                </Button>
              </ButtonWrapper>
              )}
            </EBarManageEmailsResultDiv>
          )}
        </Wrapper>
      </BlockWrapper>
    );
  }
}

EBarManageEmailsCTA.propTypes = {
  eBarManageEmailsJobs: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string,
    Timestamp: PropTypes.date,
    ReportType: PropTypes.string,
    Status: PropTypes.string,
    StatusDetail: PropTypes.string,
  })),
  heading: PropTypes.string,
};

EBarManageEmailsCTA.defaultProps = {
  eBarManageEmailsJobs: [],
  heading: 'EBar Manage Emails',
};


EBarManageEmailsCTA.displayName = 'EBarManageEmailsCTA';

export default EBarManageEmailsCTA;
