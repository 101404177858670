import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';

import Button from '@style-guide/components/Button';

import FormFieldSection from '@components/FormFieldSection';

import EducationList from './components/EducationList';

const EducationInfoForm = ({
  handleSubmit,
  loading,
  pristine,
  invalid,
  hasSaveError,
}) => (
  <form
    id="educationInfoForm"
    onSubmit={handleSubmit}
  >
    <FormFieldSection
      heading="Schools"
    >
      <div>
        Enter your School Name, Degree Earned, and Graduation Year.<br />
        Please note that your Law School Information will not need to be entered here -
        you can update that information in the Profile Information tab.
      </div>
      <FieldArray
        name="LineItems"
        component={EducationList}
        rerenderOnEveryChange
      />
    </FormFieldSection>
    <Button
      type="submit"
      disabled={((pristine || loading) && !hasSaveError) || invalid}
    >
      Save
    </Button>
  </form>
);

EducationInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
};

EducationInfoForm.defaultProps = {
  hasSaveError: false,
};

EducationInfoForm.displayName = 'EducationInfoForm';

export default reduxForm({
  form: 'educationInfoForm',
  enableReinitialize: true,
})(EducationInfoForm);
