import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  onClickPreviousEBarHomePageInfo,
  onClickNextEBarHomePageInfo,
  onChangeEBarHomePageInfo,
} from '@redux/modules/ebarHomePageInfo';
import moment from 'moment';
import camelcaseKeys from 'camelcase-keys';
import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import { breakpoints, colors, typography } from '@style-guide/config';
import Arrow from '@style-guide/mixins/Arrow';
import LinkButton from '@style-guide/components/LinkButton';

// import moment from 'moment';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import DashboardToolsPrimary from './components/DashboardToolsPrimary';
import DashboardToolsSecondary from './components/DashboardToolsSecondary';
import QuickLinks from './components/QuickLinks';
import HeaderDiv from './components/HeaderDiv';
import NavigationButtonDiv from './components/NavigationButtonDiv';
import NavigationDiv from './components/NavigationDiv';
import DateText from './components/DateText/DateText';
import ButtonWrapper from './components/ButtonWrapper';
import Select from './components/Select';

const arrowOptionsLeft = {
  arrow: 'left',
  color: colors.darkRed,
  size: rem('32px', typography.baseFontSize),
};

const arrowOptionsRight = {
  arrow: 'right',
  size: rem('32px', typography.baseFontSize),
};

const ActionButton = styled.button`
  ${props => Arrow(props.arrowOptions)}

  color: ${props => (props.disabled ? colors.darkGray : colors.darkRed)};
  border: none;
  cursor: pointer;

  &::before {
    font-size: 1.5rem;
  }
`;

ActionButton.displayName = 'ActionButton';

const RightPrimaryTools = styled.div`
  display: flex;
`;

RightPrimaryTools.displayName = 'RightPrimaryTools';

const InputTxt = styled.input`
  appearance: none;
  background: none;
  border-radius: 0;
  cursor: pointer;
  background-color: #FFF;
  border: 1px solid #d1d3d3;
  box-shadow: none;
  font-size: 1rem;
  height: 100%;
  padding: 0 0.9375em;
  margin-right: 20px;
  width: 500px;

  @media (min-width: ${breakpoints.m}) and (max-width: ${breakpoints.l}) {
    width: 600px;
    height: 30px;
  }

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.m}) {
    height: 30px;
  }
`;

InputTxt.displayName = 'InputTxt';

const LinkSpan = styled.a`
  font-size: 14px; 
  font-weight: normal; 
  color: #000 !important;
  text-decoration: underline;
  padding: 0px 10px;
  display: flex;
  align-items: center;
`;

LinkSpan.displayName = 'LinkSpan';

class DashboardTools extends React.Component {
  constructor() {
    super();

    this.state = {
      textValue: '',
    };

    this.onEnterClick = this.onEnterClick.bind(this);
    this.textBoxHandler = this.textBoxHandler.bind(this);
  }

  onEnterClick(event) {
    if (event.keyCode === 13) {
      window.open(`${this.props.eBarGreenbookSearchUrl}"${this.state.textValue}"`, '_blank');
    }
  }

  textBoxHandler(value) {
    this.setState({
      textValue: value,
    });
  }

  render() {
    const {
      dispatch,
      isMember,
      quickLinks,
      customizeLabel,
      customizeLink,
      faqLabel,
      faqLink,
      types,
      selectedOption,
      startDateDaily,
      startDateWeekly,
      isNextDisabled,
      eBarGreenbookSearchUrl,
    } = this.props;

    return (
      <div>
        <DashboardToolsPrimary>
          <div>
            <h1>
              <YellowLineSVG />
              <span>OSBA Report</span>
            </h1>
          </div>
          <RightPrimaryTools>
            <InputTxt
              type="text"
              placeholder="Search the OSBA Report."
              onChange={e => this.textBoxHandler(e.target.value)}
              onKeyDown={e => this.onEnterClick(e)}
              value={this.state.textValue}
            />
            <NavigationButtonDiv>
              <ButtonWrapper>
                <LinkButton
                  href={`${eBarGreenbookSearchUrl}"${this.state.textValue}"`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Search
                </LinkButton>
              </ButtonWrapper>
            </NavigationButtonDiv>
          </RightPrimaryTools>
        </DashboardToolsPrimary>
        <DashboardToolsSecondary>
          <HeaderDiv>
            {types && (
              <Select
                id="frequencyTypeId"
                name="frequencyType"
                placeholder="Select frequency type"
                options={camelcaseKeys(types || []).map(filter => ({
                  label: filter.name,
                  value: filter.id,
                }))}
                onChange={event => dispatch(onChangeEBarHomePageInfo(event))}
                value={selectedOption}
                pointerBottomPosition="25px"
              />
            )}
          </HeaderDiv>
          <HeaderDiv>
            <ActionButton
              title="Left"
              onClick={() => dispatch(onClickPreviousEBarHomePageInfo(selectedOption,
                startDateDaily, startDateWeekly))}
              arrowOptions={arrowOptionsLeft}
            />
            <NavigationDiv>
              <DateText
                startDateDaily={startDateDaily}
                startDateWeekly={startDateWeekly}
                isDaily={selectedOption === 1}
              />
            </NavigationDiv>
            <ActionButton
              title="Right"
              onClick={() => dispatch(onClickNextEBarHomePageInfo(selectedOption,
                startDateDaily, startDateWeekly))}
              arrowOptions={arrowOptionsRight}
              disabled={isNextDisabled}
            />
          </HeaderDiv>
          <HeaderDiv>
            <LinkSpan href={customizeLink}>{customizeLabel}</LinkSpan>
            <LinkSpan href={faqLink}>{faqLabel}</LinkSpan>
          </HeaderDiv>
          <QuickLinks
            isMember={isMember}
            quickLinks={quickLinks}
          />
        </DashboardToolsSecondary>
      </div>
    );
  }
}

DashboardTools.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMember: PropTypes.bool,
  eBarGreenbookSearchUrl: PropTypes.string.isRequired,
  quickLinks: PropTypes.arrayOf(PropTypes.object),
  customizeLabel: PropTypes.string.isRequired,
  customizeLink: PropTypes.string.isRequired,
  faqLabel: PropTypes.string.isRequired,
  faqLink: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  selectedOption: PropTypes.number.isRequired,
  startDateDaily: PropTypes.instanceOf(moment).isRequired,
  startDateWeekly: PropTypes.instanceOf(moment).isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
};

DashboardTools.defaultProps = {
  isMember: false,
  quickLinks: [],
  types: [],
};

const mapStateToProps = ({ ebarHomePageInfo }) => ({
  selectedOption: ebarHomePageInfo.selectedOption,
  startDateDaily: ebarHomePageInfo.startDateDaily,
  startDateWeekly: ebarHomePageInfo.startDateWeekly,
  isNextDisabled: ebarHomePageInfo.isNextDisabled,
});

DashboardTools.displayName = 'DashboardTools';

export default connect(mapStateToProps)(DashboardTools);
