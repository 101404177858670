import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const ChildWrapper = styled.div`
  width: 100%;
  margin-top: 35px;
  align-self: flex-start;

  @media(min-width: ${breakpoints.m}) {
    width: calc(50% - 12.5px);
    margin-top: 0px;

    &.marginTop {
      margin-top: 35px;
    }
  }
`;

ChildWrapper.displayName = 'ChildWrapper';

export default ChildWrapper;
